import documentApiClient, { ApiDefinition } from '@documentsApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/documents/document-validation/{documentId}']['GET'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const getDocumentValidationById = async ({ documentId }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/documents/document-validation/{documentId}', { documentId });
  const response = await documentApiClient.get(path);
  return response.data;
};

export default getDocumentValidationById;