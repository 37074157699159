import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const PATH_TEMPLATE = '/creators/{creatorId}/recently-used-prices';
type THIS_API = ApiDefinition['/creators/{creatorId}/recently-used-prices']['GET'];
type Input = THIS_API['params'];

const getRecentlyUsedPrices = async ({ creatorId }: Input): Promise<THIS_API['response']> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getRecentlyUsedPrices;