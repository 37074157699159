import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/conversations/{conversationSid}/message/{messageId}';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getConversationMessage = async ({ messageId, conversationSid }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { conversationSid, messageId });
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getConversationMessage;
