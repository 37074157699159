import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/stripe/creators/{creatorId}/secondary-accounts']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getSecondaryStripeAccounts = async ({ creatorId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/stripe/creators/${creatorId}/secondary-accounts`);
  return response.data;
};

export default getSecondaryStripeAccounts;
