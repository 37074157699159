import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{id}/tiers']['GET'];

const getProductTiers = async ({ id }: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/products/${id}/tiers`);
  return response.data;
};

export default getProductTiers;
