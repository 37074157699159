import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}']['GET'];
type Input = ThisApi['params'];

const getCreator = async ({ creatorSlug }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.get(`/creators/${creatorSlug}`);
  return data.data;
};

export default getCreator;