import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/emails/welcome-waitlist/{listId}/count';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getCountOnEmailList = async ({ listId }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { listId });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getCountOnEmailList;
