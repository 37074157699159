import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type THIS_API = ApiDefinition['/ai/creators/{creatorSlug}/conversations/{conversationId}/messages']['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const publishChatMessage = async ({ creatorSlug, conversationId, ...body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/ai/creators/{creatorSlug}/conversations/{conversationId}/messages', {
    conversationId,
    creatorSlug,
  });
  const response = await noodleApiClient.post(path, body);
  return response.data;
};

export default publishChatMessage;
