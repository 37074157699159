import documentApiClient, { ApiDefinition } from '@documentsApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/documents/document-validation/{documentId}']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];
type Output = ThisApi['response'];

const updateDocumentValidationById = async ({ documentId, statusOfValidationStatus }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/documents/document-validation/{documentId}', { documentId });
  const response = await documentApiClient.put(path, {
    statusOfValidationStatus,
  });
  return response.data;
};

export default updateDocumentValidationById;