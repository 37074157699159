import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{productId}/workflow-templates']['POST'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const createWorkflowTemplateFromProduct = async ({ productId }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/products/${productId}/workflow-templates`);
  return response.data;
};

export default createWorkflowTemplateFromProduct;
