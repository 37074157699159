import documentApiClient, { ApiDefinition } from '@documentsApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/documents/uploads/{uploadId}']['GET'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const getDocumentByUploadId = async ({ uploadId }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/documents/uploads/{uploadId}', { uploadId });
  const response = await documentApiClient.get(path);
  return response.data;
};

export default getDocumentByUploadId;