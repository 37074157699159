import { GetServerSidePropsContext } from 'next';
import { logError } from '@providers/ErrorTracking';
import { NextSeo } from 'next-seo';
import { OpenGraphMedia } from 'next-seo/lib/types';
import { SELF_ORIGIN } from '@configuration/client';

type SeoDefinition = {
  description?: string | null;
  images: OpenGraphMedia[];
  url?: string | null;
  title?: string | null;
};

export type SeoSSRProps = {
  seo: SeoDefinition | null;
};

const SEOProvider: React.FC<SeoSSRProps> = ({ seo }) => {
  if (!seo) {
    return null;
  }

  return (
    <NextSeo
      title={seo.title || undefined}
      description={seo.description || undefined}
      twitter={{
        cardType: 'summary',
      }}
      openGraph={{
        images: seo.images,
        url: seo.url || undefined,
      }}
    />
  );
};

export const getSeoUrl = (context: GetServerSidePropsContext): string | null => {
  try {
    const {req} = context;
    const {host} = req.headers;
    const path = context.resolvedUrl;
    // @todo - how to get the correct protocol?
    return `https://${host}${path}`;
  } catch (error) {
    logError(error);
    return null;
  }
};

// @todo - use nextjs image optimization.
const getOptimizedUrl = (url?: string): string | undefined => url;
const isValidImage = (image: Partial<OpenGraphMedia>): image is OpenGraphMedia => Boolean(image?.url);
export const getSeoImages = (...urls: (string | undefined)[]): OpenGraphMedia[] => urls
  .map(url => ({ url: getOptimizedUrl(url) }))
  .filter(isValidImage);

export const DEFAULT_OG_IMAGE = getOptimizedUrl(`${SELF_ORIGIN}/og.jpg`) as string;

export default SEOProvider;