import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/reviews/{creatorSlug}/{productSlug}']['GET'];

type Props = ThisApi['params'];

const getProductReviews = async ({ productSlug, creatorSlug }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/reviews/${creatorSlug}/${productSlug}`);
  return response.data;
};

export default getProductReviews;
