import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}/payments']['GET'];
type Input = ThisApi['params'] & ThisApi['query'];

const getInvoicePayments = async ({ id, page, perPage }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/invoices/${id}/payments`, {
    params: { page, perPage },
  });
  return response.data;
};

export default getInvoicePayments;
