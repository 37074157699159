const serialize = (item: Record<string, unknown> | Array<unknown>): string => JSON.stringify(item);

// These must be specific for the object being deserizliazed.
const deserialize = null;

const deserializeFactory = <V>(zodParser: { parse: (obj: unknown ) => V }) => (str: string): V => {
  const parsed = zodParser.parse(JSON.parse(str));
  return parsed;
};

export default { deserialize, deserializeFactory, serialize };