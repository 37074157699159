import { m } from 'framer-motion';
import styled from 'styled-components';

type Props = {
  duration?: number,
  message?: string;
  subMessage?: string;
  rounded?: boolean;
  isSmall?: boolean;
  isProcessMessage?: boolean;
  className?: string;
};

type ContainerProps = {
  hasMessage: boolean;
  rounded: boolean;
  isSmall: boolean;
};

const Container = styled.div<ContainerProps>`
  align-items: center;
  border-radius: ${({ rounded }) => (rounded ? '1000px' : '4px')};
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  transform: translateZ(0px);
  padding: ${({ isSmall }) => (isSmall ? '4px' : '16px')};
  text-align: center;
`;

const SubMessage = styled.p`
  opacity: 0.3;
  font-size: 12px;
  position: relative;
  max-width: 240px;
  line-height: 1;
`;

const Shimmer: React.FC<Props> = ({ duration, message = '', subMessage, rounded = false, isProcessMessage = false, isSmall = false, className }) => (
  <Container hasMessage={Boolean(message)} rounded={rounded} isSmall={isSmall} className={className}>
    <m.div
      style={{
        aspectRatio: '1 / 1',
        background: 'linear-gradient(70deg, #66666600 0%, var(--color-gray-25) 50%, #66666600 100%)',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
      initial={{ scale: 2 }}
      animate={{ x: ['-50%', '50%'] }}
      transition={{ duration: 0.5, repeat: Infinity, repeatType: 'mirror' }}
    />
    {message && (
      <>
        <m.span
          animate={{ opacity: [0.4, 0.8] }}
          transition={{ duration: duration || 0.5, repeat: Infinity, repeatType: 'mirror' }}
          style={{ color: 'grey', padding: isProcessMessage ? '25px' : '0px', position: 'relative' }}
        >
          {message}
        </m.span>
        {subMessage && <SubMessage>{subMessage}</SubMessage>}
      </>
    )}
  </Container>
);

export default Shimmer;
