import { FC, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import throttle from 'lodash/throttle';
import ResizeObserver from 'resize-observer-polyfill';

import { isInIframe } from '@helpers/helper';

const IframeAdjustments: FC = () => {
  useEffect(() => {
    let resizeObserver: ResizeObserver | undefined;

    if (ResizeObserver && isInIframe()) {
      const handleResize = throttle(
        newHeight => {
          try {
            window.parent.postMessage(
              {
                message: 'change-client-height',
                postedTo: window.name,
                // @deprecated - stop sending id when widget js's are past browser caches
                value: { clientHeight: newHeight, id: window.name },
              },
              '*',
            );
          } catch (e) {
            Sentry.captureException(e);
          }
        },
        200,
        { leading: true, trailing: true },
      );

      resizeObserver = new ResizeObserver(entries => {
        const { scrollHeight } = entries[0].target;
        handleResize(scrollHeight);
      });
      resizeObserver.observe(document.body);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(document.body);
      }
    };
  }, []);

  return null;
};

export default IframeAdjustments;
