import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/calendar-accounts/{calendarAccountId}/calendars/{calendarId}']['PUT'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const disableCalendar = async ({ calendarAccountId, calendarId }: Input): Promise<Output> => {
  const response = await noodleApiClient.put(`/calendar-accounts/${calendarAccountId}/calendars/${calendarId}`, {
    isEnabled: false,
  });
  return response.data;
};

export default disableCalendar;
