import noodleMediaClient, { ApiDefinition } from '@tsClient/servers/noodleMedia';

type ThisApi = ApiDefinition['/loom-media']['POST'];
type Input = ThisApi['body'];
type Output = ThisApi['response'];

const postLoomMedia = async (body: Input): Promise<Output> => {
  const data = await noodleMediaClient.post('/loom-media', body);
  return data.data;
};

export default postLoomMedia;
