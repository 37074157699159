import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-requests/{id}']['PUT'];
type Input = ThisApi['params'] & { updates: ThisApi['body'] };

const updateDocumentRequestTemplate = async ({ id, updates }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.put(`/document-requests/${id}`, updates);
  return response.data;
};

export default updateDocumentRequestTemplate;
