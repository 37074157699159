import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const pathTemplate = '/conversations/{conversationSid}/purchased-products';
type ThisApi = ApiDefinition['/conversations/{conversationSid}/purchased-products']['GET'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const getConversationPurchases = async ({ conversationSid }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>(pathTemplate, { conversationSid });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getConversationPurchases;
