import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{id}/prefilled-context-data']['GET'];

const getPrefilledContextData = async ({ associatedUserWorkflowId, id }: ThisApi['params'] & ThisApi['query']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/workflows/${id}/prefilled-context-data`, {
    params: { associatedUserWorkflowId },
  });
  return response.data;
};

export default getPrefilledContextData;
