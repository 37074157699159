import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/custom-terms-templates']['GET'];

type Input = ThisApi['params'] & ThisApi['query'];

const getCreatorTermsTemplates = async ({ creatorId, search, page = 1, perPage = 100 }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/custom-terms-templates`, {
    params: {
      page,
      perPage,
      search,
    },
  });
  return response.data;
};

export default getCreatorTermsTemplates;