import questionnairesClient, { ApiDefinition } from '@questionnairesApi';

type THIS_API = ApiDefinition['/external-data-sets']['PUT'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const getDatasets = async (body: Input): Promise<Output> => {
  const response = await questionnairesClient.put('/external-data-sets', body);
  return response.data;
};

export default getDatasets;
