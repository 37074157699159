import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorId}/import-contacts';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['params'] & {
  body: THIS_API['body'];
};
type Output = THIS_API['response'];

const importContacts = async ({ creatorId, body }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId });
  const response = await noodleApiClient.post(path, body);
  return response.data;
};

export default importContacts;
