import { createContext } from 'react';
import { nanoid } from 'nanoid';

export enum SocketMessageType {
  NEW_CHAT_MESSAGE = 'new-chat-message',
  USER_WORKFLOW_UPDATED = 'user-workflow-updated',
  STATUS_CHANGE = 'status-change',
  USER_INBOX_UPDATED = 'user-inbox-updated',
  FORM_SUMMARY_UPDATED = 'form-summary-updated',
}

export type SocketMessage = {
  type: SocketMessageType;
  data: unknown;
};

export type SocketContextType = {
  addListener: ({ fn, messageType }: { fn: (data: unknown) => unknown, messageType: SocketMessageType }) => string;
  isInitialized: boolean;
  joinChannels: (channelIds: string[]) => Promise<void>;
  removeListener: (id: string) => void;
};

const SocketContext = createContext<SocketContextType>({
  addListener: () => nanoid(),
  isInitialized: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  joinChannels: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeListener: () => {},
});

export default SocketContext;
