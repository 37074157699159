import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{invoiceId}/permissions']['GET'];
type Input = ThisApi['params'];

const getInvoicePermissions = async ({ invoiceId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/invoices/${invoiceId}/permissions`);
  return response.data;
};

export default getInvoicePermissions;