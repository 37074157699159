import noodleApiClient, { ApiDefinition } from '@/tsClient/servers/noodleApi';

type THIS_API = ApiDefinition['/translations']['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const translateTexts = async ({ texts, target }: Input) : Promise<Output> => {
  const response = await noodleApiClient.post(`/translations`, { target, texts });
  return response.data;
};
export default translateTexts;