import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/content-library/get-creator-content-library']['GET'];

const getCreatorContent = async (): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get('/content-library/get-creator-content-library' );
  return response.data.data;
};

export default getCreatorContent;
