import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/products/{creatorSlug}/{productSlug}/messages/{messageId}';
type THIS_API = ApiDefinition['/products/{creatorSlug}/{productSlug}/messages/{messageId}']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

// set defaults big for now, as a temporary fix while waiting for server to handle pagination.
const getMessageThread = async ({ creatorSlug, productSlug, messageId, page, perPage }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorSlug, messageId, productSlug });
  const data = await noodleApiClient.get(path, { params: { page, perPage } });
  return data.data;
};

export default getMessageThread;