import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/documents/:userWorkflowDocumentId';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getUserWorkflowDocument = async ({ userWorkflowDocumentId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/workflows/user-workflows/documents/${userWorkflowDocumentId}`);
  return response.data;
};

export default getUserWorkflowDocument;
