import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/prices/{priceId}']['GET'];
type Input= THIS_API['params'];
type Output = THIS_API['response'];

const getPrice = async ({ priceId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/prices/${priceId}`);
  return response.data;
};

export default getPrice;
