import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports-b2c/{creditReportId}/response']['GET'];
type Input = ThisApi['params'];

const getCreditReportResponses = async ({ creditReportId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/credit-reports-b2c/${creditReportId}/response`);
  return response.data;
};

export default getCreditReportResponses;
