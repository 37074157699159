import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/user-workflows/{userWorkflowId}']['GET'];

type Props = ThisApi['params'];

const getUserWorkflowById = async ({ userWorkflowId }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/workflows/user-workflows/${userWorkflowId}`);
  return response.data;
};

export default getUserWorkflowById;