import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/my/teams';
type THIS_API = ApiDefinition['/my/teams']['GET'];
type Input = THIS_API['query'];
type Output = THIS_API['response'];

const getTeams = async ( data: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.get(path, { params: { ...data } });
  return response.data;
};

export default getTeams;