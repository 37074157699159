import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}/{questionnaireTemplateId}']['PUT'];
type Input = ThisApi['params'];
type Body = ThisApi['body'];

const updateQuestionnaireTemplate = async ({ creatorId, questionnaireTemplateId }: Input, body: Body): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaire-templates/{creatorId}/{questionnaireTemplateId}', { creatorId, questionnaireTemplateId });
  const data = await formsBackendClient.put(path, body);
  return data.data;
};

export default updateQuestionnaireTemplate;
