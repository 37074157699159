import { createContext } from 'react';
import { InstallType } from '@helpers/pwa';
import { AppName } from './typings';

type DashboadAppContextType = {
  doInstall: () => void | Promise<void>;
  isReadyToInstall: boolean;
  isInApp: boolean;
  installType: InstallType;
  appName: AppName;
};

const DashboadAppContext = createContext<DashboadAppContextType>({
  appName: 'web',
  doInstall: () => { /* */ },
  installType: 'unsupported',
  isInApp: false,
  isReadyToInstall: false,
});

export default DashboadAppContext;
