import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/templates/{id}']['GET'];
type Input = ThisApi['params'];

const getInvoiceRequestTemplateById = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/invoices/templates/${id}`);
  return response.data;
};

export default getInvoiceRequestTemplateById;
