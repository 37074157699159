import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/form-request-templates/{id}/aiSummaryVoice']['PUT'];

type Input = ThisApi['params'] & ThisApi['body'];

const updateFormRequestAiSummaryVoice = async ({ id, ...body }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/form-request-templates/{id}/aiSummaryVoice', { id });
  const data = await noodleApiClient.put(path,body);
  return data.data;
};

export default updateFormRequestAiSummaryVoice;
