import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{productId}/testimonials']['GET'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const getTestimonials = async ({ productId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/products/${productId}/testimonials`);
  return response.data;
};

export default getTestimonials;
