import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/custom-terms-templates/{id}']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getCustomTermsTemplateById = async ({ id }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/custom-terms-templates/{id}', { id });
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getCustomTermsTemplateById;
