import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisRequest = ApiDefinition['/reviews']['PUT'];
type Input = ThisRequest['body'];
type Output = ThisRequest['response'];

const editProductReview = async (payload: Input): Promise<Output> => {
  const response = await noodleApiClient.put('/reviews', payload);
  return response.data;
};

export default editProductReview;