import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/documents/:userWorkflowDocumentId';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const generateUserWorkflowDocumentPDF = async ({ userWorkflowDocumentId, slate }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/workflows/user-workflows/documents/${userWorkflowDocumentId}`, { slate });
  return response.data;
};

export default generateUserWorkflowDocumentPDF;
