import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{productId}']['GET'];

type Props = { id: string }; // @todo change to productId

const getProductById = async ({ id }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/products/${id}`);
  return response.data;
};

export default getProductById;
