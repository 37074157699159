import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/{workflowId}/invoices';
type ThisApi = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = ThisApi['body'] & ThisApi['params'];

const createWorkflowInvoice = async ({ invoiceTemplateId, workflowId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/workflows/${workflowId}/invoices`, { invoiceTemplateId });
  return response.data;
};

export default createWorkflowInvoice;