import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { breakpoints, SIZES } from '@styles/media';
import isBrowser from '@helpers/isBrowser';

type UseIsMobile = (size?: SIZES) => boolean;

const useIsMobile: UseIsMobile = (size = SIZES.sm) => {
  const breakpoint = breakpoints[size];
  const [isMobile, setIsMobile] = useState(isBrowser() && window.innerWidth < breakpoint);

  useLayoutEffect(() => {
    setIsMobile(window.innerWidth < breakpoint);
    const updateSize = (): void => {
      setIsMobile(window.innerWidth < breakpoint);
    };
    window.addEventListener('resize', debounce(updateSize, 20));
    return () => window.removeEventListener('resize', updateSize);
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
export { SIZES };