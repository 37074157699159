import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/custom-terms';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const createCustomTerms = async (body: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const data = await noodleApiClient.post(path, body);
  return data.data;
};

export default createCustomTerms;