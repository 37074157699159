import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateDocumentRequestUser = async ({ id, ...body }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.put(`/document-request-users/${id}`, body);
  return response.data;
};

export default updateDocumentRequestUser;
