import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports-b2c/{creditReportId}/approval-request']['POST'];

type Input = ThisApi['params'] & ThisApi['body'];

const sendApprovalRequest = async ({ creditReportId, clientIds }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/credit-reports-b2c/${creditReportId}/approval-request`, {
    clientIds,
  });

  return response.data;
};

export default sendApprovalRequest;
