import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/invoice-custom-terms-variables']['GET'];

const getInvoiceVariablesForCustomTerms = async ({ creatorId }: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/invoice-custom-terms-variables`);
  return response.data;
};

export default getInvoiceVariablesForCustomTerms;
