import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import { isBrowser } from 'helpers/helper';
import MediaLoader from '@components/MediaLoader';
import useHistory from '@providers/History';

import s from './BackButton.module.scss';

type BackButtonProps = {
  title?: string;
  fallBackTitle?: string;
  backLink?: string | undefined;
  fallBackLink?: string | null;
  backAction?: () => void | undefined;
  className?: undefined | string;
};

const BackButton: FC<BackButtonProps> = ({
  title = 'Back',
  backLink = undefined,
  fallBackLink = undefined,
  fallBackTitle = undefined,
  backAction = undefined,
  className = undefined,
}) => {
  const router = useRouter();
  const { history } = useHistory();
  const [isFetching, setIsFetching] = useState(false);

  const useBackLink = Boolean(backLink);
  const useLatestHistory = !useBackLink && history.length > 1;
  const useFallBackLink = !useBackLink && !useLatestHistory && Boolean(fallBackLink);

  const titleToShow = (useFallBackLink && fallBackTitle) || title;

  useEffect(() => {
    const handleRouteChange = (): void => {
      setIsFetching(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (backLink) {
      router.prefetch(backLink);
    } else if (history.length > 1) {
      router.prefetch(history[history.length - 2]);
    } else if (fallBackLink) {
      router.prefetch(fallBackLink);
    }
  }, [backLink, router, fallBackLink, history]);

  const handleGoBack = async (): Promise<void> => {
    if (isBrowser()) {
      setIsFetching(true);
      if (backLink) {
        await router.push(backLink);
      } else {
        if (history.length > 1) {
          await router.back();
          if (backAction) backAction();
          return;
        }
        if (fallBackLink) {
          await router.push(fallBackLink);
        } else {
          // if backLink & fallBackLink not provided & history.length === 1
          await router.back();
        }
      }
      if (backAction) backAction();
    }
  };

  if (isBrowser() && history.length <= 1 && !backLink && !fallBackLink && !backAction) {
    return null;
  }

  return (
    <button className={classNames(s['back-button__wrapper'], className)} disabled={isFetching} onClick={handleGoBack}>
      <span className={classNames(s['back-button__link'], 'body-md-bold')}>
        {isFetching && <MediaLoader className={s['back-button__loader']} />}
        <ArrowCircleLeft weight="fill" size={24} />
        {titleToShow && <span className={s['back-button__title']}>{titleToShow}</span>}
      </span>
    </button>
  );
};

export default BackButton;
