import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from "@tsClient/utilities/buildPath";

const PATH_TEMPLATE = '/conversations/{conversationSid}';
type THIS_API = ApiDefinition['/conversations/{conversationSid}']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getConversationBySid = async ({ conversationSid }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { conversationSid });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getConversationBySid;
