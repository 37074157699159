export const ALL_PREFIXES = [
  'dashboard',
  'document-access-provider',
  'feature-flags',
  'pushNotifications',
  'teams',
  'test',
  'workflows',
  'user-global',
] as const;

export type Prefixes = typeof ALL_PREFIXES[number];
