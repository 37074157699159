import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/organizations/{organizationId}/members']['GET'];

type Input = ThisApi['params'] & ThisApi['query'];

const getOrganizationMembers = async ({ page = 1, perPage = 1000, organizationId }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.get(`/organizations/${organizationId}/members`, {
    params: {
      page,
      perPage,
    },
  });
  return data.data;
};

export default getOrganizationMembers;
