import DOMPurify from 'isomorphic-dompurify';
import { STUBGROUP_GA_MEASUREMENT_ID } from '@configuration/client';
import React from 'react';

const StubGroupGTag: React.FC = () => {
  if (!STUBGROUP_GA_MEASUREMENT_ID) {
    return null;
  }

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${STUBGROUP_GA_MEASUREMENT_ID}');
  `),
      }}
    />
  );
};

const StubGroupGTagNoScript: React.FC = () => {
  if (!STUBGROUP_GA_MEASUREMENT_ID) {
    return null;
  }
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${STUBGROUP_GA_MEASUREMENT_ID}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      >
      </iframe>`;
    </noscript>
  );
};

export { StubGroupGTag, StubGroupGTagNoScript };