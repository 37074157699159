import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/products']['GET'];

type Input = ThisApi['params'] & ThisApi['query'];
type Output = ThisApi['response'];

const getCreatorProducts = async ({ creatorSlug, isOmitFilterByActive, onlyActivePrices, onlyOneLinkProducts }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/creators/${creatorSlug}/products`, {
    params: {
      isOmitFilterByActive,
      onlyActivePrices,
      onlyOneLinkProducts,
    },
  });
  return response.data;
};

export default getCreatorProducts;
