import { createContext } from 'react';
import Bluebird from 'bluebird';
import { Media } from '@typings/graphql-models';

export enum JobStatus {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELED = 'canceled'
}

export enum JobType {
  TEST_JOB = 'test-job',
  TEST_COMMIT = 'test-commits',
  MESSAGE_MEDIA_UPLOAD = 'message-media-upload',
  MESSAGE_PUBLISH = 'message-publish',
  CHAT_MESSAGE = 'chat-message',
  COMMENT_REPLY = 'comment-reply',
  CREATE_DEFAULT_PRODUCTS = 'create-default-products',
  UPLOAD_VIDEO = 'upload-video',
  VERIFY_NOODLE = 'verify-noodle',
  UPLOAD_PROFILE_PHOTO = 'upload-profile-photo',
  BYTE_MEDIA_UPLOAD = 'byte-media-upload',
  SUBMIT_BYTE_STEP = 'submit-byte-step',
  CREATE_WORKFLOWS = 'create-workflows',
}

export type Job = {
  id: string;
  correlationId: string;
  status: JobStatus;
  title: string;
  type: JobType;
  mediaType?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function: (data?: any[]) => Promise<any>;
  dependsOn?: Job[];
  progress: number;
  setProgress: (progress: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise: Bluebird<any>;
  cancel: () => void;
  onCancel?: () => void;
  onRemove?: () => void;
  retry: () => void;
  metadata?: Record<string, unknown>;
  uploadMedia?: Media;
};

export type IJobContext = {
  addJob: (args: Pick<Job, 'title' | 'dependsOn' | 'function' | 'type' | 'mediaType' | 'metadata'> & { correlationId?: string; onCancel?: () => void; }) => Job;
  removeJob: (args: Job) => void;
  updateJob: (args: Pick<Job, 'id'> & Partial<Job>) => void;
  jobs: Job[];
};

const JobContext = createContext<IJobContext>({
  addJob: args => ({
    ...args,
    cancel: () => { /**/ },
    correlationId: '1234',
    id: '1234',
    progress: 0,
    promise: new Bluebird.Promise(r => { r(); }),
    retry: () => { /**/ },
    setProgress: () => { /**/ },
    status: JobStatus.IN_PROGRESS,
  }),
  jobs: [],
  removeJob: args => args.toString(),
  updateJob: args => args.toString(),
});

export default JobContext;
