import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/compiled-documents';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const updateUserWorkflowCompiledDocuments = async ({ userWorkflowId, updates }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { userWorkflowId });
  const response = await noodleApiClient.put(path, { updates });
  return response.data;
};

export default updateUserWorkflowCompiledDocuments;