import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from './utilities/buildPath';

type THIS_API = ApiDefinition['/my/creators/{creatorId}/payment-methods']['POST'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = THIS_API['response'];

const attachNewCard = async ({
  creatorId,
  paymentMethodId,
  personId,
  isDefaultPaymentMethod,
}: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/my/creators/{creatorId}/payment-methods', {
    creatorId,
  });
  const response = await noodleApiClient.post(path, {
    isDefaultPaymentMethod,
    paymentMethodId,
    personId,
  });
  return response.data;
};

export default attachNewCard;
