import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{id}/scheduling-prices';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getSchedulingPrices = async ({ creatorId, page, perPage }: Input): Promise<Output> => {
  const data = await noodleApiClient.get(`/creators/${creatorId}/scheduling-prices`, { params: { page, perPage }});
  return data.data;
};

export default getSchedulingPrices;
