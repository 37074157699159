// Javascript versions of the breakpoints
// These should match the ones in _media.scss, if changing this file update _media.scss too
export enum SIZES {
  xs = 'extra-small',
  sm = 'small',
  md = 'medium',
  lg = 'large',
  xl = 'extra-large',
  xxl = 'extra-extra-large',
  custom_1024 = 'custom_1024',
}

export const breakpoints = {
  [SIZES.xs]: 0,
  [SIZES.sm]: 576,
  [SIZES.md]: 768,
  [SIZES.lg]: 992,
  [SIZES.xl]: 1200,
  [SIZES.xxl]: 1600,
  [SIZES.custom_1024]: 1024,
};
