import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/{workflowId}/invoices/{id}';
type ThisApi = ApiDefinition[typeof PATH_TEMPLATE]['DELETE'];
type Input = ThisApi['params'];

const deleteWorkflowInvoice = async ({ id, workflowId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.delete(`/workflows/${workflowId}/invoices/${id}`);
  return response.data;
};

export default deleteWorkflowInvoice;