import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/my/payments';

type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['query'];
type Output = THIS_API['response'];

const getCustomerPayments = async (data: Input ): Promise<Output> => {
  const response = await noodleApiClient.get('/my/payments', { params: { ...data } });
  return response.data;
};

export default getCustomerPayments;
