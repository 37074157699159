import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}/payment-intents']['POST'];
type Input = ThisApi['body'] & ThisApi['params'];

const createPaymentIntentForInvoice = async ({ id, ...body }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/invoices/${id}/payment-intents`, body);
  return response.data;
};

export default createPaymentIntentForInvoice;
