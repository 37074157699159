import { RefObject, useEffect, useState } from "react";

const useIsTextTruncated = <T extends HTMLElement>(ref: RefObject<T>): boolean => {
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const element = ref?.current;
    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }, [ref]);

  return isTruncated;
};

export default useIsTextTruncated;
