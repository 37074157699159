import ProgressIndicator from '@components/ProgressIndicator';
import s from './Button.module.scss';

type Props = {
  size: 'xs' | 'sm' | 'md' | 'lg';
};

const Progress: React.FC<Props> = ({ size }) => (
  <ProgressIndicator
    className={s.progress}
    size={(size === 'xs' && 12) || (size === 'sm' && 16) || 20}
  />
);

export default Progress;