import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type THIS_API = ApiDefinition['/messages/{id}']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getMessageById = async ({ id, page, perPage }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/messages/{id}', { id });
  const response = await noodleApiClient.get(path, { params: { page, perPage }});
  return response.data;
};

export default getMessageById;
