import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/user-workflows/documents/:userWorkflowDocumentId/ai-generated-content';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const generateUserWorkflowDocumentContent = async ({ userWorkflowDocumentId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/workflows/user-workflows/documents/${userWorkflowDocumentId}/ai-generated-content`);
  return response.data;
};

export default generateUserWorkflowDocumentContent;
