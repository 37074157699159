import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/ai-followup-history']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getCreatorAiFollowupHistory = async ({ creatorId, filter, page, perPage, userWorkflowId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/creators/{creatorId}/ai-followup-history', { creatorId });
  const data = await noodleApiClient.get(path, { params: { filter, page, perPage, userWorkflowId }});
  return data.data;
};

export default getCreatorAiFollowupHistory;
