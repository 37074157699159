import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}/steps']['POST'];

const createWorkflowStep = async ({ id, stepData }: ThisApi['params'] & { stepData: ThisApi['body'] }): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/workflows/${id}/steps`, {
    ...stepData,
  });
  return response.data;
};

export default createWorkflowStep;