import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/social-media']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateCreatorSocialMedia = async ({ creatorId, ...body }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.put(`/creators/${creatorId}/social-media`, body);
  return data.data;
};

export default updateCreatorSocialMedia;
