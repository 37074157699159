import buildPath from '@/tsClient/utilities/buildPath';
import integrationsClient, { ApiDefinition } from '@/tsClient/servers/integrationsService';

type THIS_API = ApiDefinition['/{provider}/creators/{creatorId}/account']['DELETE'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const deleteCreatorIntegration = async ({ creatorId, provider }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/{provider}/creators/{creatorId}/account', { creatorId, provider });
  const response = await integrationsClient.delete(path);
  return response.data;
};

export default deleteCreatorIntegration;
