import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/custom-terms/{id}']['GET'];
type Input = ThisApi['params'];

const getCustomTerms = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/custom-terms/${id}`);
  return response.data;
};

export default getCustomTerms;
