import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/products/{productId}/prices']['POST'];

type Input = {
  productId: string;
} & ApiDefinition['/products/{productId}/prices']['POST']['body'];

const updateProductPrices = async ({ productId, creatorId, newPrices }: Input): Promise<THIS_API['response']> => {
  const data = await noodleApiClient.post<THIS_API['response']>(`/products/${productId}/prices`, { creatorId, newPrices });
  return data.data;
};

export default updateProductPrices;
