import axios from 'axios';
import axiosRetry from 'axios-retry';
import { SELF_ORIGIN } from '@configuration/client';
import axiosRetryCondition from '../axiosRetryCondition';

if (!SELF_ORIGIN) {
  throw new Error('SELF_ORIGIN not defined');
}

const client = axios.create({
  baseURL: `${SELF_ORIGIN}/api`,
});

axiosRetry(client, {
  retryCondition: axiosRetryCondition,
});

export default client;
