import { createContext } from 'react';
import { DEFAULT_LANGUAGE, LanguageCode } from './constants';

type TranslationContextType = {
  language: LanguageCode;
  setLanguage: (language: LanguageCode) => void;
  setQueryLanguage: (language: LanguageCode) => void;
};

const TranslationContext = createContext<TranslationContextType>({
  language: DEFAULT_LANGUAGE.code,
  setLanguage: () => undefined,
  setQueryLanguage: () => undefined,
});

export default TranslationContext;
