import { isInPopoverWidgetLauncher } from "@helpers/helper";
import { useEffect } from "react";

const DarkModeProvider = (): null => {
  useEffect(() => {
    if (isInPopoverWidgetLauncher()) {
      window.document.body.style.backgroundColor = 'transparent';
    } else {
      window.document.body.style.backgroundColor = 'var(--color-gray-0)';
    }
  }, []);

  return null;
};

export default DarkModeProvider;