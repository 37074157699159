import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/products/{creatorSlug}/{productSlug}/messages/{messageId}/participants';
type THIS_API = ApiDefinition['/products/{creatorSlug}/{productSlug}/messages/{messageId}/participants']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getAllParticipantsOfBroadcast = async ({ creatorSlug, productSlug, messageId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorSlug, messageId, productSlug });
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getAllParticipantsOfBroadcast;