const POPOVER_WIDGET_NAMES = [
  'noodle-popover-main',
  'noodle-popover-v2-main',
  'popover-widget-page',
];

function isInPopoverWidget(): boolean {
  try {
    return window.self !== window.top && POPOVER_WIDGET_NAMES.includes(window.name); // should be the same as on popover-widget.js
  } catch (e) {
    return true;
  }
}

export default isInPopoverWidget;
