import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports-b2c/{creditReportId}/verify-smfa-status/{smfaToken}']['POST'];

type Input = {
  creditReportId: string;
  smfaToken: string;
};

type Output = ThisApi['response'];

const verifySmfaStatus = async ({ creditReportId, smfaToken }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/credit-reports-b2c/${creditReportId}/verify-smfa-status/${smfaToken}`);

  return response.data;
};

export default verifySmfaStatus;
