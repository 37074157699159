import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/my/upcoming-bookings/unpaginated';
type THIS_API = ApiDefinition['/my/upcoming-bookings/unpaginated']['GET'];
type Input = THIS_API['query'];
type Output = THIS_API['response'];

const getMyUpcomingBookings = async ({ creatorSlug }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.get(path, {
    params: { creatorSlug },
  });
  return response.data;
};

export default getMyUpcomingBookings;
