import noodleApiClient from '@noodleApi';

type UpdateProductProps = {
  creatorSlug: string,
  productSlug: string,
  updates: unknown
};

/**
 * @deprecated use tsClient.updateProduct instead
 */
const updateProduct = async ({ creatorSlug, productSlug, updates }: UpdateProductProps): Promise<void> => {
  const data = await noodleApiClient.put(`/products/${creatorSlug}/${productSlug}`, updates);
  return data.data;
};

export default updateProduct;
