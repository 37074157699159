import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/prices']['GET'];
type Input = ThisApi['params'];

const getCreatorPrices = async ({ creatorSlug }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.get(`/creators/${creatorSlug}/prices`);
  return data.data;
};

export default getCreatorPrices;