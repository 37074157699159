import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}/contexts']['POST'];

const createWorkflowContext = async ({ workflowId, data }: ThisApi['params'] & { data: ThisApi['body'] }): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/workflows/${workflowId}/contexts`, data);
  return response.data;
};

export default createWorkflowContext;
