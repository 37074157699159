import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}/contexts/{contextId}']['PUT'];

const updateWorkflowContext = async ({ contextId, workflowId, updates }: ThisApi['params'] & { updates: ThisApi['body'] }): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/workflows/${workflowId}/contexts/${contextId}`, {
    ...updates,
  });
  return response.data;
};

export default updateWorkflowContext;