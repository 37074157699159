import Link from '@components/CustomLink';

import BackButton from '@components/BackButton';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';

import s from './Page404.module.scss';

const NotFoundPage: React.FC = () => (
  <main className={s['not-found__wrapper']}>
    <BackButton />
    <title>Not found</title>
    <h1 className="heading-md">Page not found</h1>
    <p className="body-sm">
      Sorry{' '}
      <span role="img" aria-label="Pensive emoji">
        😔&nbsp;
      </span>{' '}
      we couldn’t find what you were looking for.
    </p>
    {process.env.NODE_ENV === 'development' && (
      <p className="body-sm">
        Try creating a page in <code className={s['not-found__code']}>src/pages/</code>.
      </p>
    )}
    <Link to={getUrl(IDENTIFIERS.HOME)} prefetch={false}>Go home</Link>.
  </main>
);

export default NotFoundPage;
