import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/payments/requests']['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const createPaymentRequest = async ({ priceIds, workflowReferenceId }: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/payments/requests', { priceIds, workflowReferenceId });
  return response.data;
};

export default createPaymentRequest;
