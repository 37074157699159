import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorSlug}/conversations';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const findOrCreateConversationWithCreator = async ({ creatorId, creatorSlug }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, {creatorSlug});
  const data = await noodleApiClient.post(path, { creatorId });
  return data.data;
};

export default findOrCreateConversationWithCreator;