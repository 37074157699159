import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { logError } from '@providers/ErrorTracking';
import storage from './storage';
import { Prefixes } from './typings';

// @todo - use useSyncExternalStore or window.addEventListener to subscribe to changes so that different components can use the same stored value

type PropsWithoutDefault<V> = {
  key: string;
  prefix: Prefixes; // used when clearing storage on logout
  defaultValue?: never;
  useIf?: (item: V | null) => boolean;
  serialize: (item: Exclude<V, null>) => string;
  deserialize: (val: string) => V;
};

type PropsWithDefault<V> = {
  key: string;
  prefix: Prefixes; // used when clearing storage on logout
  defaultValue: V;
  useIf?: (item: V | null) => boolean;
  serialize: (item: Exclude<V, null>) => string;
  deserialize: (val: string) => V;
};

type Props<V> = PropsWithDefault<V> | PropsWithoutDefault<V>;

type LocalStorageState<V> = [
  V,
  Dispatch< SetStateAction<V | undefined>>,
];

function useLocalStorageState<V>(opts: PropsWithoutDefault<V>): LocalStorageState<V | null>;
function useLocalStorageState<V>(opts: PropsWithDefault<V>): LocalStorageState<V>;

function useLocalStorageState<V>({
  key,
  prefix,
  defaultValue,
  useIf,
  serialize,
  deserialize,
}: Props<V>): LocalStorageState<V | null> {
  const serializerRef = useRef({ deserialize, serialize });
  const useIfRef = useRef(useIf);
  serializerRef.current.serialize = serialize;
  serializerRef.current.deserialize = deserialize;
  useIfRef.current = useIf;

  const [value, setValue] = useState<V | null>(() => {
    let storedValue: string | null | undefined;

    try {
      storedValue = storage.get(prefix, key);

      if (typeof storedValue === 'string') {
        try {
          return deserialize(storedValue);
        } catch (error) {
          console.error(error); // eslint-disable-line no-console
          logError(new Error('Failed to deserialize'), { storedValue });
        }
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      logError(new Error('Failed to deserialize'), { storedValue });
    }
    return (defaultValue ?? null);
  });

  useEffect(() => {
    try {
      if (value === null) {
        storage.remove(prefix, key);
      } else {
        const asStr = serializerRef.current.serialize(value as Exclude<V, null>);
        storage.set(prefix, key, asStr);
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      logError(new Error('Failed to serialize'), { value });
    }
  }, [key, prefix, value]);

  const setValueIgnoreUndefined = useCallback((newValue: SetStateAction<V | null | undefined>) => {
    // This is mostly used for async data, so rather than having everyone do if !undefined, do it here.
    if (newValue instanceof Function) {
      setValue((state) => {
        const v = newValue(state ?? null);
        return v === undefined ? state : v;
      });
    } else if (newValue !== undefined) {
      setValue(newValue);
    }
  }, []);

  const valueToReturn = useMemo(() => {
    if (value === null || value === undefined) {
      return defaultValue ?? null;
    }

    if (useIfRef.current && !useIfRef.current(value)) {
      return defaultValue ?? null;
    }

    return value;
  }, [defaultValue, value]);

  return [valueToReturn, setValueIgnoreUndefined];
}

export default useLocalStorageState;
