import NoodleIcon from '@components/Icons/Noodle';
import NoodleTheme from '@components/NoodleTheme';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import { FC } from 'react';
import s from './PoweredByNoodle.module.scss';

type Props = {
  style?: React.CSSProperties;
  isVertical?: boolean;
};

const PoweredByNoodle: FC<Props> = ({ style, isVertical }) => (
  <NoodleTheme>
    <div className={s.wrapper} style={style} data-translate="false">
      <a
        className={isVertical ? s.poweredVertical : s.powered}
        target="_blank"
        rel="noopener noreferrer"
        href={getUrl(IDENTIFIERS.HOME)}
      >
        Powered by{' '}
        <span>
          <NoodleIcon width={12} height={12} fill="var(--color-noodle)" />
          <span>noodle</span>
        </span>
      </a>
    </div>
  </NoodleTheme>
);

export default PoweredByNoodle;
