import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/my/agencies';
type THIS_API = ApiDefinition['/my/agencies']['GET'];
type Input = THIS_API['query'];
type Output = THIS_API['response'];

const getMyAgencies = async ({ page = 1, perPage = 10 }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.get(path, {
    params: { page, perPage },
  });
  return response.data;
};

export default getMyAgencies;
