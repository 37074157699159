import * as Sentry from '@sentry/react';
import Button from '@/components/DesignLibrary/Button';

import s from './ErrorComponent.module.scss';

type Props = { eventId: string | null };

const ErrorComponent: React.FC<Props> = ({ eventId }) => {
  const handleOpenFeedbackWidget = (): void => Sentry.showReportDialog(eventId ? { eventId } : {});

  return (
    <div className={s.container}>
      <h1 className="heading-sm">Oops!</h1>
      <div>
        <p className="body-sm">Something went wrong. We’re sorry for the inconvenience.</p>
        <p className="body-sm">Please report this error and we’ll fix it asap.</p>
      </div>
      <Button variant="destructive" size="sm" onClick={handleOpenFeedbackWidget}>
        Report this error
      </Button>
    </div>
  );
};

export default ErrorComponent;
