import { logError } from '@providers/ErrorTracking';
import { requestResponse, sendMessageUp } from './iframeMessages';

const makeParentKey = (key: string): string => `noodle:${key}`;

const setItem = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    const eventId = sendMessageUp({
      message: 'localStorage:set',
      value: {
        key: makeParentKey(key),
        value,
      },
    });
    if (!eventId) {
      logError(err, { reason: 'not-in-iframe' });
    }
  }
};

const getItem = async (key: string): Promise<string | null> => {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    return requestResponse<{ key: string }, string | null>({
      errorExtras: { key },
      request: {
        message: 'localStorage:get',
        value: {
          key: makeParentKey(key),
        },
      },
      response: {
        // If there is no referenceId, assume it's correct for any localStorage response
        ensureReferenceId: (eventId, data) => {
          if (
            !data.referenceId
            && (
              data.message === 'localStorage:response'
              || data.message === 'localStorage:error'
            )
          ) {
            return {
              referenceId: eventId,
              ...data,
            };
          }
          return data;
        },

        handler: (data) => {
          if (data.message === 'localStorage:response') {
            let item = data.value ?? null;
            // because the iframeMessages add { id: window.name } to things, need to detect that.
            // @deprecated - can remove when this when the auto adding of the id is removed
            if (typeof item === 'object') {
              item = null;
            }
            return item;
          }
          if (data.message === 'localStorage:error') {
            throw data.value || new Error('LocalStorage is not available');
          }
          throw new Error(`Unsupported response type: ${data.message}`);
        },
      },
    });
  }
};

const removeItem = async (key: string): Promise<void> => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    const eventId = sendMessageUp({
      message: 'localStorage:remove',
      value: {
        key: makeParentKey(key),
      },
    });
    if (!eventId) {
      logError(err, { reason: 'not-in-iframe' });
    }
  }
};

export default { getItem, removeItem, setItem };
