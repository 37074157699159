import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/files/{fileId}']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateDocumentRequestUserFile = async ({ id, fileId, ...body }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.put(`/document-request-users/${id}/files/${fileId}`, body);
  return response.data;
};

export default updateDocumentRequestUserFile;