import { createContext } from 'react';

type PushNotificationContextData = {
  registerPushNotifications: () => Promise<void>;
  deregisterPushNotifications: () => Promise<void>;
  pushSubscriptionId: string | null,
};

const PushNotificationContext = createContext<PushNotificationContextData>({
  deregisterPushNotifications: async () => { /* empty function */ },
  pushSubscriptionId: null,
  registerPushNotifications: async () => { /* empty function */ },
});

export default PushNotificationContext;
