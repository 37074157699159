import noodleApiClient, { ApiDefinition } from '@noodleApi';
import { CREDIT_REPORT_PRODUCT_SLUG } from '@/configuration/client';

type ThisApi = ApiDefinition['/credit-reports-b2c/reports/noodle-products/{slug}']['GET'];

const getNoodleProducts = async (): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/credit-reports-b2c/reports/noodle-products/${CREDIT_REPORT_PRODUCT_SLUG}`);
  return response.data;
};

export default getNoodleProducts;
