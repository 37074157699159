import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users']['GET'];
type Input = ThisApi['query'];

const getDocumentRequestUsers = async ({ creatorId, page = 1, perPage = 100 }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.get('/document-request-users', {
    params: {
      creatorId,
      page,
      perPage,
    },
  });
  return response.data;
};

export default getDocumentRequestUsers;
