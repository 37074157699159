import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/comments/{commentId}']['PUT'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const updateWorkflowConversationMessage = async ({ userWorkflowId, commentId, ...body }: Input): Promise<Output> => {
  const response = await noodleApiClient.put(`/workflows/user-workflows/${userWorkflowId}/comments/${commentId}`, body);
  return response.data;
};

export default updateWorkflowConversationMessage;
