import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/organization']['POST'];

type Input = ThisApi['params'] & ThisApi['body'];

const createOrganization = async ({ creatorId, ...body }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.post(`/creators/${creatorId}/organization`, body);
  return data.data;
};

export default createOrganization;
