import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaires/{questionnaireId}/responses']['GET'];
type Input = ThisApi['params'] & ThisApi['query'];

const getQuestionnaireResponses = async ({ page, perPage, questionnaireId }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaires/{questionnaireId}/responses', { questionnaireId });
  const data = await formsBackendClient.get(path, {
    params: { page, perPage },
  });
  return data.data;
};

export default getQuestionnaireResponses;
