import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/products/{productId}/custom-terms-template';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getProductTermsTemplate = async ({ productId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { productId });
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getProductTermsTemplate;