import noodleApiClient, { ApiDefinition } from '@noodleApi';

type Input = ApiDefinition['/creators/{creatorId}/dashboard-data']['GET']['params'];
type Output = ApiDefinition['/creators/{creatorId}/dashboard-data']['GET']['response'];

const getCreatorDashboardData = async ({ creatorId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/dashboard-data`);
  return response.data;
};

export default getCreatorDashboardData;
