import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/calendar-accounts']['POST'];
type Input = ThisApi['params'] & ThisApi['body'];
type Output = ThisApi['response'];

const createCalendarAccount = async ({ creatorId, personId, code, source }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/creators/${creatorId}/calendar-accounts`, {
    code,
    personId,
    source,
  });
  return response.data;
};

export default createCalendarAccount;
