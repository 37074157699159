import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/confido-payment-token']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getConfidoPaymentToken = async ({ creatorId, payableId, personId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/creators/{creatorId}/confido-payment-token', { creatorId });
  const data = await noodleApiClient.get(path, { params: { payableId, personId } });
  return data.data;
};

export default getConfidoPaymentToken;
