import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/collaborators/{collaboratorId}';
type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/collaborators/{collaboratorId}']['PUT'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const updateUserWorkflowCollaborator = async ({ userWorkflowId, collaboratorId, ...form }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { collaboratorId, userWorkflowId });
  const response = await noodleApiClient.put(path, form);
  return response.data;
};

export default updateUserWorkflowCollaborator;