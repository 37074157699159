import { useCallback, useEffect, useMemo, useState } from "react";
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { useIsUserInitialized, useUser } from '@providers/Auth';
import { serializers, useLocalStorageState } from '@providers/LocalStorageState';
import { getTeams } from '@tsClient/my';
import * as ApiModles from '@typings/api-models';
import TeamsContext from './TeamsContext';
import { Team, TeamOwner } from './constants';

const PAGE_SIZE = 200;
const PAGE = 1;

const TeamsProvider: React.FC = ({ children }) => {
  const [user] = useUser();
  const isUserInitialized = useIsUserInitialized();
  const [teams, setTeams] = useState<Team[]>([]);
  const [currentTeam, setCurrentTeam] = useState<Team | null>(null);
  const [currentTeamOwner, setCurrentTeamOwner] = useState<TeamOwner | null>(null);
  const [currentRole, setCurrentRole] = useState({
    admin: false,
    finance: false,
    manageTeam: false,
    role: "caseWorker" as ApiModles.TeamMemberRoles['role'],
  });
  const [teamUserId, setTeamUserId] = useLocalStorageState<string | null>({
    key: 'teamUserId',
    prefix: 'teams',
    ...serializers.string,
  });
  const [creatorId, setCreatorId] = useLocalStorageState<string | null>({
    key: 'creatorId',
    prefix: 'teams',
    ...serializers.string,
  });
  const [currentTeamId, setCurrentTeamId] = useLocalStorageState<string | null>({
    key: 'currentTeamId',
    prefix: 'teams',
    ...serializers.string,
  });

  const [hasFetchedTeams, setHasFetchedTeams] = useState(false);
  const { data: fetchedTeams, getData: getTeamsForUser } = useNoodleApi(tsClient.my.getTeams);

  const clearTeamData = useCallback((): void => {
    setCurrentTeam(null);
    setCurrentTeamId(null);
    setCurrentTeamOwner(null);
    setCreatorId(null);
    setTeamUserId(null);
    setTeams([]);
    setHasFetchedTeams(true);
    setCurrentRole({
      admin: false,
      finance: false,
      manageTeam: false,
      role: "caseWorker",
    });
  }, [setCreatorId, setCurrentTeamId, setTeamUserId]);

  const wrapSetCurrentTeam = useCallback((newTeam: Team | null) => {
    if (!newTeam) {
      clearTeamData();
    } else {
      setCurrentTeam(newTeam);
      setCurrentTeamId(newTeam.id);
      setCurrentTeamOwner(newTeam.teamOwner);
      setTeamUserId(newTeam.teamOwner.person.id);
      setCreatorId(newTeam.teamOwner.id);
      setCurrentRole(newTeam.role);
    }
  }, [setCurrentRole, clearTeamData, setCurrentTeam, setCurrentTeamId, setCurrentTeamOwner, setTeamUserId, setCreatorId]);

  useEffect(() => {
    if (user && user?.id && !user.isAnonymous) {
      setHasFetchedTeams(false);
      getTeamsForUser({
        page: PAGE,
        perPage: PAGE_SIZE,
      });
    }
  }, [user, getTeamsForUser]);

  const setTeamData = async (teamsArr: Team[]): Promise<void> => {
    setTeams(teamsArr);

    const storedTeam = teamsArr.find((team) => team.id === currentTeamId);
    const teamToSet: Team | null = storedTeam || (teamsArr.length > 0 && teamsArr[0]) || null;

    if (teamToSet) {
      wrapSetCurrentTeam(teamToSet);
    } else {
      clearTeamData();
    }
    setHasFetchedTeams(true);
  };

  const refetchData = useCallback(async (): Promise<Awaited<ReturnType<typeof getTeams>>['items']> => {
    if (user && user?.id && !user.isAnonymous) {
      const { data } = await getTeamsForUser({ page: PAGE, perPage: PAGE_SIZE });
      setTeamData(data?.items || []);
      return data?.items || [];
    }
    return [];
  }, [getTeamsForUser, user]);

  useEffect(() => {
    if (isUserInitialized && !user) {
      clearTeamData();
    }
  }, [clearTeamData, isUserInitialized, user]);

  useEffect(() => {
    if (fetchedTeams) {
      if ((fetchedTeams?.items?.length || 0) > 0) {
        setTeamData(fetchedTeams.items);
      } else {
        clearTeamData();
        setHasFetchedTeams(true);
      }
    }
  }, [fetchedTeams, clearTeamData, setTeamUserId]);

  const value = useMemo(() => ({
    creatorId,
    currentTeam,
    currentTeamOwner,
    hasFetchedTeams,
    refetchTeams: refetchData,
    role:currentRole,
    setCurrentTeam: wrapSetCurrentTeam,
    setTeamUserId,
    teamUserId,
    teams,
  }), [
    hasFetchedTeams,
    wrapSetCurrentTeam,
    creatorId,
    currentRole,
    currentTeamOwner,
    currentTeam,
    teamUserId,
    teams,
    setTeamUserId,
    refetchData,
  ]);

  return (
    <TeamsContext.Provider value={value}>
      {children}
    </TeamsContext.Provider>
  );
};

export default TeamsProvider;
