import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/confido-signup-link']['POST'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const createConfidoSignUpLink = async ({ creatorId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/creators/{creatorId}/confido-signup-link', { creatorId });
  const data = await noodleApiClient.post(path);
  return data.data;
};

export default createConfidoSignUpLink;
