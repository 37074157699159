import noodleApiClient, { ApiDefinition } from '@noodleApi';

type Input = ApiDefinition['/products/{creatorSlug}/{productSlug}/messages/{messageId}']['PUT']['params'] & ApiDefinition['/products/{creatorSlug}/{productSlug}/messages/{messageId}']['PUT']['body'];
type Output = ApiDefinition['/products/{creatorSlug}/{productSlug}/messages/{messageId}']['PUT']['response'];

const updateMessage = async ({ creatorSlug, productSlug, messageId, ...body }: Input): Promise<Output> => {
  const data = await noodleApiClient.put(`/products/${creatorSlug}/${productSlug}/messages/${messageId}`, body);
  return data.data;
};

export default updateMessage;