import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/my/creators/{creatorId}/user-profile-tabs';
type THIS_API = ApiDefinition['/my/creators/{creatorId}/user-profile-tabs']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getUserProfileTabs = async ({ creatorId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getUserProfileTabs;