import noodleApiClient from '@noodleApi';

type UpdateHandbookStepProps = {
  productId: string;
  actions: unknown;
};

const updateHandbookSteps = async ({ productId, actions }: UpdateHandbookStepProps): Promise<void> => {
  const data = await noodleApiClient.put(`/products/${productId}/steps`, actions);
  return data.data;
};

export default updateHandbookSteps;
