import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/collaborators';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const addUserWorkflowCollaborator = async ({ userWorkflowId, email, name, sendEmail }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, {userWorkflowId});
  const data = await noodleApiClient.post(path, { email, name, sendEmail });
  return data.data;
};

export default addUserWorkflowCollaborator;