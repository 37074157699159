const getClient = (): facebook.Pixel.Event => window?.fbq || ((): void => undefined);

type Data = facebook.Pixel.CustomParameters;
export const init = (id: string | null): void => {
  if (id) {
    getClient()('init', id);
  }
};

export const pageView = (): void => {
  getClient()('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, data: Data = {}): void => {
  getClient()('track', name, data);
};

export const track = (title: string, data: Data = {}): void => {
  getClient()('track', title, data);
};

export const trackCustom = (title: string, data: Data = {}): void => {
  getClient()('trackCustom', title, data);
};

export const trackSingle = (pixel: string, title: string, data: Data = {}): void => {
  if (pixel) {
    getClient()('trackSingle', pixel, title, data);
  }
};