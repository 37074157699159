import React from 'react';
import * as Sentry from '@sentry/react';
import ErrorComponent from './ErrorComponent';

const FallbackFunction = ({ eventId }: { eventId: string | null }): React.ReactElement => <ErrorComponent eventId={eventId}/>;

const ErrorBoundary: React.FC = ({ children }) => (
  <Sentry.ErrorBoundary fallback={FallbackFunction} showDialog={true}>
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
