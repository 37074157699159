import buildPath from '@/tsClient/utilities/buildPath';
import integrationsClient, { ApiDefinition } from '@/tsClient/servers/integrationsService';

type THIS_API = ApiDefinition['/creators/{creatorId}/accounts']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const listCreatorAccounts = async ({ creatorId, page, perPage }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/creators/{creatorId}/accounts', { creatorId });
  const response = await integrationsClient.get(path, {
    params: { page, perPage },
  });
  return response.data;
};

export default listCreatorAccounts;
