import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { JWT_COOKIE_NAME, DEPLOYMENT_TIER, INTEGRATIONS_BACKEND_HOST, PACKAGE_NAME, VERSION } from '@configuration/client';
import { getCookiesAsJson } from '@providers/Auth';
import isBrowser from '@helpers/isBrowser';
import axiosRetryCondition from '../axiosRetryCondition';

if (!INTEGRATIONS_BACKEND_HOST) {
  throw new Error('INTEGRATIONS_BACKEND_HOST not defined');
}

const client = axios.create({
  baseURL: INTEGRATIONS_BACKEND_HOST,
});

client.interceptors.request.use(
  (config): AxiosRequestConfig => {
    if (isBrowser()) {
      const token = getCookiesAsJson()[JWT_COOKIE_NAME] || window.httpToken;
      if (!config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers = {};
      }
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = token;
      }
      // eslint-disable-next-line no-param-reassign
      config.headers['x-noodle-client-version'] = `${DEPLOYMENT_TIER}@${PACKAGE_NAME}@${VERSION}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosRetry(client, {
  retryCondition: axiosRetryCondition,
});

export default client;
