import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/inbox']['GET'];

type Input = ThisApi['query'] & ThisApi['params'];

const getCreatorInboxItems = async ({ search, page, perPage, types, creatorId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/inbox`, {
    params: {
      page,
      perPage,
      search,
      types,
    },
  });
  return response.data;
};

export default getCreatorInboxItems;
