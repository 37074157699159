import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/star-creators']['GET'];

const getNoodleStars = async (): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get('/creators/star-creators');
  return response.data;
};

export default getNoodleStars;
