import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}/contexts/{contextId}']['DELETE'];

const deleteWorkflowContext = async ({ contextId, workflowId }: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.delete(`/workflows/${workflowId}/contexts/${contextId}`);
  return response.data;
};

export default deleteWorkflowContext;
