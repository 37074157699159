import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/persons/{id}/payments']['POST'];

type Input = ThisApi['body'] & ThisApi['params'];
type Output = ThisApi['response'];

const initiatePaymentForUser = async ({ id, ...body }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/persons/${id}/payments`, body);
  return response.data;
};

export default initiatePaymentForUser;
