import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/members/{personId}/notes']['POST'];

type Params = ThisApi['params'];
type Body = ThisApi['body'];

const createMemberNote = async ({ creatorSlug, personId, content }: Params & Body): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/creators/${creatorSlug}/members/${personId}/notes`, { content });
  return response.data;
};

export default createMemberNote;