import Spinner from '@components/Icons/Spinner';
import { m } from 'framer-motion';
import s from './ProgressIndicator.module.scss';

type PropsTypes = {
  isPage?: boolean;
  hasSpace?: boolean;
  size?: number;
  isAbsolute?: boolean;
  isCentered?: boolean;
  color?: string;
  isInline?: boolean;
  className?: string;
};

const MotionSpinner = m(Spinner);

const ProgressIndicator: React.FC<PropsTypes> = ({
  isPage = false,
  hasSpace = false,
  size = 24,
  isAbsolute = false,
  isCentered = false,
  isInline = false,
  color,
  className,
}) => (
  <m.span
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    className={[
      s['progress-indicator'],
      ...(isInline ? [s['progress-indicator--inline']] : []),
      ...(isAbsolute ? [s['progress-indicator--absolute']] : []),
      ...(isPage ? [s['progress-indicator--page']] : []),
      ...(isCentered ? [s['progress-indicator--centered']] : []),
      ...(hasSpace ? [s['progress-indicator--has-space']] : []),
      ...(className ? [className] : []),
    ].join(' ')}
    style={{ maxHeight: `${size}px` }}
  >
    <MotionSpinner animate={{ rotate: 180 }} transition={{ duration: 0.5, ease: 'linear', repeat: Infinity }} size={size} color={color || 'var(--color-gray-75)'} />
  </m.span>
);

export default ProgressIndicator;
