import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/form-requests/{formRequestId}']['GET'];

type Input = ThisApi['params'];
type Output = ThisApi['response'];

const getFormRequestById = async ({ formRequestId }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/form-requests/{formRequestId}', { formRequestId });
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getFormRequestById;