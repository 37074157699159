import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}/clone']['POST'];

const cloneWorkflow = async ({ id }: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/workflows/${id}/clone`);
  return response.data;
};

export default cloneWorkflow;
