import ApiError from '@helpers/ApiError';
import * as Sentry from '@sentry/nextjs';
import type { Transaction } from '@sentry/types';
import { logError } from '../ErrorTracking/logError';

export type TransactionNames =
  | 'sign-in'
  | 'sign-up'
  | 'send-message'
  | 'cart-submit';

export default class SystemHealthMonitor {
  sentryTransaction: Transaction | null;

  constructor(name: TransactionNames) {
    try {
      const sentryTransaction = Sentry.startTransaction({ name }, { sampleAll: true });
      Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(sentryTransaction));
      sentryTransaction.setTag('health', name);
      sentryTransaction.setTag('healthState', 'incomplete');
      this.sentryTransaction = sentryTransaction;
    } catch (error) {
      logError(error);
      this.sentryTransaction = null;
    }
  }

  getState(error: ApiError): string {
    if (
      (error.type === 'LoginFailedError' && error.reason() === 'Wrong password')
      || (error.type === 'OTPVerificationError' && error.reason() === 'Incorrect code')
      || error.type === 'IsLandlinePhoneNumberError'
      || error.type === 'LoginNoSuchUserError'
    ) {
      return 'ignore';
    }

    return 'error';
  }

  finish(error?: unknown | null | undefined): void {
    if (!this.sentryTransaction) {
      return;
    }
    try {
      if (error) {
        const apiError = ApiError.create(error);
        const state = this.getState(apiError);
        this.sentryTransaction.setTag('healthState', state);
        // state is limitted to the SpanStatus enum.
        // Ok, Cancelled, and Unknown don't count towards failure_count or failure_rate
        // https://develop.sentry.dev/sdk/event-payloads/properties/status/
        if (state === 'ignore') {
          this.sentryTransaction.setStatus('unknown');
        } else {
          this.sentryTransaction.setStatus('invalid_argument');
        }
        this.sentryTransaction.setTag('healthReason', apiError.reason());
      } else {
        this.sentryTransaction.setTag('healthState', 'ok');
        this.sentryTransaction.setStatus('ok');
        this.sentryTransaction.setTag('healthReason', 'successful');
      }
      this.sentryTransaction.finish();
    } catch (finishError) {
      logError(finishError);
    }
  }
}
