import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/form-request-templates/tags']['GET'];
type Input = ThisApi['params'];

const getCreatorsFormRequestTemplateTags = async ({ creatorId }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.get(`/creators/${creatorId}/form-request-templates/tags`);
  return data.data;
};

export default getCreatorsFormRequestTemplateTags;
