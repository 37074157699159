import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { JWT_COOKIE_NAME, DEPLOYMENT_TIER, NOODLE_DOCUMENT_HOST, PACKAGE_NAME, VERSION } from '@configuration/client';
import { getCookiesAsJson } from '@providers/Auth';
import isBrowser from '@helpers/isBrowser';
import { mixpanelTrack } from '@providers/Mixpanel';
import axiosRetryCondition from '../axiosRetryCondition';

if (!NOODLE_DOCUMENT_HOST) {
  throw new Error('NOODLE_DOCUMENT_HOST not defined');
}

const client = axios.create({
  baseURL: NOODLE_DOCUMENT_HOST,
});

client.interceptors.request.use(
  (config): AxiosRequestConfig => {
    if (isBrowser()) {
      const token = getCookiesAsJson()[JWT_COOKIE_NAME] || window.httpToken;
      if (!config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers = {};
      }
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = token;
      }
      // eslint-disable-next-line no-param-reassign
      config.headers['x-noodle-client-version'] = `${DEPLOYMENT_TIER}@${PACKAGE_NAME}@${VERSION}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
  ,
);

client.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error?.response?.status === 403) {
      mixpanelTrack('Forbidden request', {
        message: error?.message,
        route: error?.config?.url,
      });
    }
    return Promise.reject(error);
  },
);

axiosRetry(client, {
  retryCondition: axiosRetryCondition,
});

export default client;
