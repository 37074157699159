import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/my/creators/{creatorId}/tasks']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getCustomerTasks = async ({ creatorId, page, perPage}: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/my/creators/{creatorId}/tasks', { creatorId });
  const data = await noodleApiClient.get(path, { params: { page, perPage }});
  return data.data;
};

export default getCustomerTasks;