import { ReactElement } from 'react';
import useIPGeolocation from '@hooks/useIPGeolocation';
import { m } from 'framer-motion';
import Buttons from '@components/Buttons';
import useDismissedMessages from '@providers/Messages/useDismissedMessages';
import { isInIframe } from '@helpers/helper';
import s from './GDPRBanner.module.scss';

const GDPR_DISMISS_KEY = 'gdpr_banner';

const GDPRBanner = (): ReactElement => {

  const { dismissMessage, isMessageDismissed } = useDismissedMessages();

  const [geolocation] = useIPGeolocation();

  const dismissDialog = async (): Promise<void> => {
    dismissMessage(GDPR_DISMISS_KEY);
  };

  if (isMessageDismissed(GDPR_DISMISS_KEY) || geolocation?.continent?.code !== 'EU' || isInIframe()) return <></>;

  return (
    <m.dialog
      initial={{ opacity: 0, y: 80 }}
      animate={{
        opacity: 1,
        transition: {
          delay: 0.5,
        },
        y: 0,
      }}
      className={s.wrapper}
      open
    >
      <p>By continuing to use this website, you acknowledge and agree to the use of cookies.</p>
      <form method="dialog">
        <Buttons isSecondary onClick={dismissDialog}>
          Accept
        </Buttons>
      </form>
    </m.dialog>
  );
};

export default GDPRBanner;
