import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/ai/creators/:creatorId/customizations/:type']['PUT'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const setAiCustomization = async ({type, creatorId, ...body}: Input): Promise<Output> => {
  const response = await noodleApiClient.put(`/ai/creators/${creatorId}/customizations/${type}`, body );
  return response.data;
};

export default setAiCustomization;