import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/inbox/{inboxItemId}']['GET'];

type Input = ThisApi['params'];

const getInboxItemById = async ({ inboxItemId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/inbox/${inboxItemId}`);
  return response.data;
};

export default getInboxItemById;
