import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/completion']['POST'];
type Input = ThisApi['params'] & ThisApi['query'];

const completeDocumentRequest = async ({ accessToken, documentRequestUserId }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.post(`/document-request-users/${documentRequestUserId}/completion`, null, {
    params: { accessToken },
  });
  return response.data;
};

export default completeDocumentRequest;
