import { createContext } from 'react';

type DismissedMessage = string | boolean;
export type DismissedMessages = Record<string, DismissedMessage>;

export const DEFAULT_HIDE_FOR_DAYS = 30;

type IsMessageDismissedOptions = {
  days?: number;
};

export type MessagesContextType = {
  isFetching: boolean;
  isInitialized: boolean;
  dismissMessage: (key: string) => void;
  isMessageDismissed: (key: string | undefined, options?: IsMessageDismissedOptions) => boolean;
};

const MessagesContext = createContext<MessagesContextType>({
  dismissMessage: () => undefined,
  isFetching: false,
  isInitialized: false,
  isMessageDismissed: () => false,
});

export default MessagesContext;
