import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/custom-terms/{id}/reset']['POST'];
type Input = ThisApi['params'];

const resetCustomTerms = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/custom-terms/${id}/reset`);
  return response.data;
};

export default resetCustomTerms;
