import { createContext } from 'react';
import { Agency } from './constants';

type AgencyContextType = {
  hasFetchedAgencies: boolean;
  setCurrentAgency: (newAgency: Agency | null) => void;
  currentAgency: Agency | null;
  currentAgencyId: string | null;
  currentAgencyOwnerId: string | null;
  agencies: Agency[];
};

const AgencyContext = createContext<AgencyContextType>({
  agencies: [],
  currentAgency: null,
  currentAgencyId: null,
  currentAgencyOwnerId: null,
  hasFetchedAgencies: false,
  setCurrentAgency: () => undefined,
});

export default AgencyContext;