import isBrowser from "@helpers/isBrowser";

// Cookies used to be not be qualified by domain, so scheduling.noodle.shop had a different cookie than noodle.shop.
// This was changed. Need to clear the legacy cookie on logout.
const createLegacyCookie = (name: string, value: string, days?: number): string => {
  if (!isBrowser()) {
    return '';
  }

  const parts = [];
  parts.push(`${name}=${value}`);

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    parts.push(`expires=${date.toUTCString()}`);
  }

  parts.push(
    'path=/',
    'SameSite=None',
    'Secure',
  );

  const cookie = parts.join('; ');
  document.cookie = cookie;

  return cookie;
};

export default createLegacyCookie;