import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/re-open']['POST'];
type Input = ThisApi['params'] & ThisApi['query'];

const reOpenDocumentRequest = async ({ accessToken, documentRequestUserId }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.post(`/document-request-users/${documentRequestUserId}/re-open`, null, {
    params: { accessToken },
  });
  return response.data;
};

export default reOpenDocumentRequest;