import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/my/associated-users']['GET'];
type Input = ThisApi['query'];

const getAssociatedUsers = async ({ page, perPage }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get('/my/associated-users', {
    params: { page, perPage },
  });
  return response.data;
};

export default getAssociatedUsers;
