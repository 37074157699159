import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/invoices';
type ThisApi = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = ThisApi['body'];

const createUserWorkflowInvoice = async (body: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(PATH_TEMPLATE, body);
  return response.data;
};

export default createUserWorkflowInvoice;