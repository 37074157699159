import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/templates']['POST'];
type Input = ThisApi['body'];
type Output = ThisApi['response'];

const addWorkflowsFromTemplate = async ({ onBehalfOf, templates }: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/workflows/templates', { onBehalfOf, templates });
  return response.data;
};

export default addWorkflowsFromTemplate;
