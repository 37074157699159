import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/form-request-templates/{id}']['GET'];

type Input = ThisApi['params'];

const getFormRequestTemplateById = async ({ id }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.get(`/form-request-templates/${id}`);
  return data.data;
};

export default getFormRequestTemplateById;
