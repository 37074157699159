import { useRouter } from 'next/router';
import Link from '@components/CustomLink';

import BackButton from '@components/BackButton';

import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';

import { useUser } from '@hooks';
import { MouseEventHandler } from 'react';
import s from './Page401.module.scss';

const CREATOR_SIGN_IN_PATHS = ['/dashboard/', '/dashboard/queue/', '/dashboard-test/queue/'];

const Page401: React.FC = () => {
  const router = useRouter();
  const [user, _setUser, setUserByToken] = useUser();

  const handleLogOut: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    setUserByToken(null);
    router.push(getUrl(IDENTIFIERS.LOGIN));
  };

  return (
    <main className={s.unauthorized__wrapper}>
      <BackButton />
      <title>Unauthorized</title>
      <h1 className="heading-md">401 - Unauthorized</h1>
      <p className='body-sm'>
        Go <Link to={getUrl(IDENTIFIERS.HOME)} prefetch={false}>Home</Link> or&nbsp;
        {user?.id
          ? (
            <Link to={getUrl(IDENTIFIERS.LOGIN)} prefetch={false}>
              <a onClick={handleLogOut}>Change Account</a>
            </Link>
          )
          : (
            <Link
              to={getUrl(CREATOR_SIGN_IN_PATHS.includes(router.pathname) ? IDENTIFIERS.CREATOR_SIGN_IN : IDENTIFIERS.LOGIN)}
              prefetch={false}
            >
              Login
            </Link>
          )}
      </p>
    </main>
  );
};

export default Page401;
