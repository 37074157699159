import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-requests']['POST'];
type Input = ThisApi['body'];

const createDocumentRequest = async ({ ...body }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.post('/document-requests', body);
  return response.data;
};

export default createDocumentRequest;
