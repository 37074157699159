import { useState, useEffect } from 'react';
import { useToastContext } from 'context/ToastContext';
import { isInIframe } from '@helpers/helper';
import { IframeEvent } from '@helpers/iframeMessages';
import { m } from 'framer-motion';
import Toast from './Toast';
import s from './Toasts.module.scss';

type IShowToast = boolean | undefined;

export default function ToastContainer(): JSX.Element | null {
  const { toasts, removeToast } = useToastContext();
  const [showToastInternally, setShowToastsInternally] = useState<IShowToast>();

  useEffect(() => {
    if (isInIframe()) {
      window.parent.postMessage(
        {
          message: 'get-toast-position-attribute',
          postedTo: window.name,
          // @deprecated - stop sending id when widget js's are past browser caches
          value: { id: window.name },
        },
        '*',
      );

      const eventHandler = (event: IframeEvent<{ handleToastsInternally: string }>): void => {
        if (event?.data?.message === 'toast-position-attribute') {
          const { handleToastsInternally } = event.data?.value || {};
          setShowToastsInternally(handleToastsInternally === 'true');
          window.removeEventListener('message', eventHandler);
        }
      };

      window.addEventListener('message', eventHandler, false);
    } else {
      setShowToastsInternally(true);
    }
  }, []);

  if (isInIframe() && showToastInternally === false) { // it should be false, it undefined by default
    toasts.slice(0, 3).forEach((toast) => {
      if (toast) {
        window.parent.postMessage(
          {
            message: 'add-toast',
            postedTo: window.name,
            value: { toast },
          },
          '*',
        );
        setTimeout(() => removeToast(toast), 3000);
      }
    });

    return null;
  }

  if(showToastInternally) {
    return (
      <div className={s.toasts}>
        {toasts
        && toasts.slice(0, 3).map((toast, index) => (
          <m.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} transition={{ delay: index * 0.1 }} key={toast.id} className={s['toasts-container']}>
            <Toast id={toast.id} type={toast.type} message={toast.message} />
          </m.div>
        ))}
      </div>
    );
  }

  return null;
}
