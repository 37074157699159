import React, { createContext, useRef, useMemo, memo, FC, useState, useContext, useEffect } from 'react';
import { Canvas, useFrame, extend } from '@react-three/fiber';
import * as THREE from 'three';
import { shaderMaterial } from '@react-three/drei';
import Image from 'next/image';
import ErrorBoundary from './ErrorBoundary';

extend({ TorusKnotGeometry: THREE.TorusKnotGeometry });

interface NoodleAIContextProps {
  setErrorOccurred: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoodleAIContext = createContext<NoodleAIContextProps | null>(null);

const CombinedColorMaterial = shaderMaterial(
  {
    color1: new THREE.Color(0xfce067),
    color2: new THREE.Color(0xdc5ced),
    color3: new THREE.Color(0x554faf),
    color4: new THREE.Color(0x32b8f9),
    color5: new THREE.Color(0x63d5be),
    size: 1.0,
  },
  // Vertex Shader
  `
    varying vec3 vNormal;
    varying vec3 vPosition;
    varying vec2 vUv;

    void main() {
      vNormal = normalize(normalMatrix * normal);
      vPosition = (modelViewMatrix * vec4(position, 1.0)).xyz;
      vUv = uv;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment Shader
  `
    uniform vec3 color1;
    uniform vec3 color2;
    uniform vec3 color3;
    uniform vec3 color4;
    uniform vec3 color5;
    uniform float size;

    varying vec3 vNormal;
    varying vec3 vPosition;
    varying vec2 vUv;

    void main() {
      float fresnel = abs(dot(normalize(vNormal), normalize(vPosition)));
      fresnel = 1.0 - fresnel;
      fresnel = pow(fresnel, 2.0);

      float minPosition = -size * 0.3;
      float maxPosition = size * 0.3;
      float factor = (vPosition.x - minPosition) / (maxPosition - minPosition);

      factor = clamp(factor, 0.0, 1.0);

      vec3 gradientColor;
      if (factor < 0.25) {
        gradientColor = mix(color1, color2, factor / 0.25);
      } else if (factor < 0.5) {
        gradientColor = mix(color2, color3, (factor - 0.25) / 0.25);
      } else if (factor < 0.75) {
        gradientColor = mix(color3, color4, (factor - 0.5) / 0.25);
      } else {
        gradientColor = mix(color4, color5, (factor - 0.75) / 0.25);
      }

      vec3 combinedColor = gradientColor + fresnel * vec3(1.0);

      combinedColor = clamp(combinedColor, 0.0, 1.0);

      gl_FragColor = vec4(combinedColor, 1.0);
    }
  `,
);

interface TorusKnotProps {
  size?: number;
  animate?: boolean;
  mode?: 'webgl' | 'image';
}

const TorusKnot: FC<TorusKnotProps> = memo(({ size = 24, animate }) => {
  const meshRef = useRef<THREE.Mesh>(null);
  const context = useContext(NoodleAIContext);

  const material = useMemo(() => {
    try {
      const materialInstance = new CombinedColorMaterial();
      materialInstance.uniforms.size.value = size;
      return materialInstance;
    } catch (error) {
      context?.setErrorOccurred(true);
      return null;
    }
  }, [size, context]);

  useEffect(
    () => () => {
      if (material) {
        material.dispose();
      }
    },
    [material],
  );

  useFrame(() => {
    if (meshRef.current) {
      if (animate) {
        meshRef.current.rotation.y += 0.01;
        meshRef.current.rotation.z += 0.01;
      } else {
        meshRef.current.rotation.y = THREE.MathUtils.lerp(meshRef.current.rotation.y, 0, 0.05);
        meshRef.current.rotation.z = THREE.MathUtils.lerp(meshRef.current.rotation.z, 0, 0.05);
      }
    }
  });

  if (!material) return null;

  const knotMajorRadius = size * 0.2;
  const knotTubeRadius = size * 0.05;

  return (
    <mesh ref={meshRef}>
      <torusKnotGeometry args={[knotMajorRadius, knotTubeRadius, 100, 16, 2, 5]} />
      <primitive attach="material" object={material} />
    </mesh>
  );
});

TorusKnot.displayName = 'TorusKnot';

const isWebGLAvailable = (() => {
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    const canvas = document.createElement('canvas');
    return Boolean(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
  } catch (e) {
    return false;
  }
})();

const NoodleAI: FC<TorusKnotProps> = memo(({ size = 24, animate, mode = 'webgl' }) => {
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    setReadyToRender(true);
  }, []);

  const contextValue = useMemo(() => ({ setErrorOccurred }), [setErrorOccurred]);

  if (mode === 'image' || !isWebGLAvailable || errorOccurred || !readyToRender) {
    return <Image src="/images/dashboard/noodle-ai-fallback.png" alt="" width={size} height={size} />;
  }

  return (
    <NoodleAIContext.Provider value={contextValue}>
      <ErrorBoundary fallback={<Image src="/images/dashboard/noodle-ai-fallback.png" alt="" width={size} height={size} />}>
        <Canvas
          style={{ height: `${size}px`, width: `${size}px` }}
          camera={{ fov: 50, position: [0, 0, size] }}
          onCreated={({ gl }) => {
            gl.getContext().canvas.addEventListener('webglcontextlost', e => {
              e.preventDefault();
              setErrorOccurred(true);
            });
          }}
          onError={() => setErrorOccurred(true)}
        >
          <ambientLight intensity={0.8} />
          <directionalLight position={[5, 5, 5]} intensity={0.5} />
          <TorusKnot size={size} animate={animate} />
        </Canvas>
      </ErrorBoundary>
    </NoodleAIContext.Provider>
  );
});

NoodleAI.displayName = 'NoodleAI';

export default NoodleAI;
