import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { sendMessageUp } from '@helpers/iframeMessages';
import { logError } from '@providers/ErrorTracking';
import isBrowser from '@helpers/isBrowser';
import { JWT_COOKIE_NAME } from '@configuration/client';
import { AuthUser } from './constants';

function getCookieUser(): AuthUser | null {
  const jwtCookie = Cookies.get(JWT_COOKIE_NAME);
  try {
    if (jwtCookie) {
      const decoded = jwtDecode(jwtCookie) as AuthUser;
      decoded.token = jwtCookie;
      return decoded;
    }
  } catch (error) {
    logError(error);
  }
  if (!isBrowser()) {
    return null;
  }

  sendMessageUp({
    message: 'get-client-cookie',
  });

  return null;
}

export default getCookieUser;
