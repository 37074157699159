import Axios from 'axios';
import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';
import { mixpanelTrack } from '@providers/Mixpanel';

const PATH_TEMPLATE = '/auth/otp-requests';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const requestOTP = async (payload: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  try {
    const response = await noodleApiClient.post(path, payload);
    return response.data;
  } catch (error) {
    if (Axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      if (errorData?.type === 'IsLandlinePhoneNumberError') {
        mixpanelTrack('Attempting to verify a landline', errorData);
      } else if (errorData?.type === 'OTPRequestError') {
        mixpanelTrack('Failed to send OTP request', errorData);
      } else if (errorData?.type === 'InvalidPhoneNumberError') {
        mixpanelTrack('Invalid phone number for OTP', errorData);
      }
    }
    throw error;
  }
};

export default requestOTP;
