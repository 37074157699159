import React, {
  Fragment,
  forwardRef,
  PropsWithChildren,
  ButtonHTMLAttributes,
  ForwardRefRenderFunction,
  MouseEvent,
  ReactElement,
  CSSProperties,
} from 'react';
import classNames from 'classnames';
import Shimmer from '@components/Shimmer';
import ProgressIndicator from 'components/ProgressIndicator';

import s from './Buttons.module.scss';

export type ButtonProps = Partial<{
  icon: boolean | ReactElement;
  title: string;
  isFlat: boolean;
  isExtraSmall: boolean;
  isSmall: boolean;
  isLarge: boolean;
  isBold: boolean;
  isZeroBorderRadius: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  isThird: boolean;
  disabled: boolean;
  isPrimary: boolean;
  isFourth: boolean;
  isSkewed: boolean;
  isWrapper: boolean;
  ariaLabel: string;
  className: string;
  iconAfter?: ReactElement;
  iconBefore: ReactElement;
  isFetching: boolean;
  isSecondary: boolean;
  isFullWidth: boolean;
  multiline: boolean;
  isGray: boolean;
  isEdit: boolean;
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  isShimmering?: boolean;
  style?: CSSProperties;
}>;

const Buttons: ForwardRefRenderFunction<HTMLButtonElement, PropsWithChildren<ButtonProps>> = (
  {
    icon,
    title,
    isFlat,
    isExtraSmall,
    isSmall,
    isLarge,
    isBold,
    isZeroBorderRadius,
    onClick,
    isThird,
    disabled,
    children,
    isPrimary,
    isFourth,
    isSkewed,
    isWrapper,
    ariaLabel,
    className,
    iconAfter,
    iconBefore,
    isFetching,
    isSecondary,
    isFullWidth,
    multiline,
    isGray,
    isEdit,
    type,
    isShimmering,
    style,
  },
  ref,
) => {
  const classes = classNames(className, {
    [s['custom-button']]: !isWrapper,
    [s['custom-button--icon']]: icon,
    [s['custom-button--flat']]: isFlat,
    [s['custom-button--extra--small']]: isExtraSmall,
    [s['custom-button--small']]: isSmall,
    [s['custom-button--third']]: isThird,
    [s['custom-button--large']]: isLarge,
    [s['custom-button--primary']]: isPrimary,
    [s['custom-button--fourth']]: isFourth,
    [s['custom-button--skewed']]: isSkewed,
    [s['custom-button--bold']]: isBold,
    [s['custom-button--zero-border-rad']]: isZeroBorderRadius,
    [s['custom-button--wrapper']]: isWrapper,
    [s['custom-button--secondary']]: isSecondary,
    [s['custom-button--has-icon-after']]: iconAfter,
    [s['custom-button--is-full-width']]: isFullWidth,
    [s['custom-button--has-icon-before']]: iconBefore,
    [s['custom-button--multiline']]: multiline,
    [s['custom-button--gray']]: isGray,
    [s['custom-button--shimmer']]: isShimmering,
    [s['custom-button--edit']]: isEdit,
  });

  const getContent = (): ReactElement => {
    if (isWrapper) {
      return (
        <Fragment>
          {children}
          {isFetching && <ProgressIndicator />}
        </Fragment>
      );
    }

    if (icon) {
      return (
        <Fragment>
          {!isFetching && <span className={s['custom-button__icon']}>{icon}</span>}
          {isFetching && <span className={s['custom-button__icon']}>{<ProgressIndicator size={20} />}</span>}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {iconBefore && <span className={`${s['custom-button__icon']} ${s['custom-button__icon--before']}`}>{iconBefore}</span>}
        <span className={s['custom-button__text']}>{children}</span>
        {(isFetching || iconAfter) && (
          <span className={`${s['custom-button__icon']} ${s['custom-button__icon--after']}`}>
            {isFetching && <ProgressIndicator />}
            {iconAfter && !isFetching && <Fragment>{iconAfter}</Fragment>}
          </span>
        )}
      </Fragment>
    );
  };

  let ariaLabelString = ariaLabel || title;
  if (!ariaLabelString) {
    const firstChild = React.Children.toArray(children)[0];
    if (typeof firstChild === 'string') {
      ariaLabelString = firstChild;
    } else {
      ariaLabelString = 'button';
    }
  }

  const loading = isFetching || isShimmering;

  return (
    <button
      ref={ref}
      title={title}
      onClick={onClick}
      className={classes}
      disabled={disabled || loading}
      aria-label={ariaLabelString}
      type={type}
      style={loading ? { ...style, cursor: 'wait' } : style}
    >
      {isShimmering && <Shimmer className={s['custom-button__loader']} />}
      {getContent()}
    </button>
  );
};

export default forwardRef(Buttons);
