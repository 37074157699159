import { Id } from "@typings/common";
import { NoodleProductTypes } from '@typings/graphql-models';

type ThisProduct = {
  id: Id,
  title?: string | null;
  creator?: {
    name?: string | null;
    slug: string;
  } | null;
  productTypes: {
    noodleProductType?: NoodleProductTypes | null;
  }[];
};

type Price = {
  price: number;
};

type EcommerceProduct = {
  brand: string;
  category: string | undefined;
  id: string;
  item_brand: string;
  item_category: string;
  item_id: Id;
  item_name: string;
  name: string;
  price: number;
  quantity: number;
};

// eslint-disable-next-line import/prefer-default-export
export function getEcommerceItemFromProduct(product: ThisProduct, price?: Price): EcommerceProduct {
  return {
    brand: product?.creator?.name || product?.creator?.slug || '__UNNAMED__CREATOR__',
    category: (product.productTypes && product.productTypes[0]?.noodleProductType) || '__UNCATEGORIZED_TYPE__',
    id: product.id,
    item_brand: product?.creator?.name || product?.creator?.slug || '__UNNAMED__CREATOR__',
    item_category: (product.productTypes && product.productTypes[0]?.noodleProductType) || '__UNCATEGORIZED_TYPE__',
    item_id: product.id,
    item_name: product.title || '__UNTITLED_PRODUCT__',
    name: product.title || '__UNTITLED_PRODUCT__',
    price: price?.price || 0,
    quantity: 1,
  };
}
