import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/auth/token-for/{userId}']['GET'];
type Input = ThisApi['params'];

const getTokenForUser = async ({ userId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/auth/token-for/${userId}`);
  return response.data;
};

export default getTokenForUser;
