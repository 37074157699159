import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}/payment-plans']['GET'];
type Input = ThisApi['params'];

const getPaymentPlan = async ({ id, ...body }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/invoices/${id}/payment-plans`, body);
  return response.data;
};

export default getPaymentPlan;
