import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/sockets/channels']['POST'];
type Output = THIS_API['response'];

const joinSocketChannels = async ({ channels }: THIS_API['body']): Promise<Output> => {
  const response = await noodleApiClient.post('/sockets/channels', { channels });
  return response.data;
};

export default joinSocketChannels;
