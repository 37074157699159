import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/agencies']['POST'];

type Input = ThisApi['body'];
type Output = ThisApi['response'];

const createAgency = async ({ businessName }: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/agencies', { businessName });
  return response.data;
};

export default createAgency;
