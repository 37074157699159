import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/my/profile';
type THIS_API = ApiDefinition['/my/profile']['PUT'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const updateProfile = async (body: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default updateProfile;