import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const PATH_TEMPLATE = '/creators/{creatorSlug}/ui';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];

const getCreatorUI = async ({ creatorSlug }: THIS_API['params']): Promise<THIS_API['response']> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorSlug });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getCreatorUI;
