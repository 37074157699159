import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorSlug}/routable/connect';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const connectCreatorToRoutable = async ({ creatorSlug }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorSlug });
  const response = await noodleApiClient.post(path);
  return response.data;
};

export default connectCreatorToRoutable;
