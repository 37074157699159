import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/collections']['GET'];

type Props = ThisApi['params'];
type Output = ThisApi['response'];

const getCreatorCollections = async ({ creatorSlug }: Props): Promise<Output> => {
  const response = await noodleApiClient.get(`/products/collections/${creatorSlug}`);
  return response.data;
};

export default getCreatorCollections;
