import { createContext } from 'react';
import * as tsClient from '@tsClient';
import { GetData } from '@/hooks/useNoodleApi/typings';
import { AuthUser, Profile } from './constants';

export type AuthContextType = {
  isContextInitialized: boolean;
  user: AuthUser | null;
  setUser: (newUser: AuthUser | null) => void;
  profile: Profile | null;
  updateProfile: GetData<typeof tsClient.my.updateProfile>
};

const AuthContext = createContext<AuthContextType>({
  isContextInitialized: false,
  profile: null,
  setUser: () => undefined,
  updateProfile: () => {
    throw new Error('Not in Context');
  },
  user: null,
});

export default AuthContext;
