import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/workflows']['GET'];

const getCreatorWorkflows = async ({ creatorId, isActive, page = 1, perPage = 25, hasNoodleFee }: ThisApi['params'] & ThisApi['query']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/workflows`, {
    params: {
      hasNoodleFee,
      isActive,
      page,
      perPage,
    },
  });

  return response.data;
};

export default getCreatorWorkflows;
