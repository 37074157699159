import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/persons']['GET'];

type GetPersonResponse = ThisApi['response'];

const getPerson = async (): Promise<GetPersonResponse> => {
  const response = await noodleApiClient.get('/persons');
  return response.data;
};

export default getPerson;
