import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-requests']['GET'];
type Input = ThisApi['query'];

const getCreatorDocumentRequests = async ({ creatorId, search, page = 1, perPage = 100 }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.get('/document-requests', {
    params: {
      creatorId,
      page,
      perPage,
      search,
    },
  });
  return response.data;
};

export default getCreatorDocumentRequests;
