import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/tiers/{id}/is-tier-buy']['GET'];
const PATH_TEMPLATE = '/tiers/{id}/is-tier-buy';
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const isTierBuy = async (params: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { id: params.id });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default isTierBuy;