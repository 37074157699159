import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/messages/attachments/{id}/followup-history']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getAttachmentFollowupHistory = async ({ page, perPage, id }: Input): Promise<Output> => {
  const data = await noodleApiClient.get(`/messages/attachments/${id}/followup-history`, { params: { page, perPage } });
  return data.data;
};

export default getAttachmentFollowupHistory;
