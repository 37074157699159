import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/conversations/{conversationSid}/purchases/{purchaseId}/fulfillment';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = THIS_API['response'];

const updateFulfillment = async ({ conversationSid, purchaseId, ...body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { conversationSid, purchaseId });
  const data = await noodleApiClient.put(path, { ...body });
  return data.data;
};

export default updateFulfillment;
