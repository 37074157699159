import Axios from 'axios';
import noodleApiClient, { ApiDefinition } from '@noodleApi';
import { mixpanelTrack } from '@providers/Mixpanel';

type ThisApi = ApiDefinition['/auth/otp-requests']['PUT'];
type OUTPUT = ThisApi['response'];
type INPUT = ThisApi['body'];

const verifyOTP = async (payload: INPUT): Promise<OUTPUT> => {
  try {
    const response = await noodleApiClient.put('/auth/otp-requests', payload);
    return response.data;
  } catch (error) {
    if (Axios.isAxiosError(error) && error.response) {
      const errorData = error.response.data;
      if (errorData?.type === 'OTPVerificationError') {
        mixpanelTrack('Failed to verify OTP request', errorData);
      }
    }
    throw error;
  }
};

export default verifyOTP;
