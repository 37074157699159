import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/products/{productId}/free-person-products';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = THIS_API['response'];

const buyFreeProduct = async ({ productId, ...body }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { productId });
  const response = await noodleApiClient.post(path, body);
  return response.data;
};

export default buyFreeProduct;
