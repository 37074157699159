import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/simple-workflows']['GET'];

const getCreatorWorkflowsSimple = async ({ creatorId, isActive, hasNoodleFee, page = 1, perPage = 25 }: ThisApi['params'] & ThisApi['query']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/simple-workflows`, {
    params: {
      hasNoodleFee,
      isActive,
      page,
      perPage,
    },
  });

  return response.data;
};

export default getCreatorWorkflowsSimple;
