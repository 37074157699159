import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}/field-templates/{fieldTemplateId}']['DELETE'];
type Input = ThisApi['params'];

const deleteFieldTemplate = async ({ creatorId, fieldTemplateId }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaire-templates/{creatorId}/field-templates/{fieldTemplateId}', {
    creatorId,
    fieldTemplateId,
  });
  const data = await formsBackendClient.delete(path);
  return data.data;
};

export default deleteFieldTemplate;
