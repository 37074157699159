import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisRequest = ApiDefinition['/ai/text-to-speech']['POST'];
type Input = ThisRequest['body'];
type Output = ThisRequest['response'];

const generateAudioFromText = async (payload: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/ai/text-to-speech', payload);
  return response.data;
};

export default generateAudioFromText;