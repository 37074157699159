import { useContext, useEffect, useState } from 'react';
import FeatureFlagsContext from './Context';
import { FEATURE_FLAG_KEY } from './checkFeatureFlag';

const useFeatureFlag = (featureFlagKey: FEATURE_FLAG_KEY, defaultValue = false): boolean => {
  const { checkFeatureFlag } = useContext(FeatureFlagsContext);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(checkFeatureFlag(featureFlagKey));
  }, [featureFlagKey, checkFeatureFlag]);

  return value;
};

export default useFeatureFlag;
