import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/default-products']['POST'];
type Input = ThisApi['body'];

type CreateProductResponse = ThisApi['response'];

const createDefaultProducts = async ({ creatorId }: Input): Promise<CreateProductResponse> => {
  const response = await noodleApiClient.post('/creators/default-products', { creatorId });
  return response.data;
};

export default createDefaultProducts;
