const ignoreEvent = ({ error }) => {
  if (!error) {
    return false;
  }

  // From the lastpass safari plugin...
  if (error === 'Not implemented on this platform') {
    return true;
  }

  if (error instanceof Error) {
    // Ignore all NotFound, Forbidden, and Unauthorized errors
    if (error.statusCode === 404 || error.statusCode === 403 || error.statusCode === 401) {
      return true;
    }

    // launch darkly errors
    if (
      error.message && (
        // These all seem to be from launch darkly POST /events/bulk
        error.message === 'A network error occurred'
        // launch darkly trying to use Synchronous XHR on page dismissal...
        || /Failed to load 'https:\/\/events\.launchdarkly.com\/events\/bulk.*Synchronous XHR in page dismissal/.test(error.message)
      )
    ) {
      return true;
    }

    if (
      (error.type === 'LoginFailedError' && error.data?.reason === 'Wrong password')
      || (error.type === 'LoginNoSuchUserError')
      || (error.type === 'InvalidDiscountCodeError')
      || (error.type === 'ExistingUserError')
      || (error.type === 'BadRequestError' && error.message === 'This reset password request has already been used')
      || (error.type === 'BadRequestError' && error?.errors?.[0] === 'This reset password request has already been used')
    ) {
      return true;
    }
  }

  return false;
};

export default ignoreEvent;
