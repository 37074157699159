import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/organizations/{organizationId}/members']['POST'];

type Input = ThisApi['params'] & ThisApi['body'];

const createOrganizationMember = async ({ organizationId, ...body }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.post(`/organizations/${organizationId}/members`, body);
  return data.data;
};

export default createOrganizationMember;
