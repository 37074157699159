import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/carts/purchase']['POST'];
type Input = ThisApi['body'];
type Output = ThisApi['response'];

const purchaseCart = async ({ creatorSlug, discountCode, personId, prices }: Input): Promise<Output> => {
  const data = await noodleApiClient.post('/carts/purchase', { creatorSlug, discountCode, personId, prices });
  return data.data;
};

export default purchaseCart;
