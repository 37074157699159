import React from 'react';
import s from './IsOnBehalfOfBar.module.scss';

type Props = {
  user: {
    id: string;
    isOnBehalfOf?: boolean;
  } | null;
  profile: {
    id: string;
    email: string | null;
    name: string | null;
  } | null;
};

const IsOnBehalfOfBar: React.FC<Props> = ({ profile, user }) => {
  if (!user || !user.isOnBehalfOf) {
    return null;
  }
  const name = profile
    ? (profile.name || profile.email || profile.id)
    : user.id;

  return (
    <div className={s['is-on-behalf-of-alarm']}>
      <span>Acting on behalf of:</span>&nbsp;{name}
    </div>
  );
};

export default IsOnBehalfOfBar;