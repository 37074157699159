import { isBrowser } from './helper';

export default function scrollParentToTop(): void {
  if (isBrowser()) {
    window.parent.postMessage(
      {
        message: 'scroll-to-top',
        postedTo: window.name,
        value: {},
      },
      '*',
    );
  }
}
