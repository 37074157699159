import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/featured-product']['GET'];

type Props = ThisApi['params'];

const getFeaturedProducts = async ({ creatorSlug }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorSlug}/featured-product`);
  return response.data;
};

export default getFeaturedProducts;
