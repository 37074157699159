import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/tiers/{id}']['PUT'];

const updateTier = async ({ id, updates }: ThisApi['params'] & ThisApi['body']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/tiers/${id}`, {
    ...updates,
  });
  return response.data;
};

export default updateTier;
