import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const PATH_TEMPLATE = '/my/bookmarks';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];

const getBookmarks = async (): Promise<THIS_API['response']> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getBookmarks;
