import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}']['POST'];
type Input = ThisApi['params'];
type Body = ThisApi['body'];

const createQuestionnaireTemplate = async ({ creatorId }: Input, body: Body): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaire-templates/{creatorId}', { creatorId });
  const data = await formsBackendClient.post(path, body);
  return data.data;
};

export default createQuestionnaireTemplate;
