import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/form-requests/{formRequestId}/permissions']['GET'];
type Input = ThisApi['params'];

const getFormRequestPermissions = async ({ formRequestId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/form-requests/${formRequestId}/permissions`);
  return response.data;
};

export default getFormRequestPermissions;