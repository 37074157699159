import noodleApiClient, { ApiDefinition } from '@noodleApi';

type Input = ApiDefinition['/content-library/{creatorSlug}/hero-links']['GET']['params'];
type ThisApi = ApiDefinition['/content-library/{creatorSlug}/hero-links']['GET'];

const getCreatorHeroLinks = async ({ creatorSlug }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/content-library/${creatorSlug}/hero-links`);
  return response.data.data;
};

export default getCreatorHeroLinks;
