import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from './utilities/buildPath';

const pathTemplate = '/products/{productId}/subscriptions';
type ThisApi = ApiDefinition[typeof pathTemplate]['GET'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const getSubscriptionFromProductId = async ({ productId }: Input): Promise<Output> => {
  const path = buildPath(pathTemplate, { productId });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getSubscriptionFromProductId;
