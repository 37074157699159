import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}']['GET'];

type Props = ThisApi['params'];

const getWorkflowById = async ({ workflowId }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/workflows/${workflowId}`);
  return response.data;
};

export default getWorkflowById;
