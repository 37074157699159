import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/bookings/{id}/permissions']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateBookingPermissions = async ({ id, userIds }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/bookings/${id}/permissions`, { userIds });
  return response.data;
};

export default updateBookingPermissions;
