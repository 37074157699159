import noodleApiClient from '@noodleApi';

type CreateHandbookStepProps = {
  productId: string;
  actions: unknown;
};

const createHandbookStep = async ({ productId, actions }: CreateHandbookStepProps): Promise<void> => {
  const data = await noodleApiClient.put(`/products/${productId}/steps`, actions);
  return data.data;
};

export default createHandbookStep;
