import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}']['GET'];

type Props = ThisApi['params'];

const getInvoice = async ({ id }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/invoices/${id}`);
  return response.data;
};

export default getInvoice;
