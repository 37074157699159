import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const PathTemplate = '/subscriptions/{subscriptionId}/retry';

type ThisApi = ApiDefinition['/subscriptions/{subscriptionId}/retry']['POST'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const retrySubscriptionPayment = async ({ subscriptionId }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>(PathTemplate, { subscriptionId });
  const data = await noodleApiClient.post(path);
  return data.data;
};

export default retrySubscriptionPayment;
