import { logError } from "@providers/ErrorTracking";
import { nanoid } from "nanoid";
import isBrowser from './isBrowser';
import isInIframe from './isInIframe';

type Data = Record<string, unknown>;

const DEFAULT_REQUEST_RESPONSE_TIMEOUT = 1000;

// @todo - figure out how to type this correctly without a value but to have it not optional if V !== undefined
type MessageToSend<V = unknown> = {
  eventId?: string;
  from?: string;
  message: string;
  referenceId?: string | null;
  value?: V;
};

// The message that is actually posted
type MessageToPost<V = unknown> = MessageToSend<V> & {
  eventId: string;
  from: string;
  postedTo: string;
};

export type IframeEvent<V = unknown> = {
  data?: MessageToPost<V>
};

export enum IframeMessageTypes {
  CONVERSATION_INITIALIZED = 'conversation-initialized'
}

export type ConversationInitializedData = {
  conversationSid: string;
  initialMessage: string;
  isEmptyConversation: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDataObject = (value: any): value is Data => (typeof value === 'object');

type PostMessageToProps<V> = {
  to: Window | null,
  name: string,
  body: MessageToSend<V>,
  host: string
};

const postMessageTo = <V>({ to, name, body, host }: PostMessageToProps<V>): string | null => {
  if (isBrowser() && to) {
    try {
      const dataToPost: MessageToPost<unknown> & { postedTo: string } = {
        eventId: nanoid(),
        from: name,
        ...body,
        postedTo: name,
      };
      // @deprecate, widget.js and popover-widget.js used to need event.data.value.id to figure out which iframe the message came from
      // This moved to event.data.postedTo, but browsers can have the widgets cached, so need to keep sending them.
      // This can be removed when browser caches are invalidated (July 1, 2022 + whatever the default cache TTL was)
      if (isDataObject(dataToPost.value)) {
        dataToPost.value = {
          ...dataToPost.value,
          id: name,
        };
      } else if (!dataToPost.value) {
        dataToPost.value = {
          id: name,
        };
      }

      to.postMessage(dataToPost, host);
      return dataToPost.eventId;
    } catch (error) {
      console.warn('failed to postMessageTo', to, name, JSON.stringify(body)); // eslint-disable-line no-console
      logError(error);
      return null;
    }
  }
  return null;
};

const isFromThisListener = (expectedIframeName: string, event: IframeEvent): boolean => {
  if (typeof event.data !== 'object') {
    return false;
  }
  // @deprecated - cached widgets don't send postedTo, only value.id. keep this for a while.
  const postToIframeId = event?.data?.postedTo || (event?.data?.value as Data)?.id || null;
  return Boolean(postToIframeId) && postToIframeId === expectedIframeName;
};

type SendMessageUpProps<V> = MessageToSend<V> & {
  host?: string;
};

export const sendMessageUp = <V>({ host = '*', ...body }: SendMessageUpProps<V>): string | null => {
  if (!isInIframe()) {
    return null;
  }

  return postMessageTo<V>({
    body,
    host,
    name: window.name,
    to: window.parent,
  });
};

type SendMessageDownProps<V> = MessageToSend<V> & {
  iframe: HTMLIFrameElement,
  host?: string;
};

const sendMessageDown = <V>({ iframe, host = '*', ...body }: SendMessageDownProps<V>): string | null => postMessageTo<V>({
  body,
  host,
  name: iframe.name,
  to: iframe.contentWindow,
});

// Not specifiying a whitelist is echo all, use [] to echo none.
const shouldEchoEvent = (whitelist: string[] | undefined, event: IframeEvent): boolean => {
  if (whitelist === undefined) {
    return true;
  }
  if (typeof event.data !== 'object') {
    return false;
  }
  const { message } = event.data;
  return whitelist.includes(message);
};

type EchoMessageUp = (args: {
  event: IframeEvent;
  from: string;
  whitelist?: string[];
  host?: string;
}) => void;

export const echoMessageUp: EchoMessageUp = ({ event, from, host, whitelist }) => {
  if (isFromThisListener(from, event) && shouldEchoEvent(whitelist, event) && event.data) {
    sendMessageUp({
      eventId: event.data.eventId,
      from: event.data.from,
      host,
      message: event.data.message,
      referenceId: event.data.referenceId,
      value: event.data.value,
    });
  }
};

type EchoMessageDown = (args: {
  iframe: HTMLIFrameElement,
  event: IframeEvent<unknown>;
  whitelist?: string[];
  host?: string;
}) => void;

export const echoMessageDown: EchoMessageDown = ({ iframe, event, host = '*', whitelist }) => {
  if (isFromThisListener(window.name, event) && shouldEchoEvent(whitelist, event) && event.data) {
    sendMessageDown({
      eventId: event.data.eventId,
      from: event.data.from,
      host,
      iframe,
      message: event.data.message,
      referenceId: event.data.referenceId,
      value: event.data.value,
    });
  }
};

export const referencesThisEvent = (eventId: string, receivedEvent: MessageToSend<unknown>): boolean => {
  const referenceId = receivedEvent?.referenceId;
  return referenceId === eventId;
};

type RequestResponseProps<Q, S, R> = {
  request: Pick<MessageToSend<Q>, 'message' | 'value'>;
  response: {
    handler: (data: MessageToPost<S>) => R;
    // need to allow this since widgets may not return the referenceId.
    // This can be removed once browser caches for widget.js expire.
    ensureReferenceId?: (eventId: string, data: MessageToPost<S>) => MessageToPost<S>;
  },
  errorExtras?: Record<string, unknown>,
  timeout?: number
};

export const requestResponse = <Q, S, R = S>({
  request,
  response,
  errorExtras = {},
  timeout = DEFAULT_REQUEST_RESPONSE_TIMEOUT,
}: RequestResponseProps<Q, S, R>): Promise<R | null> => {
  if (!isInIframe()) {
    return Promise.resolve(null);
  }

  return new Promise<R>((resolve, reject) => {
    const eventId = sendMessageUp(request);
    let handler: (event: MessageEvent<MessageToPost<S>>) => void | undefined;
    if (eventId) {
      let isTimedout = false;
      const timeoutTimeout = setTimeout(() => {
        isTimedout = true;
        const error = new Error('requestResponse timed out');
        reject(error);
        window.removeEventListener('message', handler);
        logError(error, { message: request.message, reason: 'timeout', ...errorExtras });
        // @todo - should probably remove the listener in here too, but want to see if there are responses after timeou
      }, timeout);
      handler = (event: MessageEvent<MessageToPost<S>>): void => {
        const data = response.ensureReferenceId ? response.ensureReferenceId(eventId, event.data) : event.data;
        if (referencesThisEvent(eventId, data)) {
          window.removeEventListener('message', handler);
          clearTimeout(timeoutTimeout);
          if (!isTimedout) {
            try {
              resolve(response.handler(data));
            } catch(error) {
              logError(error, { message: request.message, ...errorExtras });
              reject(error);
            }
          } else {
            logError(new Error('Responded after timeout'), {
              message: request.message,
              reason: 'response after timeout',
              ...errorExtras,
            });
          }
        }
      };
      window.addEventListener('message', handler);
    } else {
      const error = new Error('Failed to sendMessageUp');
      logError(error, { reason: 'no-eventId' });
      reject(error);
    }
  });
};
