import { sendMessageUp } from "@helpers/iframeMessages";
import isBrowser from "@helpers/isBrowser";

const createCookie = (name: string, value: string, days?: number): string => {
  if (!isBrowser()) {
    return '';
  }

  // This means that the cookie is created here is different from the one  in widget and here. Should be the same, but that's later.
  sendMessageUp({
    message: 'set-client-cookie',
    value: { token: value },
  });

  const parts = [];
  parts.push(`${name}=${value}`);

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    parts.push(`expires=${date.toUTCString()}`);
  }

  parts.push(
    'path=/',
    'SameSite=None',
  );

  // make cookie available on all subdomains.
  // count on the name being different on different tiers so that qa.noodle.shop and noodle.shop don't get the same cookie.
  // this is to enable scheduling.noodle.shop to have the same cookie as noodle.shop.
  const { host } = window.location;
  const hostParts = host.replace(/:\d+$/, '').split('.').reverse();

  if (hostParts.length >= 2) {
    parts.push(
      'Secure',
      `domain=.${hostParts[1]}.${hostParts[0]}`,
    );
  } else {
    // Chrome wants Secure with localhost.
    // Safari does not... comment this out if on safari.
    // Just be careful to not commit it.
    parts.push('Secure');
  }

  const cookie = parts.join('; ');
  document.cookie = cookie;
  return cookie;
};

export default createCookie;
