import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports-b2c/{creditReportId}/permissions']['GET'];
type Input = ThisApi['params'];

const getCreditReportPermissions = async ({ creditReportId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/credit-reports-b2c/${creditReportId}/permissions`);
  return response.data;
};

export default getCreditReportPermissions;
