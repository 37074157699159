import ApiError from '@helpers/ApiError';
import { logError } from '@providers/ErrorTracking';
import selfApiClient from '@selfApi';

type Data = Record<string, unknown> & {
  message?: never
};

const loggerInfo = async (message: string, data: Data): Promise<void> => {
  const path = '/logger/info';
  try {
    await selfApiClient.post(path, { ...data, message });
  } catch(error) {
    logError(ApiError.create(error));
  }

};

export default loggerInfo;
