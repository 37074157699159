import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/messages/noodle-broadcasts']['POST'];
type Input = ThisApi['body'];

const postNoodleBroadcasts = async (body: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/messages/noodle-broadcasts`, body);
  return response.data;
};

export default postNoodleBroadcasts;
