import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/comments']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getWorkflowConversationMessages = async ({ page, perPage, userWorkflowId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/workflows/user-workflows/${userWorkflowId}/comments`, {
    params: { page, perPage },
  });
  return response.data;
};

export default getWorkflowConversationMessages;
