import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/product-comments']['GET'];

type Input = ThisApi['params'] & ThisApi['query'];
type Output = ThisApi['response'];

const getCreatorProductComments = async ({ creatorSlug, perPage, page }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/creators/${creatorSlug}/product-comments`, {
    params: {
      page,
      perPage,
    },
  });
  return response.data;
};

export default getCreatorProductComments;
