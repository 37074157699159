import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/progress']['GET'];
type Props = ThisApi['params'];

const getUserWorkflowProgress = async ({ userWorkflowId }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/workflows/user-workflows/${userWorkflowId}/progress`);
  return response.data;
};

export default getUserWorkflowProgress;