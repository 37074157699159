import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/workflows/{workflowSlug}/published-at']['POST'];

type Props = ThisApi['params'];

const publishWorkflow = async ({ creatorId, workflowSlug }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/creators/${creatorId}/workflows/${workflowSlug}/published-at`);
  return response.data;
};

export default publishWorkflow;
