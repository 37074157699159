import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/creators/{creatorId}/form-requests']['GET'];

type Input = ThisApi['params'] & ThisApi['query'];
type Output = ThisApi['response'];

const getCreatorFormRequests = async ({ creatorId, personId }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/creators/{creatorId}/form-requests', { creatorId });
  const data = await noodleApiClient.get(path, {
    params: { personId },
  });
  return data.data;
};

export default getCreatorFormRequests;