import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorId}/pending-tasks';
type THIS_API = ApiDefinition['/creators/{creatorId}/pending-tasks']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getPendingTasksCount = async ({
  creatorId,
}: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId });
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getPendingTasksCount;
