import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/my/profile';
type THIS_API = ApiDefinition['/my/profile']['GET'];
type Output = THIS_API['response'];

const getProfile = async (): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getProfile;