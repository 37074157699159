import React from 'react';

interface ErrorBoundaryProps {
  fallback: JSX.Element;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class NoodleAIErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    if (process.env.NODE_ENV === 'development') {
      console.error('NoodleAI icon error', error);
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default NoodleAIErrorBoundary;
