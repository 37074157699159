import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/workflows/templates/{templateSlug}']['GET'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const getWorkflowTemplateBySlug = async ({ templateSlug }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/workflows/templates/{templateSlug}', { templateSlug });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getWorkflowTemplateBySlug;
