import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/discount-codes']['PUT'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const checkDiscountCode = async ( { priceIds, discountCode }: Input): Promise<Output> => {
  const data = await noodleApiClient.put('/discount-codes', { discountCode, priceIds });
  return data.data;
};

export default checkDiscountCode;
