import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/conversations/{conversationSid}/empty-ai-message']['POST'];
type Input = ThisApi['params'];

const createEmptyAIMessage = async ({ conversationSid }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/conversations/${conversationSid}/empty-ai-message`);
  return response.data;
};

export default createEmptyAIMessage;
