import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/personProducts';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const editPersonProduct = async ({ ...body }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default editPersonProduct;
