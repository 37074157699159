import { useCallback, useReducer } from 'react';
import { nanoid } from 'nanoid';
import Bluebird from 'bluebird';
import { mixpanelTrack } from '@providers/Mixpanel';
import JobContext, { Job, JobStatus, JobType } from './Context';
import isDependency from './isDependency';

type State = Job[];

const initialState: State = [];

type ActionType = 'ADD_JOB' | 'REMOVE_JOB' | 'UPDATE_JOB';
type Action = { type: ActionType; job: Job | Partial<Job> };

type AddJobProps = Pick<Job, 'title' | 'dependsOn' | 'function' | 'type' | 'metadata'> & {
  correlationId?: string;
  onCancel?: () => void;
};

function JobReducer(state: State, action: Action): State {
  switch (action.type) {
  case 'ADD_JOB': {
    return [
      ...state.filter(({ id }) => id !== action.job.id),
      (action.job as Job),
    ];
  }
  case 'REMOVE_JOB': {
    return [
      ...state.filter((t: Job) => action.job.id !== t.id),
    ];
  }
  case 'UPDATE_JOB': {
    return [
      ...state.map((t: Job) => {
        if (action.job.id !== t.id) {
          return t;
        }
        return Object.assign(t, action.job);
      }),
    ];
  }
  default: {
    throw new Error('unhandled action type');
  }
  }
}

function JobProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const [state, dispatch] = useReducer(JobReducer, initialState);

  const updateJob = useCallback((job: Pick<Job, 'id'> & Partial<Job>) => {
    dispatch({ job, type: 'UPDATE_JOB' });
  }, []);

  const removeJob = useCallback((job: Job) => {
    job.onRemove?.();
    dispatch({ job, type: 'REMOVE_JOB' });
  }, []);

  const addJob = useCallback((partialJob: AddJobProps) => {
    const job: Job = {
      ...partialJob,
      cancel() {
        mixpanelTrack('Async Job Cancelled', {
          ...(job.metadata || {}),
          correlationId: job.correlationId,
          id: job.id,
          type: job.type,
        });
        this.status = JobStatus.CANCELED;
        this.promise.cancel();
        this.onCancel?.();
        updateJob(this);
      },
      correlationId: partialJob.correlationId || nanoid(),
      id: nanoid(),
      progress: 0,
      promise: new Bluebird.Promise(r => { r(); }),
      retry: () => { /**/ },
      setProgress: (progress: number) => {
        job.progress = progress;
        updateJob(job);
      },
      status: JobStatus.IN_PROGRESS,
    };
    // Only one media upload should happen at a time. Can't use dependencies here since new upload jobs should run even if the prior ones failed.
    if (job.type === JobType.MESSAGE_MEDIA_UPLOAD) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadFunction = async (data: any): Promise<unknown> => {
        await Promise.allSettled(state.filter(j => j.type === JobType.MESSAGE_MEDIA_UPLOAD).map(j => j.promise));
        return partialJob.function.bind({})(data);
      };
      job.function = uploadFunction;
    }
    const createJobPromise = (): Bluebird<never> => new Bluebird.Promise<never>((resolve, reject) => {
      const dependencies = job.dependsOn || [];
      Promise
        .all(dependencies.map(j => j.promise))
        .then(async dependentData => {
          job
            .function(dependentData)
            .then((data) => {
              job.status = JobStatus.COMPLETED;
              updateJob(job);
              resolve(data);
              if (!isDependency(job)) {
                setTimeout(() => [job, ...dependencies].forEach(removeJob), 30 * 1000);
              }
            })
            .catch(() => {
              mixpanelTrack('Async Dependency Job Failed', {
                ...(job.metadata || {}),
                correlationId: job.correlationId,
                id: job.id,
                type: job.type,
              });
              job.cancel();
              job.status = JobStatus.FAILED;
              updateJob(job);
              reject();
            });
        })
        .catch(() => {
          mixpanelTrack('Async Job Failed', {
            ...(job.metadata || {}),
            correlationId: job.correlationId,
            id: job.id,
            type: job.type,
          });
          job.cancel();
          job.status = JobStatus.FAILED;
          updateJob(job);
          reject();
        });
    });
    job.promise = createJobPromise();
    job.retry = function retry() {
      mixpanelTrack('Async Job Retried', {
        ...(job.metadata || {}),
        correlationId: job.correlationId,
        id: job.id,
        type: job.type,
      });
      this.status = JobStatus.IN_PROGRESS;
      this.progress = 0;
      this.promise = createJobPromise();
      updateJob(this);
    };
    dispatch({ job, type: 'ADD_JOB' });
    return job;
  }, [state, updateJob, removeJob]);

  return (
    <JobContext.Provider
      value={{
        addJob,
        jobs: state,
        removeJob,
        updateJob,
      }}
    >
      {children}
    </JobContext.Provider>
  );
}

export default JobProvider;
