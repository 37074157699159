import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/{provider}/integrations']['POST'];

type Input = ThisApi['params'] & ThisApi['body'];

const upsertCreatorIntegrationAccount = async ({ creatorId, provider, ...body }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/creators/${creatorId}/${provider}/integrations`, body);
  return response.data;
};

export default upsertCreatorIntegrationAccount;
