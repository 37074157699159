import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/confido-payments']['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const runConfidoPayment = async ({
  amount,
  creatorId,
  passProcessingFeesToCustomer,
  personId,
  paymentMethod,
  paymentToken,
  processingFee,
  priceIds,
  sessionDuration,
  sessionTime,
  onBehalfOfPersonId,
}: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/creators/{creatorId}/confido-payments', { creatorId });
  const data = await noodleApiClient.post(path, {
    amount,
    onBehalfOfPersonId,
    passProcessingFeesToCustomer,
    paymentMethod,
    paymentToken,
    personId,
    priceIds,
    processingFee,
    sessionDuration,
    sessionTime,
  });
  return data.data;
};

export default runConfidoPayment;
