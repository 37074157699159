import noodleMediaClient, { ApiDefinition } from '@mediaApi';

type THIS_API = ApiDefinition['/assets/{assetId}']['DELETE'];
type Input = THIS_API['params'];

const deleteUploadLogoAsset = async ({ assetId }: Input): Promise<void> => {
  await noodleMediaClient.delete(`/assets/${assetId}`);
};

export default deleteUploadLogoAsset;
