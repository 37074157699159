import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/files/{fileId}/documents']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateDocumentOrder = async ({ id, fileId, updates }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.put(`/document-request-users/${id}/files/${fileId}/documents`, { updates });
  return response.data;
};

export default updateDocumentOrder;
