import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/members/{personId}']['PUT'];

type Props = ThisApi['params'];

const updateMemberDetails = async ({ creatorSlug, personId, updates }: Props & { updates: ThisApi['body'] }): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/creators/${creatorSlug}/members/${personId}`, {
    ...updates,
  },
  );
  return response.data;
};

export default updateMemberDetails;