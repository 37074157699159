import React, { useContext, useEffect } from 'react';
import { usePrevious, useUser } from '@hooks';
import { RootContext } from '@context/RootContext';
import { useRouter } from 'next/router';
import shoppingCart from './index';

type Props = {
  enabled?: boolean;
};

const CartProvider: React.FC<Props> = () => {
  const [rootContextUser] = useUser();
  const userId = rootContextUser?.id;
  const user = userId ? rootContextUser : null;
  const prevUser = usePrevious(user);
  const router = useRouter();
  const didLogout = Boolean(!user && prevUser);

  const { setContextSection, updateContextSection } = useContext(RootContext);

  const creatorSlug = router.query.creatorSlug as string;
  const prevCreator = usePrevious(creatorSlug);

  useEffect(() => {
    shoppingCart.onCartUpdate(carts => {
      updateContextSection({
        data: {
          carts,
        },
        section: 'cart',
      });
    });
    shoppingCart.onDiscountCodeUpdate(code => {
      updateContextSection({
        data: {
          discountCodes: {
            [creatorSlug]: code,
          },
        },
        section: 'cart',
      });
    });
  }, [creatorSlug, updateContextSection, setContextSection]);

  useEffect(() => {
    if (didLogout) {
      shoppingCart.setUser(null);
    } else if(creatorSlug && (user !== prevUser || prevCreator !== creatorSlug)) {
      shoppingCart.initialize({ creatorSlug, user });
    }
  }, [didLogout, user, creatorSlug, prevUser, prevCreator]);

  return null;
};

export default CartProvider;
