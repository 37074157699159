import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisRequest = ApiDefinition['/ai/filing-district']['GET'];
type Input = ThisRequest['query'];
type Output = ThisRequest['response'];

const getFilingDistrict = async ({ zipCode }: Input): Promise<Output> => {
  const response = await noodleApiClient.get('/ai/filing-district', {
    params: { zipCode },
  });
  return response.data;
};

export default getFilingDistrict;
