import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/user-workflows/{userWorkflowId}']['PUT'];

const updateUserWorkflow = async ({ userWorkflowId, updates }: ThisApi['params'] & { updates: ThisApi['body'] }): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/workflows/user-workflows/${userWorkflowId}`, {
    ...updates,
  });
  return response.data;
};

export default updateUserWorkflow;