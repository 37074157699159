import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/products/{creatorSlug}/{productSlug}/messages';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const postNewMessage = async ({ creatorSlug, productSlug, ...body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorSlug, productSlug });
  const data = await noodleApiClient.post(path, {...body});
  return data.data;
};

export default postNewMessage;
