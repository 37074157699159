import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/organization']['GET'];

type Input = ThisApi['params'];

const getOrganization = async ({ creatorId }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.get(`/creators/${creatorId}/organization`);
  return data.data;
};

export default getOrganization;
