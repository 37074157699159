type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};

const SvgNoodle: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit', className }) => (
  <svg width={width} height={height} className={className} viewBox="0 0 64 64" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0C2.68629 0 0 2.68629 0 6V58C0 61.3137 2.68629 64 6 64H58C61.3137 64 64 61.3137 64 58V6C64 2.68629 61.3137 0 58 0H6ZM20.9219 12.1719C24.7374 12.1719 27.8319 15.2626 27.8319 19.0781C27.8319 22.8937 24.7374 25.9883 20.9219 25.9883C17.1063 25.9883 14.0117 22.8937 14.0117 19.0781C14.0117 15.2626 17.1063 12.1719 20.9219 12.1719ZM42.457 12.1719C46.2726 12.1719 49.3632 15.2626 49.3632 19.0781C49.3632 22.8937 46.2726 25.9883 42.457 25.9883C38.6414 25.9883 35.5469 22.8937 35.5469 19.0781C35.5469 15.2626 38.6414 12.1719 42.457 12.1719ZM9.76171 33.9258H54.2383C54.2383 46.2076 44.2819 56.1679 32 56.1679C19.7181 56.1679 9.76171 46.2077 9.76171 33.9258Z" />
  </svg>
);

export default SvgNoodle;
