import noodleMediaClient, { ApiDefinition } from '@mediaApi';
import { RequireExactlyOne } from '@typings/utility';

type ThisApi = ApiDefinition['/file']['GET'];
type Props = ThisApi['query'];

const getMediaData = async (params: RequireExactlyOne<Props>): Promise<ThisApi['response']> => {
  const response = await noodleMediaClient.get('/file', { params: { ...params } });
  return response.data?.media || response.data || null;
};

export default getMediaData;
