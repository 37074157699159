import buildPath from '@/tsClient/utilities/buildPath';
import integrationsClient, { ApiDefinition } from '@/tsClient/servers/integrationsService';

type THIS_API = ApiDefinition['/{provider}/creators/{creatorId}/temporary-mfa-code']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getTemporaryMFACode = async ({ creatorId, provider, twoFaKey }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/{provider}/creators/{creatorId}/temporary-mfa-code', { creatorId, provider });
  const response = await integrationsClient.get(path, {
    params: { twoFaKey },
  });
  return response.data;
};

export default getTemporaryMFACode;
