import documentApiClient, { ApiDefinition } from '@documentsApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/documents/document-file-validation/{fileId}']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];
type Output = ThisApi['response'];

const updateDocumentFileValidationById = async ({ fileId, statusOfValidationStatus }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/documents/document-file-validation/{fileId}', { fileId });
  const response = await documentApiClient.put(path, { statusOfValidationStatus });
  return response.data;
};

export default updateDocumentFileValidationById;