import noodleApiClient from '@noodleApi';

type linkSocialResponse = unknown;

type Props = { platform: string; creatorId: string; };

const linkSocial = async ({ platform, creatorId }: Props): Promise<linkSocialResponse> => {
  const response = await noodleApiClient.get(`/social/${platform}`, { params: { creatorId } });
  return response.data;
};

export default linkSocial;