import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/user-notes']['GET'];
type Props = ThisApi['query'];

const getUserNotes = async ({productId}: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get('/user-notes', {
    params: {
      productId,
    },
  },
  );
  return response.data;
};

export default getUserNotes;