import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/users/{userId}/document-request-users']['GET'];
type Input = ThisApi['query'];

const getUserDocumentRequestUsers = async ({ creatorId, userId, page = 1, perPage = 100 }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.get(`/users/${userId}/document-request-users`, {
    params: {
      creatorId,
      page,
      perPage,
      userId,
    },
  });
  return response.data;
};

export default getUserDocumentRequestUsers;