import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient from '@noodleApi';

const PATH_TEMPLATE = '/for-developers/mock-long-request';
type THIS_API = {
  query: {
    delay?: number; // in seconds
  };
  body: { randomData: string };
  params: void;
  response: {
    requestedDelay: number; // in milliseconds
    actualDelay: number; // in milliseconds
    randomData: string;
  }
};

type Input = THIS_API['query'] & THIS_API['body'] & { cid: string };
type Output = THIS_API['response'];

const mockLongRequest = async ({ cid, delay, ...body }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.post(path, body, {
    headers: {
      'x-correlationId': cid,
    },
    params: { delay },
  });
  return response.data;
};

export default mockLongRequest;
