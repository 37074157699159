import MimeTypes from "@typings/MimeTypes";
import { Asset } from "@typings/graphql-models";
import { NoodleProductTypes } from '@typings/api-models';

type Product = {
  productTypes: {
    noodleProductType?: NoodleProductTypes | null,
  }[];
};

export const isConsultationProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.Consultation),
);

export const isLiteProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.Lite),
);

export const isLiveSessionProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.OnlineSession),
);

export const isInPersonProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.InPersonSession),
);

export const isSessionProduct = (product: Product): boolean => isLiveSessionProduct(product) || isInPersonProduct(product);

export const isSubscriptionProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.Subscription),
);

export const isSaaSProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.NoodleSaaS),
);

export const isChatProduct = (product: Product): boolean => Boolean(
  isLiteProduct(product) || isLiveSessionProduct(product) || isInPersonProduct(product),
);

export const isHandbookProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.Handbook),
);

export const isChargeProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.Charge),
);

export const isProductGroupProduct = (product: Product): boolean => Boolean(
  product?.productTypes?.some(type => type?.noodleProductType === NoodleProductTypes.ProductGroup),
);

// @todo - showOnOneLink should be required on Product and here, not optional.
type MaybeFeaturedHandbook = Product & {
  showOnOneLink?: boolean | null;
};

export const isFeaturedHandbook = (product: MaybeFeaturedHandbook): boolean => isHandbookProduct(product)
  && Boolean(product.showOnOneLink);

type MaybeDigitalDownloadHandbook = Product & {
  handbookSteps?: Array<{
    photos: Array<Pick<Asset, 'mimeType'>>;
  }>;
};

const DOWNLOADABLE_MIME_TYPES: string[] = [MimeTypes.PDF, MimeTypes.ZIP];

// eslint-disable-next-line import/no-unused-modules
export const isDigitalDownloadHandbook = (product: MaybeDigitalDownloadHandbook): boolean => {
  if (!isHandbookProduct(product)) {
    return false;
  }
  const firstAssetMimeType = product?.handbookSteps?.[0]?.photos?.[0]?.mimeType;
  return Boolean(firstAssetMimeType && DOWNLOADABLE_MIME_TYPES.includes(firstAssetMimeType));
};
