import noodleApiClient, { ApiDefinition } from '@noodleApi';

type Input = ApiDefinition['/products/{creatorSlug}/{productSlug}/messages/{messageId}']['DELETE']['params'];
type Output = ApiDefinition['/products/{creatorSlug}/{productSlug}/messages/{messageId}']['DELETE']['response'];

const deleteMessage = async ({ productSlug, creatorSlug, messageId }: Input): Promise<Output> => {
  const data = await noodleApiClient.delete(`/products/${creatorSlug}/${productSlug}/messages/${messageId}`);
  return data.data;
};

export default deleteMessage;