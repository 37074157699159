import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/messages/noodle-broadcasts']['GET'];
type Input = ThisApi['query'];
type Output = ThisApi['response'];

const getNoodleBroadcasts = async ({ page, perPage} : Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/messages/noodle-broadcasts`, {
    params: {
      page,
      perPage,
    },
  });
  return response.data;
};

export default getNoodleBroadcasts;