import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/my/creators/{creatorId}/confido-payment-methods']['POST'];
type Input = THIS_API['params'] & THIS_API['body'] & THIS_API['query'];
type Output = THIS_API['response'];

const saveConfidoPaymentMethod = async ({ creatorId, forUserId, ...body }: Input): Promise<Output> => {
  const data = await noodleApiClient.post(`/my/creators/${creatorId}/confido-payment-methods`, body, {
    params: { forUserId },
  });
  return data.data;
};

export default saveConfidoPaymentMethod;
