import { useContext } from "react";
import MessagesContext, { DEFAULT_HIDE_FOR_DAYS, MessagesContextType } from "./MessagesContext";

type ReturnValue = Pick<MessagesContextType, | 'dismissMessage' | 'isMessageDismissed' | 'isInitialized'>;

const useDismissedMessages = (): ReturnValue => {
  const { dismissMessage, isMessageDismissed, isInitialized } = useContext(MessagesContext);
  return { dismissMessage, isInitialized, isMessageDismissed };
};

export default useDismissedMessages;
export { DEFAULT_HIDE_FOR_DAYS };
