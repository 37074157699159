import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/conversations/last-view';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const updateConversationLastViewed = async ({ ...body }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.post(path, body);
  return response.data;
};

export default updateConversationLastViewed;
