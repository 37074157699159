import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports-b2c/{creditReportId}/response']['POST'];

type Input = ThisApi['params'];
type Output = ThisApi['response'];

const pullCreditReportData = async ({ creditReportId }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/credit-reports-b2c/${creditReportId}/response`);
  return response.data;
};

export default pullCreditReportData;
