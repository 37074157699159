import noodleApiClient from '@noodleApi';

type Props = {
  email: string;
  requestedSlug: string;
  source: string;
  campaignId: string;
  utmContent: string;
};

const createWaitlist = async (data: Props): Promise<void> => {
  await noodleApiClient.post('/waitlist', data);
};

export default createWaitlist;