import { GOOGLE_OPTIMIZE_ID } from '@configuration/client';

// This needs to be included in _document.tsx, not _app.tsx
const GoogleOptimize: React.FC = () => (
  <>
    {GOOGLE_OPTIMIZE_ID
      ? <script async src={`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE_ID}`} />
      : null
    }
  </>
);

export default GoogleOptimize;