import isBrowser from './isBrowser';
import isInIframe from './isInIframe';
import {
  isLiteProduct,
  isInPersonProduct,
  isLiveSessionProduct,
  isChatProduct,
  isSubscriptionProduct,
  isSaaSProduct,
  isConsultationProduct,
  isChargeProduct,
} from './isProductType';
import isInPopoverWidget from './isInPopoverWidget';
import isInPopoverWidgetLauncher from './isInPopoverWidgetLauncher';

export function getId(strangeId) {
  const [, justId] = strangeId.split(':');
  return justId || strangeId;
}

export function getDateFormat(date) {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
}

export function getDateTimeFormat(date) {
  const options = { day: 'numeric', hour: 'numeric', minute: 'numeric', month: 'short', year: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
}

export {
  isChargeProduct,
  isConsultationProduct,
  isInIframe,
  isChatProduct,
  isLiteProduct,
  isInPersonProduct,
  isLiveSessionProduct,
  isSaaSProduct,
  isSubscriptionProduct,
  isInPopoverWidget,
  isInPopoverWidgetLauncher,
  isBrowser,
};
