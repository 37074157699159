import { useCallback, useEffect, useMemo, useState } from "react";
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { useIsUserInitialized, useUser } from "@providers/Auth";
import storage from "@helpers/storage";
import AgencyContext from "./AgencyContext";
import { Agency } from './constants';

const storageKeyAgencyId = 'current_agency_id';
const PAGE_SIZE = 200;
const PAGE = 1;

const AgencyProvider: React.FC = ({ children }) => {
  const [user] = useUser();
  const isUserInitialized = useIsUserInitialized();
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [currentAgency, setCurrentAgency] = useState<Agency | null>(null);
  const [currentAgencyId, setCurrentAgencyId] = useState<string | null>(null);
  const [currentAgencyOwnerId, setCurrentAgencyOwnerId] = useState<string | null>(null);

  const [hasFetchedAgencies, setHasFetchedAgencies] = useState(false);
  const { data: fetchedAgencies, getData: getAgenciesForUser } = useNoodleApi(tsClient.getMyAgencies);

  const clearAgencyData = useCallback((): void => {
    setCurrentAgency(null);
    setCurrentAgencyId(null);
    setCurrentAgencyOwnerId(null);
    setAgencies([]);

    storage.removeItem(storageKeyAgencyId);
  }, []);

  const wrapSetCurrentAgency = useCallback((newAgency: Agency | null) => {
    if (!newAgency) {
      clearAgencyData();
    } else {
      setCurrentAgency(newAgency);
      storage.setItem(storageKeyAgencyId, newAgency.id);

      setCurrentAgencyId(newAgency.id);
      setCurrentAgencyOwnerId(newAgency.ownerId);
    }
  }, [clearAgencyData, setCurrentAgency]);

  useEffect(() => {
    if (user && user?.id && !user.isAnonymous) {
      getAgenciesForUser({
        page: PAGE,
        perPage: PAGE_SIZE,
      });
    }
  }, [user, getAgenciesForUser]);

  useEffect(() => {
    if (isUserInitialized && !user) {
      clearAgencyData();
    }
  }, [clearAgencyData, isUserInitialized, user]);

  const setAgencyData = async (agencyArr: Agency[]): Promise<void> => {
    setAgencies(agencyArr);
    const storedAgencyId = await storage.getItem(storageKeyAgencyId);
    const storedAgency = agencyArr.find((agency) => agency.id === storedAgencyId);
    if (!storedAgency) {
      storage.setItem(storageKeyAgencyId, agencyArr[0].id);
    }

    const agencyToSet = storedAgency || agencyArr[0];
    const agencyOwnerIdToSet = storedAgency?.ownerId || agencyArr[0].ownerId;

    setCurrentAgency(agencyToSet);
    setCurrentAgencyId(agencyToSet.id);
    setCurrentAgencyOwnerId(agencyOwnerIdToSet);
    setHasFetchedAgencies(true);
  };

  useEffect(() => {
    if (fetchedAgencies) {
      if ((fetchedAgencies?.items.length || 0) > 0) {
        setAgencyData(fetchedAgencies.items);
      } else {
        setHasFetchedAgencies(true);
      }
    }
  }, [fetchedAgencies]);

  const value = useMemo(() => ({
    agencies,
    currentAgency,
    currentAgencyId,
    currentAgencyOwnerId,
    hasFetchedAgencies,
    setCurrentAgency: wrapSetCurrentAgency,
  }), [
    agencies,
    hasFetchedAgencies,
    wrapSetCurrentAgency,
    currentAgencyOwnerId,
    currentAgencyId,
    currentAgency,
  ]);

  return (
    <AgencyContext.Provider value={value}>
      {children}
    </AgencyContext.Provider>
  );
};

export default AgencyProvider;
