import noodleMediaClient, { ApiDefinition } from '@mediaApi';

type THIS_API = ApiDefinition['/assets']['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const uploadFileAsset = async (body: Input ): Promise<Output> => {
  const response = await noodleMediaClient.post('/assets', body);
  return response.data;
};

export default uploadFileAsset;
