// must be the same as on popover-widget.js
const PRELOAD_POPOVER_WIDGET_LAUNCHER_NAME = 'noodle-popover-launcher';
const DELAY_LOAD_POPOVER_WIDGET_LAUNCHER_NAME = 'noodle-popover-v2-launcher';
const POPOVER_WIDGET_LAUNCHER_NAMES = [
  PRELOAD_POPOVER_WIDGET_LAUNCHER_NAME,
  DELAY_LOAD_POPOVER_WIDGET_LAUNCHER_NAME,
];

function isInPopoverWidgetLauncher(): boolean {
  try {
    return window && window.self !== window.top && POPOVER_WIDGET_LAUNCHER_NAMES.includes(window.name);
  } catch (error) {
    return false;
  }
}

export function isInDelayedLoadPopoverWidgetLauncher(): boolean {
  try {
    return window && window.self !== window.top && (window.name === DELAY_LOAD_POPOVER_WIDGET_LAUNCHER_NAME);
  } catch (error) {
    return false;
  }
}

export default isInPopoverWidgetLauncher;
