import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/templates']['POST'];
type Input = ThisApi['body'];

const createInvoiceTemplate = async (body: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post('/invoices/templates', body);
  return response.data;
};

export default createInvoiceTemplate;
