import { logError } from "@providers/ErrorTracking";
import getSingleItem from './getSingleItem';
import { QueryStringItem } from './typings';

export type Options = {
  type: 'boolean';
  defaultValue?: boolean;
};

function handleBoolean(item: QueryStringItem): boolean | undefined;
function handleBoolean(item: QueryStringItem, opts: Options & { defaultValue?: never }): boolean | undefined;
function handleBoolean(item: QueryStringItem, opts: Options & { defaultValue: string }): boolean;
function handleBoolean(item: QueryStringItem, opts?: Options): boolean | undefined {
  const value = getSingleItem(item, { nullishStringIsNullish: true });
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }

  if (value !== undefined) {
    logError(new Error('Problem trying to deserialize boolean'), { item });
    return false;
  }

  return opts?.defaultValue ?? undefined;
}

export default handleBoolean;
