import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/payment-plans/projected-revenue']['GET'];

type GetRevenueResponse = ThisApi['response'];

const getProjectedRevenue = async ({ creatorId, startDate, endDate }: ThisApi['query']): Promise<GetRevenueResponse> => {
  const response = await noodleApiClient.get(`/payment-plans/projected-revenue`, {
    params: {
      creatorId,
      endDate,
      startDate,
    },
  });
  return response.data;
};

export default getProjectedRevenue;
