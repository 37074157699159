import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/tiers/{id}']['GET'];

const getTierById = async ({ id }: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/tiers/${id}`);
  return response.data;
};

export default getTierById;
