import BackButton from '@components/BackButton';

import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import Link from 'components/CustomLink';

import s from './ErrorsPage.module.scss';

interface ErrorPageProps {
  statusCode?: number,
  message?: string,
  data?: string | Record<string, unknown> | string[],
}

const ErrorsPage: React.FC<ErrorPageProps> = ({ statusCode = 500, message = 'Something went wrong', data = '' }) => {
  const environment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
  const isDevelopment = environment === 'development';

  return (
    <main className={s.unauthorized__wrapper}>
      <BackButton />
      <title>{statusCode} - { message }</title>
      <h1 className='heading-lg'>{statusCode} - {message}</h1>
      {data && isDevelopment
      && <pre className={s.unauthorized__code}>
        {typeof data === 'string' ? data : JSON.stringify(data, null, 2)}
      </pre>
      }
      Go <Link to={getUrl(IDENTIFIERS.HOME)}>Home</Link>
    </main>
  );
};

export default ErrorsPage;
