import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/broadcasts']['GET'];
type Input = ThisApi['params'] & ThisApi['query'];
type Output = ThisApi['response'];

const getBroadcasts = async ({ creatorId, hasFailed, page, perPage}: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/broadcasts`, {
    params: {
      ...hasFailed ? { hasFailed: hasFailed?.toString() } : {},
      page,
      perPage,
    },
  });
  return response.data;
};

export default getBroadcasts;
