import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisRequest = ApiDefinition['/user-notes']['POST'];
type Input = ThisRequest['body'];
type Output = ThisRequest['response'];

const addUserNotes = async (payload: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/user-notes', payload);
  return response.data;
};

export default addUserNotes;