const getFingerprint = ({ error, event }) /* : string[] | null */ => {
  const message = error?.message || error || '';
  if (
    message === 'A network error occurred'
    || message === 'Request aborted'
    || message === 'Network Error'
    || message === 'Failed to fetch'
    || message === 'cancelado'
    || message === 'The fetching process for the media resource was aborted by the user agent at the user\'s request.'
    || message === 'TypeError: Load failed'
  ) {
    return ['network-problem'];
  }

  if (
    message === /Loading CSS chunk \d+ failed/.test(message)
    || /Loading chunk \d+ failed/.test(message)
  ) {
    return ['error-loading-chunk'];
  }

  const handledBy = event?.extra?.handledBy;

  if (handledBy === 'useNoodleApi' || handledBy === 'handlePageRequest' || handledBy === 'requestWithCatch') {
    const requestStr = [event?.extra?.method, event?.extra?.baseUrl, event?.extra.url].filter(s => Boolean(s)).join(' ');
    const errorType = error?.type || 'UnknownError';
    return [requestStr, errorType, '{{ default }}'];
  }

  if (event?.tags?.flow) {
    return [`flow:${event.tags.flow}`, '{{ default }}'];
  }

  return null;
};

export default getFingerprint;
