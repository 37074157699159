import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}/{questionnaireTemplateSlug}/{statusOrVersion}']['GET'];
type Input = ThisApi['params'] & ThisApi['query'];

const getQuestionnaireTemplateBySlug = async ({ creatorId, questionnaireTemplateSlug, statusOrVersion, usesCasebuilderQuestionnaire }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>(
    '/questionnaire-templates/{creatorId}/{questionnaireTemplateSlug}/{statusOrVersion}',
    { creatorId, questionnaireTemplateSlug, statusOrVersion },
  );
  const data = await formsBackendClient.get(path, { params: { usesCasebuilderQuestionnaire } });
  return data.data;
};

export default getQuestionnaireTemplateBySlug;
