import { useEffect } from 'react';
import Buttons from '@components/Buttons';
import classNames from 'classnames';
import { ToastMessage, ToastTypeVariants, useToastContext, isTitleDescriptionMessage } from 'context/ToastContext';
import XCircle from '@components/Icons/XCircle';
import s from './Toasts.module.scss';

type ToastComponentProps = { type?: ToastTypeVariants; message: ToastMessage; id?: string };

export default function Toast({ message, id }: ToastComponentProps): JSX.Element {
  const { removeToast } = useToastContext();
  const messageAsToastMessage = message as ToastMessage;

  useEffect(() => {
    const to = setTimeout(() => {
      removeToast({ message });
    }, 3000);
    return () => clearTimeout(to);
  }, [removeToast, message]);

  return (
    <div className={s[`message-container`]}>
      <Buttons
        className={s[`message-close-btn`]}
        onClick={() => {
          removeToast({ id, message: isTitleDescriptionMessage(message) ? message.description : message });
        }}
        icon={<XCircle weight="fill" size={24} color="white" />}
      />
      {isTitleDescriptionMessage(messageAsToastMessage)
        ? (
          <div className={s.message}>
            <span className={s[`message-title`]}>{messageAsToastMessage.title}</span>
            <p className={s[`message-description`]}>{messageAsToastMessage.description}</p>
          </div>
        )
        : (
          <p className={classNames(s.message, s['message-description'])}>{message}</p>
        )}
    </div>
  );
}
