import { useCallback, useContext } from 'react';
import jwtDecode from 'jwt-decode';

import { logError } from '@providers/ErrorTracking';
import { AuthUser, ExposedUser } from './constants';
import AuthContext from './AuthContext';
import createJWTCookie from './createJWTCookie';
import clearJWTCookie from './clearJWTCookie';

const NO_USER = null;

const validUser = (contextUser: AuthUser | null): ExposedUser => {
  if (!contextUser || (contextUser.exp && (Date.now()/1000 > contextUser.exp))) {
    return NO_USER;
  }
  return contextUser;
};

type SetUser = (newUser: AuthUser | null) => void;
type SetUserByToken = (token: string | null) => AuthUser | null;

const useUser = (): [ExposedUser, SetUser, SetUserByToken] => {
  const { user, setUser: setUserInContext } = useContext(AuthContext);

  const setUser: SetUser = useCallback((newUser) => {
    if (newUser) {
      setUserInContext(newUser);
    } else {
      setUserInContext(null);
    }
  }, [setUserInContext]);

  const setUserByToken: SetUserByToken = useCallback((token) => {
    clearJWTCookie();
    if (token) {
      try {
        const decodedUser = jwtDecode(token) as Omit<AuthUser, 'token'>;
        const newUser = decodedUser ? { ...decodedUser, token } : null;
        createJWTCookie(token);
        setUserInContext(newUser);
        return newUser;
      } catch (error) {
        logError(error);
      }
    }
    setUserInContext(null);
    return null;
  }, [setUserInContext]);

  return [validUser(user), setUser, setUserByToken];
};

export default useUser;
