// This should only be needed once, and hopefully DNS TXT records actually verify...
const NOODLE_DOT_CHAT_CODE = '2sapaku33twdl1pump7haz3an6wljo';
const NOODLE_DOT_SHOP_CODE = '8ykftg121an1zzar9owr34un0g5ohz';

const VERIFY_DOMAIN = 'noodle.chat';
const TO_VERIFY = VERIFY_DOMAIN === 'noodle.chat' ? NOODLE_DOT_CHAT_CODE : NOODLE_DOT_SHOP_CODE;

// This needs to be included in _document.tsx, not _app.tsx
const FacebookVerify: React.FC = () => (
  <>
    {TO_VERIFY
      ? <meta name='facebook-domain-verification' content={TO_VERIFY} />
      : null
    }
  </>
);

export default FacebookVerify;
