import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/sorted-handbooks']['GET'];
type Props = ThisApi['params'];

const getSortedCreatorHandbooks = async ({ creatorSlug }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorSlug}/sorted-handbooks`);
  return response.data;
};

export default getSortedCreatorHandbooks;
