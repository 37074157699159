import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/invoices/{id}']['PUT'];

type Input = ThisApi['params'] & ThisApi['body'];
type Output = ThisApi['response'];

const updateInvoice = async ({ id, ...body }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/invoices/{id}', { id });
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default updateInvoice;