import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorId}/{personId}/payment-methods';
type THIS_API = ApiDefinition['/creators/{creatorId}/{personId}/payment-methods']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getPaymentMethodsForPerson = async ({ creatorId, personId }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId, personId });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getPaymentMethodsForPerson;
