import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorId}/sent-sms-messages';
type THIS_API = ApiDefinition['/creators/{creatorId}/sent-sms-messages']['GET'];
type Input = THIS_API['query'] & THIS_API['params'];

type Output = THIS_API['response'];

const getCreatorSentSmsMessages = async ({ creatorId, page, perPage }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId });
  const response = await noodleApiClient.get(path, {
    params: {
      page,
      perPage,
    },
  });

  return response.data;
};

export default getCreatorSentSmsMessages;