import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisRequest = ApiDefinition['/ai/document-analysis']['POST'];
type Input = ThisRequest['body'];
type Output = ThisRequest['response'];

const generateDocumentAnalysis = async (payload: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/ai/document-analysis', payload);

  return response.data;
};

export default generateDocumentAnalysis;