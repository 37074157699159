import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/files/{fileId}']['DELETE'];
type Input = ThisApi['params'];

const deleteDocumentRequestUserFile = async ({ id, fileId }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.delete(`/document-request-users/${id}/files/${fileId}`);
  return response.data;
};

export default deleteDocumentRequestUserFile;
