import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}']['PUT'];

const updateWorkflow = async ({ workflowId, updates }: ThisApi['params'] & { updates: ThisApi['body'] }): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/workflows/${workflowId}`, {
    ...updates,
  });
  return response.data;
};

export default updateWorkflow;
