import { AuthUser } from '@providers/Auth';
import storage from '@helpers/storage';
import { Id } from '@typings/common';
import { NoodleProductTypes, ProductState, Price, RichText } from '@typings/graphql-models';
import * as ApiModels from '@typings/api-models';
import { logError } from '@providers/ErrorTracking';
import { mixpanelTrack } from '@providers/Mixpanel';
import { DiscountCode, DiscountCodeProduct } from '@typings/api-models';
import { gaEvent } from '@helpers/analytics';
import { getEcommerceItemFromProduct } from '@helpers/ecommerce';
import * as tsClient from '@tsClient';

export type CartItem = Pick<Price, 'price' | 'priceTitle' | 'originalPrice' | 'stripePriceId' | 'currency' | 'recurringInterval' | 'id' | 'usageType' | 'trialPeriodDays' | 'tierId' | 'priceSubDescription' | 'sessionDuration' | 'sessionInterval' | 'sessionTime' | 'numBookings' | 'isAchPaymentEnabled' | 'isCardPaymentEnabled' | 'passProcessingFeesToCustomer' | 'isDebitCardPaymentEnabled'> & {
  priceDescription?: Pick<RichText, 'html' | 'text'> | null;
  product: {
    id: Id,
    address?: string | null;
    includesBroadcasts?: boolean | null;
    isTierBasedPermissioningEnabled: boolean;
    isActive: boolean;
    slug: string;
    image?: {
      url: string;
    } | null;
    title?: string | null;
    creator?: {
      name?: string | null;
      timezone?: string | null;
      slug: string;
    } | null;
    productTypes: {
      noodleProductType?: NoodleProductTypes | null;
    }[];
    state: ProductState;
    customTermsTemplate?: {
      id: string;
      customTermsDynamicVariables: ApiModels.TypedCustomTermsDynamicVariable[];
    } | null;
  };
};

export type Cart = CartItem[];

class ShoppingCart {
  private user: Pick<ApiModels.Person, 'id'> | null = null;

  private cart: Record<string, Cart> = {};

  private activeCreatorSlug = '';

  private productsToRemove: Cart = [];

  private initializePromise: Promise<void> | null = null;

  private cartUpdateFunc: (carts?: Record<string, Cart>) => void = () => { /* empty */ };

  private discountCodeUpdateFunc: (code: Pick<DiscountCode, 'id' | 'amountOff' | 'percentOff' | 'code'> & { products: DiscountCodeProduct[] } | null) => void = () => { /* empty */ };

  onCartUpdate(updateFunc: (carts?: Record<string, Cart>) => void): void {
    this.cartUpdateFunc = updateFunc;
  }

  onDiscountCodeUpdate(updateFunc: (code: Pick<DiscountCode, 'id' | 'amountOff' | 'percentOff' | 'code'> & { products: DiscountCodeProduct[] } | null) => void): void {
    this.discountCodeUpdateFunc = updateFunc;
  }

  setDiscountCode(code: Pick<DiscountCode, 'id' | 'amountOff' | 'percentOff' | 'code'> & { products: DiscountCodeProduct[] } | null): void {
    this.discountCodeUpdateFunc(code);
  }

  async initialize({ user, creatorSlug }: { user: AuthUser | null, creatorSlug?: string; }): Promise<void> {
    this.initializePromise = new Promise(resolve => {
      storage.getItem('cart').then(cart => {
        if (!cart) {
          storage.setItem('cart', JSON.stringify({}));
        }
        this.cart = JSON.parse(cart || '{}');
        if (this.cart?.length) {
          this.cart = {};
        }
        if (creatorSlug) {
          this.activeCreatorSlug = creatorSlug;
          if (!this.cart[creatorSlug]) {
            this.cart[creatorSlug] = [];
          }
        }
        this.cartUpdateFunc(this.cart);
        storage.setItem('cart', JSON.stringify(this.cart));
        if (user && user?.id && !user.isAnonymous) {
          this.setUser(user);
        }
        resolve();
      })
        .catch(err => logError(err));
    });
    return this.initializePromise;
  }

  getCart(): Cart {
    return this.cart[this.activeCreatorSlug] || [];
  }

  async setUser(user: Pick<ApiModels.Person, 'id'> | null): Promise<void> {
    if (user && user?.id && !this.user) {
      this.user = user;
      if (this.activeCreatorSlug) {
        await this.updateUserCart();
      }
    } else if (!user?.id) {
      this.user = null;
      this.cart = {};
      storage.setItem('cart', JSON.stringify({}));
      this.cartUpdateFunc(this.cart);
    }
  }

  async addProduct({ price }: { price: CartItem }): Promise<void> {
    await this.initializePromise;
    await this.clearCreatorCart();

    if (!price?.id) {
      logError(new Error('Invalid price in addProduct'));
    }
    if (!price?.product?.productTypes) {
      logError(new Error('Adding a product without product types'), { priceId: price?.id });
    }

    this.cart[this.activeCreatorSlug].push(price);
    mixpanelTrack('Added product to cart', {
      creator: price.product.creator?.name,
      priceId: price.id,
      productTitle: price.product.title,
    });
    gaEvent('add_to_cart', {
      items: [getEcommerceItemFromProduct(price.product)],
    });
    storage.setItem('cart', JSON.stringify(this.cart));
    this.cartUpdateFunc(this.cart);
    if (this.user) {
      await this.updateUserCart();
    }
  }

  async removePendingProducts(): Promise<void> {
    for (let i = 0; i < this.productsToRemove.length; i += 1) {
      if (this.cart[this.activeCreatorSlug].map(p => p.id).includes(this.productsToRemove[i].id)) {
        const newCart = this.cart[this.activeCreatorSlug].filter((p) => p.id && p.id !== this.productsToRemove[i].id);
        if (this.user) {
          // eslint-disable-next-line no-await-in-loop
          await this.updateUserCart(newCart);
        }
        this.cart[this.activeCreatorSlug] = newCart;
        storage.setItem('cart', JSON.stringify(this.cart));
        this.cartUpdateFunc(this.cart);
      }
    }
  }

  async removeProduct({ price }: { price: CartItem }): Promise<void> {
    await this.initializePromise;
    this.productsToRemove.push(price);
    await this.removePendingProducts();
  }

  async clearCart(): Promise<void> {
    this.cart = {};
    storage.setItem('cart', JSON.stringify(this.cart));
    this.cartUpdateFunc(this.cart);
    if (this.user) {
      await this.updateUserCart();
    }
  }

  async clearCreatorCart(): Promise<void> {
    this.cart[this.activeCreatorSlug] = [];
    storage.setItem('cart', JSON.stringify(this.cart));
    this.cartUpdateFunc(this.cart);
    if (this.user) {
      await this.updateUserCart();
    }
  }

  async purchaseCart({ discountCode }: { discountCode: string | null | undefined }): Promise<Awaited<ReturnType<typeof tsClient.purchaseCart>>> {
    if (!this.activeCreatorSlug) {
      logError(new Error('Trying to mark a cart as purchased without an activeCreatorSlug'), { level: 'fatal' });
      return null;
    }
    if (!this.user) {
      logError(new Error('Trying to mark a cart as purchased without a user'), { level: 'fatal' });
      return null;
    }
    await this.updateUserCart();
    const pricesPurchased = this.cart[this.activeCreatorSlug].map(i => ({
      id: i.id,
      product: { id: i.product.id },
    }));
    const purchasedCart = await tsClient.purchaseCart({
      creatorSlug: this.activeCreatorSlug,
      discountCode: discountCode ?? null,
      personId: this.user.id,
      prices: pricesPurchased,
    });
    this.cart[this.activeCreatorSlug] = [];
    storage.setItem('cart', JSON.stringify(this.cart));
    this.cartUpdateFunc(this.cart);
    return purchasedCart;
  }

  async updateUserCart(newCart?: Cart): Promise<void> {
    if (!this.user) {
      logError(new Error('Trying to update a cart without a user'), { level: 'fatal' });
      return;
    }
    if (this.activeCreatorSlug) {
      const cart = newCart || this.cart[this.activeCreatorSlug];
      const cartsToSave = [
        {
          creatorSlug: this.activeCreatorSlug,
          personId: this.user.id,
          priceIds: cart.map((p) => p.id).filter(p => p),
        },
      ];
      if (cart.length > 0) {
        await tsClient.updateSavedCart(cartsToSave);
      }
    } else {
      const cartsToSave = Object.keys(this.cart).map(creatorSlug => {
        let cart = this.cart[creatorSlug];
        if (newCart && creatorSlug === this.activeCreatorSlug) {
          cart = newCart;
        }
        return {
          creatorSlug,
          personId: this.user?.id || '',
          priceIds: cart.map((p) => p.id).filter(p => p),
        };
      }).filter(c => c.priceIds.length > 0);
      await tsClient.updateSavedCart(cartsToSave);
    }
  }
}

export default new ShoppingCart();
