import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/persons/purchased-products']['GET'];
type Props = ThisApi['query'];

const getPurchasedProducts = async (params: Props): Promise<ThisApi['response']['products']> => {
  const response = await noodleApiClient.get('/persons/purchased-products', { params });
  return response.data.products;
};

export default getPurchasedProducts;
