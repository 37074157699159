import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/organizations/{organizationId}']['PUT'];

type Input = ThisApi['params'] & ThisApi['body'];

const updateOrganization = async ({ organizationId, ...updates }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.put(`/organizations/${organizationId}`, updates);
  return data.data;
};

export default updateOrganization;
