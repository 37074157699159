import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/generated-pdfs']['POST'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const generateUserWorkflowPdfs = async ({ userWorkflowId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/workflows/user-workflows/{userWorkflowId}/generated-pdfs', { userWorkflowId });
  const response = await noodleApiClient.post(path);
  return response.data;
};

export default generateUserWorkflowPdfs;
