import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/my/creators/{creatorId}/payment-methods/default/{paymentMethodId}']['POST'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const setAsDefaultPaymentMethod = async ({ creatorId, paymentMethodId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/my/creators/{creatorId}/payment-methods/default/{paymentMethodId}', { creatorId, paymentMethodId });
  const response = await noodleApiClient.post(path);
  return response.data;
};

export default setAsDefaultPaymentMethod;
