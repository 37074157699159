import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/external-data-sets/by-slug/{slug}']['PUT'];
type Input = ThisApi['params'];
type Body = ThisApi['body'];

const createDataReference = async ({ slug }: Input, body: Body): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/external-data-sets/by-slug/{slug}', { slug });
  const data = await formsBackendClient.put(path, body);
  return data.data;
};

export default createDataReference;