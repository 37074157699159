import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/calendar-accounts/{calendarAccountId}']['DELETE'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const disconnectCalendarAccount = async ({ calendarAccountId }: Input): Promise<Output> => {
  const response = await noodleApiClient.delete(`/calendar-accounts/${calendarAccountId}`);
  return response.data;
};

export default disconnectCalendarAccount;
