import UAParser from 'ua-parser-js';
import { InstallType } from './typings';

const getAppInstallType = (): InstallType => {
  if (!window) {
    return 'unsupported';
  }

  const parser = new UAParser();
  const os = parser.getOS();
  const browser = parser.getBrowser();

  if (os.name === 'iOS') {
    if (browser.name === 'Mobile Safari') {
      return 'apple-share-button';
    }

    // For now, test and maybe make ios
    if (browser.name === 'Chrome') {
      return 'unsupported';
    }

    // @todo explore how to handle other browsers
    return 'unsupported';
  }

  if (os.name === 'Android') {
    if (browser.name === 'Chrome') {
      return 'native';
    }

    // @todo explore how to handle other browsers
    return 'native';
  }

  if (os.name === 'Mac OS') {
    if (browser.name === 'Safari') {
      return 'unsupported';
    }

    if (browser.name === 'Chrome') {
      return 'native'; // verified to work
    }

    return 'unsupported';
  }

  if (os.name === 'Windows') {
    // @todo explore which windows browsers support PWA install
    return 'native';
  }

  return 'unsupported';
};

export default getAppInstallType;