import noodleApiClient, { ApiDefinition } from '@noodleApi';
import * as ApiModels from '@typings/api-models';

type ThisApi = ApiDefinition['/creators/{creatorId}/invoices']['GET'];
type Input = ThisApi['params'] & Omit<ThisApi['query'], 'filters'> & {
  filters?: ApiModels.INVOICE_STATUS[];
};

const getCreatorInvoices = async ({ creatorId, filter, startDate, endDate, search, page = 1, perPage = 25 }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/invoices`, {
    params: {
      endDate,
      filter,
      page,
      perPage,
      search,
      startDate,
    },
  });
  return response.data;
};

export default getCreatorInvoices;
