import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/collaborators';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response']['items'];

const getUserWorkflowCollaborators = async ({ userWorkflowId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, {userWorkflowId});
  const response = await noodleApiClient.get(path);
  return response.data.items;
};

export default getUserWorkflowCollaborators;