import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports-b2c/{creditReportId}/send-verification-link/{mtoken}']['POST'];

type Input = {
  creditReportId: string;
  mtoken: string;
};

type Output = ThisApi['response'];

const sendVerificationLink = async ({ creditReportId, mtoken }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/credit-reports-b2c/${creditReportId}/send-verification-link/${mtoken}`);

  return response.data;
};

export default sendVerificationLink;
