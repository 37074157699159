import { logError } from '@providers/ErrorTracking';
import getTypedKeys from '@helpers/getTypedKeys';
import ApiError from '@helpers/ApiError';

type Value = string | number | boolean | Date | { toString: () => string } | undefined | null;

const singleItemToString = (item: Value): string | null => {
  if (item === null || item === undefined || item === '') {
    return null;
  }

  if (item instanceof Date) {
    return item.toISOString();
  }

  if (typeof item === 'string') {
    return item;
  }

  if (typeof item === 'boolean' || typeof item === 'number') {
    return item.toString();
  }

  logError(new Error('Unable to make a string'), { item });
  return item as unknown as string;
};

const itemToString = (item: Value | Value[]): string | string[] | null => {
  if (Array.isArray(item)) {
    const mapped = item.map(singleItemToString);
    const onlystrings = mapped.filter(i => i !== null) as string[];
    return onlystrings;
  }
  return singleItemToString(item);
};

const makeParamString = (thing: string | string[]): string => Array.isArray(thing) ? thing.join(',') : thing;

const checkForUnresolvedParams = (url: string, details: { pathTemplate: string; params: unknown }): void => {
  if (/{([^/]+)}/.test(url)) {
    const error = new ApiError({
      data: details,
      message: 'Unresolved Params',
      statusCode: 400,
      type: 'UnresolvedParamsError',
    });
    throw error;
  }
};

type BaseParams = Record<string, Value | Value[]>;
type ThisApiNoParams = { params: void };
type ThisApiWithParams = { params: BaseParams };
type InputParamsFromApi<Params extends BaseParams | void> = Params extends BaseParams ? Record<keyof Params, Value | Value[]> : never;

function buildPath<_THIS_API extends ThisApiNoParams>(pathTemplate: string, params?: never): string;
function buildPath<THIS_API extends ThisApiWithParams>(pathTemplate: string, params?: InputParamsFromApi<THIS_API['params']>): string;
function buildPath<THIS_API extends { params: BaseParams | void }>(pathTemplate: string, params?: InputParamsFromApi<THIS_API['params']>): string {
  let path = pathTemplate;

  if (params) {
    getTypedKeys(params).forEach((key) => {
      const value = itemToString(params[key]);
      if (value !== null) {
        path = path.replace(`{${key as string}}`, encodeURIComponent(makeParamString(value)));
      }
    });
  }

  checkForUnresolvedParams(path, { params, pathTemplate });

  return path;
}

export default buildPath;