import React, { CSSProperties } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import scrollParentToTop from '@helpers/scrollParentToTop';
import s from './CustomLink.module.scss';

type Props = {
  onClick?: () => unknown;
  newTab?: boolean;
  className?: string;
  style?: CSSProperties;
  to?: string | null;
  title?: string;
  ariaLabel?: string;
  prefetch?: boolean;
};

const CustomLink: React.FC<Props> = ({
  children,
  onClick = undefined,
  className = '',
  newTab = false,
  to,
  title,
  style,
  ariaLabel,
  prefetch,
}) => {
  if (!to) {
    return (
      <a className={classNames([className, s.disabled])} style={style} aria-label={ariaLabel} title={title}>
        {children}
      </a>
    );
  }
  if (newTab) {
    return (
      <a className={className} style={style} target="_blank" rel="noopener noreferrer" href={to} aria-label={ariaLabel} title={title}>
        {children}
      </a>
    );
  }

  const handleClick: React.MouseEventHandler = () => {
    scrollParentToTop();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link href={to} prefetch={prefetch} className={className} onClick={handleClick} style={style} aria-label={ariaLabel} title={title}>
      {children}
    </Link>
  );
};

export default CustomLink;
