import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/custom-terms-templates/{id}';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['DELETE'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const archiveCustomTermsTemplate = async ({ id }: Input): Promise<Output> => {
  const data = await noodleApiClient.delete(`/custom-terms-templates/${id}`);
  return data.data;
};

export default archiveCustomTermsTemplate;
