import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisRequest = ApiDefinition['/ai/business-description']['POST'];
type Input = ThisRequest['body'];
type Output = ThisRequest['response'];

const generateBusinessDescription = async (payload: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/ai/business-description', payload);
  return response.data;
};

export default generateBusinessDescription;