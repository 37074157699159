import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/customer-for/{forCreatorId}']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getStripeCustomerForCreator = async ( { creatorId, forCreatorId }: Input): Promise<Output['customer']> => {
  const data = await noodleApiClient.get(`/creators/${creatorId}/customer-for/${forCreatorId}`);
  return data.data.customer;
};

export default getStripeCustomerForCreator;
