import { createContext } from 'react';
import { FEATURE_FLAG_KEY } from './checkFeatureFlag';

type FeatureFlagsContextType = {
  checkFeatureFlag: (featureFlag: FEATURE_FLAG_KEY) => boolean;
};

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  checkFeatureFlag: () => false,
});

export default FeatureFlagsContext;
