import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/daily-brief']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getCreatorDailyBrief = async ({ creatorId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/creators/{creatorId}/daily-brief', { creatorId });
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getCreatorDailyBrief;