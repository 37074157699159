import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/custom-terms/{id}']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const createCustomTermsFromInstance = async ({ data, id }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/custom-terms/${id}`, { data });
  return response.data;
};

export default createCustomTermsFromInstance;
