import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/my/creators/{creatorId}/payment-methods/{paymentMethodId}']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getPaymentInfoStripe = async ({ creatorId, paymentMethodId }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>('/my/creators/{creatorId}/payment-methods/{paymentMethodId}', {
    creatorId,
    paymentMethodId,
  });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getPaymentInfoStripe;
