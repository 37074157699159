import 'url-search-params-polyfill';
import { NOODLE_API_HOST, NOODLE_DOCUMENT_HOST, SELF_ORIGIN } from '@configuration/client';
import { logError } from '@providers/ErrorTracking';
import { IDENTIFIERS, IdentifierParams } from './localPaths';
import type { Value, Params } from './typings';

const singleItemToString = (item: Value): string | null => {
  if (item === null || item === undefined || item === '') {
    return null;
  }

  if (item instanceof Date) {
    return item.toISOString();
  }

  if (typeof item === 'string') {
    return item;
  }

  if (typeof item === 'boolean' || typeof item === 'number' || item.toString()) {
    return item.toString();
  }

  logError(new Error('Unable to make a string'), { item });
  return item as unknown as string;
};

const itemToString = (item: Value | Value[]): string | string[] | null => {
  if (Array.isArray(item)) {
    const mapped = item.map(singleItemToString);
    const onlystrings = mapped.filter(i => i !== null) as string[];
    return onlystrings;
  }
  return singleItemToString(item);
};

const makeParamString = (thing: string | string[]): string => Array.isArray(thing) ? thing.join(',') : thing;

const removeUnresolvedPathParams = (url: string): string => {
  const regexp = /{([^/]+)}/gm;
  const newUrl = url.replace(regexp, '');
  if (newUrl !== url) {
    logError(new Error('Unresolved path param'), { newUrl, url });
  }
  return newUrl;
};

const getUrlBase = (identifier: string, params: Params = {}): string => {
  let url = identifier;

  const queryParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const value = itemToString(params[key]);
    if (value !== null) {
      const was = url;
      url = url.replace(`[${key}]`, encodeURIComponent(makeParamString(value)));
      if (was === url) {
        if (Array.isArray(value)) {
          value.forEach(v => queryParams.append(key, v));
        } else {
          queryParams.set(key, value);
        }
      }
    }
  });

  url = removeUnresolvedPathParams(url);

  const queryParamsStr = new URLSearchParams(queryParams).toString();
  if (queryParamsStr) {
    const alreadyHasQS = /\?/.test(url);
    const sep = alreadyHasQS ? '&' : '?';
    url = `${url}${sep}${queryParamsStr}`;
  }

  return url;
};

export const getUrl = <I extends IDENTIFIERS>(identifier: I, ...rest: IdentifierParams<I>): string => {
  const params = rest.length > 0 ? rest[0] : {};
  if (identifier === undefined || identifier === null) { // for non-typescript usages that do IDENTIFIERS.SOMETHING_NOT_DEFINED
    logError(new Error('Undefined identifier passed to getUrl'), { params });
    return getUrlBase('', params);
  }
  return getUrlBase(identifier.toString(), params);
};

export const getNoodleFrontendUrl = <I extends IDENTIFIERS>(identifier: I, ...rest: IdentifierParams<I>): string => {
  const params = rest.length > 0 ? rest[0] : {};
  const path = getUrlBase(identifier.toString(), params);
  return `${SELF_ORIGIN}${path}`;
};

// eslint-disable-next-line import/no-unused-modules
export const getNoodleDocumentsUrl = (identifier: string, params: Params = {}): string => {
  const path = getUrlBase(identifier.toString(), params);
  return `${NOODLE_DOCUMENT_HOST}${path}`;
};

// eslint-disable-next-line import/no-unused-modules
export const getNoodleApiUrl = (identifier: string, params: Params = {}): string => {
  const path = getUrlBase(identifier.toString(), params);
  return `${NOODLE_API_HOST}${path}`;
};

export function getCorrectFileUrl(fileUrl: string): string {
  if ((fileUrl.search(/^\/api\/file\/media/) === 0)
    || fileUrl.search(/^file/) === 0) {
    return `${NOODLE_API_HOST}/${fileUrl.replace(/^\/api\/file\/media/, '/file')}`;
  }
  return fileUrl;
}

export { IDENTIFIERS, getUrlBase };
