import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/custom-terms/{id}/permissions']['GET'];
type Input = ThisApi['params'];

const getCustomTermsPermissions = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/custom-terms/${id}/permissions`);
  return response.data;
};

export default getCustomTermsPermissions;
