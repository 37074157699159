import buildPath from '@/tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/{provider}/integrations']['DELETE'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const removeCreatorIntegrationAccount = async ({ creatorId, provider }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/creators/{creatorId}/{provider}/integrations', { creatorId, provider });
  const response = await noodleApiClient.delete(path);
  return response.data;
};

export default removeCreatorIntegrationAccount;
