import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const PATH_TEMPLATE = '/creators/{creatorId}/workflows/{workflowSlug}/{versionNumber}';
type ThisApi = ApiDefinition['/creators/{creatorId}/workflows/{workflowSlug}/{versionNumber}']['GET'];
type Props = Omit<ThisApi['params'], 'versionNumber'> & {
  version: number;
};

const getWorkflowVersion = async ({ creatorId, workflowSlug, version }: Props): Promise<ThisApi['response']> => {
  const versionNumber = version.toFixed(0);
  const path = buildPath<ThisApi>(PATH_TEMPLATE, { creatorId, versionNumber, workflowSlug });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getWorkflowVersion;
