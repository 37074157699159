import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/workflows/{workflowSlug}/draft']['GET'];

type Props = ThisApi['params'];

const getWorkflowDraft = async ({ creatorId, workflowSlug }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/workflows/${workflowSlug}/draft`);
  return response.data;
};

export default getWorkflowDraft;
