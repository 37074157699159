import { FC, useCallback, useContext, useEffect } from 'react';
import { useUser } from '@hooks';
import * as configuration from '@configuration/client';
import useNoodleApi from '@hooks/useNoodleApi';
import registerPushNotification from '@tsClient/registerPushNotification';
import deregisterPushNotification from '@tsClient/deregisterPushNotification';
import { logError } from '@providers/ErrorTracking';
import { serializers, useLocalStorageState } from '@providers/LocalStorageState';
import PushNotificationContext from '@providers/PushNotifications/Context';
import { useIsUserInitialized } from '@providers/Auth';
import { FEATURE_FLAG_PUSH_NOTIFICATIONS_ENABLED } from '@configuration/client';
import { PWAContext } from '@providers/PWA';

const PushNotificationProvider: FC = ({ children }) => {
  const [user] = useUser();
  const { isInApp } = useContext(PWAContext);
  const isUserInitialized = useIsUserInitialized();
  const [subscriptionId, setSubscriptionId] = useLocalStorageState({
    key: 'pushNotificationId',
    prefix: 'pushNotifications',
    ...serializers.string,
  });
  const { getData: registerPushNotificationFn } = useNoodleApi(registerPushNotification);
  const { getData: deregisterPushNotificationFn } = useNoodleApi(deregisterPushNotification);

  const register = useCallback(async (): Promise<void> => {
    try {
      if ('Notification' in window) {
        const permission = await Notification.requestPermission();
        if (permission === 'granted' || Notification.permission === 'granted') {
          const registration = await navigator.serviceWorker.register('/push-notifications.js', {
            scope: '/',
          });
          // registration.pushManager is nonexistent in iOS safari. User needs to install the PWA in this case
          if (registration.pushManager) {
            await registration.update();
            const subscription = await registration.pushManager.subscribe({
              applicationServerKey: configuration.VAPID_KEY,
              userVisibleOnly: true,
            });
            const { data: registeredSubscription } = await registerPushNotificationFn({ subscription });
            setSubscriptionId(registeredSubscription?.id);
          } else {
            alert('Push notification registration isn\'t supported on your platform. Please try installing the app, or contact support.');
          }
        } else {
          alert('Push notifications are not enabled by your browser. Please go into your system settings and enable them and try again, or contact support.');
        }
      } else {
        alert('Push notifications aren\'t supported on your platform. Please try installing the app, or contacting support.');
      }
    } catch (e) {
      alert('Failed to enable push notifications. Please contact support.');
      // Ignore permission errors
      if (!(e instanceof DOMException)) {
        logError(e);
      }
    }
  }, [registerPushNotificationFn, setSubscriptionId]);

  const deregister = useCallback(async (): Promise<void> => {
    if (subscriptionId) {
      await deregisterPushNotificationFn({ deviceId: subscriptionId });
      setSubscriptionId('');
    }
  }, [deregisterPushNotificationFn, subscriptionId, setSubscriptionId]);
  useEffect(() => {
    if (!user && isUserInitialized && subscriptionId) {
      deregister();
    }
  }, [user, register, deregister, subscriptionId, isUserInitialized]);
  useEffect(() => {
    if (user && isInApp && !subscriptionId && 'Notification' in window && Notification.permission === 'granted' && FEATURE_FLAG_PUSH_NOTIFICATIONS_ENABLED) {
      register();
    }
  }, [isInApp, user, subscriptionId, register]);
  return (
    <PushNotificationContext.Provider value={{
      deregisterPushNotifications: deregister,
      pushSubscriptionId: subscriptionId,
      registerPushNotifications: register,
    }}>
      {children}
    </PushNotificationContext.Provider>
  );
};

export default PushNotificationProvider;
