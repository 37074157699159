import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/auth/user']['GET'];

/** @deprecated should useProfile in most cases, or maybe, getProfile */
const getUserInfo = async (): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get('/auth/user');
  return response.data;
};

export default getUserInfo;