import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BeforeInstallPromptEvent, beforeinstallprompt, getAppInstallType, InstallType } from '@helpers/pwa';
import { useFeatureFlag } from '@providers/FeatureFlags';
import { logError } from '@providers/ErrorTracking';
import { mixpanelTrack } from '@providers/Mixpanel';
import { useRouter } from 'next/router';
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import isInIframe from '@helpers/isInIframe';
import DashboardAppHead from './DashboardAppHead';
import DashboardAppContext from './Context';
import { AppName } from './typings';
import getAppName from './getAppName';

const SERVICE_WORKER_PATH = "/apps/dashboard/service-worker.js";
const SERVICE_WORKER_OPTIONS = {
  scope: '/dashboard',
};

type Props = {
  onInstalled?: () => unknown;
};

const DashboardAppProvider: React.FC<Props> = ({ children, onInstalled }) => {
  const router = useRouter();
  const isDashboardAppFeatureEnabled = useFeatureFlag('dashboard-pwa-installable');
  const [isReadyToInstall, setIsReadyToInstall] = useState(false);
  const [isInApp, setIsInApp] = useState(false);
  const [installType, setInstallType] = useState<InstallType>('unsupported');
  const [appName, setAppName] = useState<AppName>('web');

  const isInPWAQS = deserializeQueryStringItem(router.query['is-in-pwa'], { defaultValue: false, type: 'boolean' });

  const beforeInstallPromptEvent = useRef<BeforeInstallPromptEvent | null>(null);
  const onInstalledRef = useRef<null | (() => void)>(null);
  onInstalledRef.current = onInstalled || null;

  const isEnabled = isDashboardAppFeatureEnabled
    && (installType !== 'unsupported')
    && !isInIframe();

  const doInstall = useCallback(async () => {
    if (beforeInstallPromptEvent.current?.prompt) {
      try {
        await beforeInstallPromptEvent.current.prompt();
        setIsInApp(true);
      } catch(error) {
        logError(error);
      }
    }
  }, []);

  useEffect(() => {
    setInstallType(isDashboardAppFeatureEnabled ? getAppInstallType() : 'unsupported');
  }, [isDashboardAppFeatureEnabled]);

  useEffect(() => {
    const thisAppName = getAppName(router.pathname);
    if (
      isInPWAQS
      // iOS
      || (window.navigator as unknown as { standalone: boolean }).standalone === true
      // desktop
      || typeof window.matchMedia === 'function' && window.matchMedia('(display-mode: standalone)').matches
    ) {
      setIsInApp(true);
      setAppName(thisAppName);
      try {
        sessionStorage.setItem('is-in-pwa', 'true');
        sessionStorage.setItem('pwa-app-name', thisAppName || '');
      } catch(_error) { /* */ }
    } else {
      try {
        const isInPWAStored = sessionStorage.getItem('is-in-pwa') === 'true';
        const storedAppName = sessionStorage.getItem('pwa-app-name') as AppName;
        if (isInPWAStored) {
          setIsInApp(true);
          setAppName(storedAppName || thisAppName || 'other-app');
        } else {
          setIsInApp(false);
          setAppName('web');
        }
      } catch(_error) {
        setIsInApp(false);
        setAppName('web');
      }
    }
  // only want to fire onMount, not all route changes so don't add router.pathname to the dependency list
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInPWAQS]);

  useEffect(() => {
    if (isInApp) {
      if (appName === 'noodle-app') {
        mixpanelTrack('NoodleApp Loaded');
      } else if (appName !== 'web') {
        mixpanelTrack('PWA Loaded', { appName });
      }
    }
  }, [appName, isInApp]);

  useEffect(() => {
    if (isEnabled && 'addEventListener' in window) {
      const handler = (): void => {
        mixpanelTrack('InstallNoodleApp - Install Completed');
        if (onInstalledRef.current) {
          onInstalledRef.current();
        }
      };
      window.addEventListener('appinstalled', handler);
      return () => window.removeEventListener('appinstalled', handler);
    }
    return () => undefined;
  }, [isEnabled]);

  useEffect(() => {
    if (isEnabled && 'addEventListener' in window) {
      const handler: EventListenerOrEventListenerObject = (event) => {
        mixpanelTrack('InstallNoodleApp - Install Ready');
        event.preventDefault();
        beforeInstallPromptEvent.current = event as BeforeInstallPromptEvent;
        setIsReadyToInstall(true);
      };

      window.addEventListener(beforeinstallprompt, handler);
      return () => window.removeEventListener(beforeinstallprompt, handler);
    }
    return () => undefined;
  }, [isEnabled]);

  useEffect(() => {
    if (isEnabled && 'serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(SERVICE_WORKER_PATH, SERVICE_WORKER_OPTIONS)
        .catch(error => {
          if (!error.message || !/The user denied permission to use Service Worker/.test(error.message)) {
            logError(error);
          }
        });

      return () => {
        navigator.serviceWorker.ready.then((registration) => registration.unregister());
      };
    }
    return () => undefined;
  }, [isEnabled]);

  const value = useMemo(() => ({
    appName,
    doInstall,
    installType,
    isInApp,
    isReadyToInstall,
  }), [appName, doInstall, isReadyToInstall, isInApp, installType]);

  return (
    <DashboardAppContext.Provider value={value}>
      <DashboardAppHead isEnabled={isEnabled} />
      {children}
    </DashboardAppContext.Provider>
  );
};

export default DashboardAppProvider;
