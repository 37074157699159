import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type THIS_API = ApiDefinition['/ai/requests/{requestId}/is-accepted']['PUT'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const acceptGeneratedText = async ({ usedText, requestId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/ai/requests/{requestId}/is-accepted', { requestId });
  const response = await noodleApiClient.put(path, { usedText });
  return response.data;
};

export default acceptGeneratedText;