import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/payments/{paymentId}']['PUT'];

type Input = ThisApi['params'] & ThisApi['body'];
type Output = ThisApi['response'];

const updatePayment = async ({ paymentId, ...body }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/payments/{paymentId}', { paymentId });
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default updatePayment;
