import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/inbox/{inboxItemId}/dismiss']['PUT'];

type Input = ThisApi['params'];

const dismissInboxItem = async ({ inboxItemId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/inbox/${inboxItemId}/dismiss`);
  return response.data;
};

export default dismissInboxItem;
