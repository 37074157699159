import Axios from 'axios';
import { logError } from '@providers/ErrorTracking';
import { AnyError } from '@typings/utility';

type ApiErrorConstructor = {
  correlationId?: string;
  data?: Record<string, unknown> | Array<string> | string;
  errors?: string[];
  message: string;
  stack?: string;
  statusCode: number;
  type: string;
};

export type ApiErrorAsJson = Omit<ApiErrorConstructor, 'stack'>;

const isApiErrorConstructor = (obj: AnyError): obj is ApiErrorConstructor => obj && obj.message && obj.type && obj.statusCode;

class ApiError extends Error {
  correlationId: ApiErrorConstructor['correlationId'];

  data: ApiErrorConstructor['data'];

  errors: ApiErrorConstructor['errors'];

  statusCode: ApiErrorConstructor['statusCode'];

  type: ApiErrorConstructor['type'];

  constructor({ correlationId, data, errors, message, stack, statusCode, type }: ApiErrorConstructor) {
    super(message);
    this.statusCode = statusCode;
    this.type = type;
    if (stack) {
      this.stack = stack;
    }
    if (data) {
      this.data = data;
    }
    if (errors) {
      this.errors = errors;
    }
    if (correlationId) {
      this.correlationId = correlationId;
    }
  }

  toJSON(): ApiErrorAsJson {
    const asJson: ApiErrorAsJson = {
      message: this.message,
      statusCode: this.statusCode,
      type: this.type,
    };

    if (this.data) {
      asJson.data = this.data;
    }

    if (this.errors) {
      asJson.errors = this.errors;
    }

    if (this.correlationId) {
      asJson.correlationId = this.correlationId;
    }

    return asJson;
  }

  reason(): string {
    let reason = 'Unknown';
    if (this.type === 'LoginNoSuchUserError') {
      return 'LoginNoSuchUserError';
    } if (this.data) {
      if (typeof this.data === 'string') {
        reason = this.data;
      } else if (Array.isArray(this.data) && typeof this.data[0] === 'string') {
        reason = this.data[0]; // eslint-disable-line prefer-destructuring
      } else if (!Array.isArray(this.data) && typeof this.data.reason === 'string') {
        reason = this.data.reason;
      }
    } else if (Array.isArray(this.errors) && typeof this.errors[0] === 'string') {
      reason = this.errors[0]; // eslint-disable-line prefer-destructuring
    }
    return reason;
  }

  static create(e: unknown): ApiError {
    if (e instanceof ApiError) {
      return e;
    }

    let error: ApiErrorConstructor;
    if (Axios.isAxiosError(e) && e.response) {
      const { headers, data: response, status, statusText } = e.response;
      if (typeof response === 'string') {
        error = {
          correlationId: headers['x-correlationid'],
          message: response,
          statusCode: 500,
          type: 'ApiErrorString',
        };
      } else if (response.message) {
        error = {
          correlationId: headers['x-correlationid'],
          data: response.details,
          errors: response.errors,
          message: response.message,
          stack: response.stack,
          statusCode: response.statusCode || status || 500,
          type: response.type || 'ApiErrorUnknown',
        };
      } else if (statusText) {
        error = {
          correlationId: headers['x-correlationid'],
          message: statusText,
          stack: response.stack,
          statusCode: response.statusCode || status || 500,
          type: 'ApiErrorUnknown',
        };
      } else {
        try {
          error = {
            correlationId: headers['x-correlationid'],
            message: JSON.stringify(response),
            stack: response.stack,
            statusCode: 500,
            type: 'ApiErrorUnknown',
          };
        } catch (stringifyError) {
          logError(stringifyError);
          error = {
            correlationId: headers['x-correlationid'],
            message: 'Unknwown error',
            stack: response.stack,
            statusCode: 500,
            type: 'ApiErrorStringify',
          };
        }
      }
    } else if (typeof e === "string") {
      error = {
        message: e,
        statusCode: 500,
        type: 'InternalError',
      };
    } else if (isApiErrorConstructor(e)) {
      error = e;
    } else if (e instanceof Error) {
      error = {
        message: e.message,
        stack: e.stack,
        statusCode: 500,
        type: e.name,
      };
    } else {
      error = {
        message: 'Something went wrong',
        statusCode: 500,
        type: 'InternalError',
      };
    }
    return new ApiError(error);
  }
}

export default ApiError;

export type PageError = ApiError | null;
