import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '../utilities/buildPath';

const PATH_TEMPLATE = '/workflows/user-workflow-activity/{userWorkflowId}';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getUserWorkflowActivityById = async ({ endDate, filter, page, perPage, search, startDate, userWorkflowId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { userWorkflowId });
  const response = await noodleApiClient.get(path, { params: { endDate, filter, page, perPage, search, startDate } });
  return response.data;
};

export default getUserWorkflowActivityById;
