import { ToastMessage, ToastTypeVariants, useToastContext } from 'context/ToastContext';
import { useCallback } from 'react';

export type AddToast = (type: ToastTypeVariants, message: ToastMessage) => void;

function useToast(): (type: ToastTypeVariants, message: ToastMessage) => void {
  const { addToast } = useToastContext();

  const toast: AddToast = useCallback((type, message) => {
    addToast( message, type );
  }, [addToast]);

  return toast;
}

useToast.ToastTypeVariants = ToastTypeVariants;

export default useToast;
export { ToastTypeVariants };