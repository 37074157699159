import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const PathTemplate = '/products/{productId}/bookings/{bookingId}';

type ThisApi = ApiDefinition['/products/{productId}/bookings/{bookingId}']['PUT'];
type Input = ThisApi['body'] & ThisApi['params'];
type Output = ThisApi['response'];

const rescheduleBooking = async ({ bookingId, productId, startAt, teamMemberPersonId }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>(PathTemplate, { bookingId, productId });
  const data = await noodleApiClient.put(path, { startAt, teamMemberPersonId });
  return data.data;
};

export default rescheduleBooking;
