import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{id}/availability-blocks']['GET'];
type Input = THIS_API['query'] & THIS_API['params'];
type Output = THIS_API['response'];

const getAvailabilityBlocksForCreator = async ({ startDate, endDate, creatorId, teamMemberPersonId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`creators/${creatorId}/availability-blocks`, {
    params: {
      endDate,
      startDate,
      teamMemberPersonId,
    },
  });
  return response.data;
};

export default getAvailabilityBlocksForCreator;