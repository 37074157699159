import { useRouter } from 'next/router';
import jwtDecode from 'jwt-decode';
import { logError } from '@providers/ErrorTracking';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseEffectDestructor } from '@typings/utility';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { ToastTypeVariants } from '@/context/ToastContext';
import getCookieUser from './getCookieUser';
import AuthContext, { AuthContextType } from './AuthContext';
import { AuthUser, Profile } from './constants';
import IsOnBehalfOfBar from './IsOnBehalfOfBar';

// trouble making value required here, but optional in general, so can't just use IframeEvent<>
type IframeMessageEvent = {
  data: {
    message: string;
    value: {
      jwtCookie: string;
      parentHost: string;
    }
  }
};

const logoutPaths = ['/auth/logout'];

const calcIsCreator = (newUser: AuthUser | null): boolean => {
  if (!newUser) {
    return false;
  }
  if (typeof newUser.isCreator === 'boolean') {
    return newUser.isCreator;
  }
  return false;
};

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  // We can get multiple simultaneous 'client-cookie' actions with the same token (multiple iframes in chain).
  // Need to prevent creating a new user when we get the same token again so useEffect(fn, [user]) doesn't loop.
  // If we useState to hold the token, we wouldn't see the handled token till the next render.
  // Hold the currentToken in useRef instead so it's immediately avallable to all.
  const currentToken = useRef<string | null>(null);
  const router = useRouter();

  const [isContextInitialized, setIsContextInitialized] = useState(false);
  const { data: fetchedProfile, getData: getProfile } = useNoodleApi(tsClient.my.getProfile);
  const { getData: updateProfileFn } = useNoodleApi(tsClient.my.updateProfile, {
    toastOnError: true,
    toastSuccessMessage: () => [ToastTypeVariants.SUCCESS, 'Profile successfully updated.'],
  });

  const wrapSetUser = useCallback((newUser: AuthUser | null) => {
    if (!newUser) {
      setUser(null);
      setProfile(null);
    } else {
      setUser({
        exp: newUser.exp || 0,
        id: newUser.id,
        isAnonymous: (newUser as AuthUser).isAnonymous ?? false,
        isComplete: newUser.isComplete ?? false,
        isCreator: calcIsCreator(newUser),
        isOnBehalfOf: newUser.isOnBehalfOf,
        token: newUser.token,
      });
    }
  }, [setUser]);

  useEffect(() => {
    if (user && user?.id && !user.isAnonymous && !logoutPaths.includes(router.pathname)) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getProfile]);

  useEffect(() => {
    setProfile(fetchedProfile);
  }, [fetchedProfile]);

  useEffect((): UseEffectDestructor => {
    setIsContextInitialized(true);
  }, []);

  useEffect((): UseEffectDestructor => {
    const eventHandler = (event: IframeMessageEvent): void => {
      if (event?.data?.message === 'client-cookie') {
        const { jwtCookie, parentHost } = event.data.value;

        // Hold the currentToken in useRef instead of useState.
        // If we useState, we wouldn't see the handled token till the next render, with useRef multiple simultaneous actions see the token immediately.
        if (jwtCookie === currentToken?.current) {
          return;
        }
        currentToken.current = jwtCookie;
        let jwt: AuthUser | null = null;

        try {
          jwt = jwtDecode(jwtCookie);
          if (jwt) {
            jwt.token = jwtCookie;
          }
        } catch (error) {
          logError(error, { from: 'client-cookie-event' });
        }

        if (parentHost) {
          window.parentHost = parentHost;
        }

        if (jwtCookie) {
          window.httpToken = jwtCookie;
        }
        wrapSetUser(jwt);
      }
    };

    window.addEventListener('message', eventHandler, false);

    const newUser = getCookieUser();
    wrapSetUser(newUser);

    return (): void => {
      window.removeEventListener('message', eventHandler, false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateProfile = useCallback<AuthContextType['updateProfile']>(async (body) => {
    const response = await updateProfileFn(body);
    if (response.data) {
      setProfile(response.data);
    }
    return response;
  }, [updateProfileFn]);

  const value = useMemo(() => ({
    isContextInitialized,
    profile,
    setUser: wrapSetUser,
    updateProfile,
    user,
  }), [isContextInitialized, wrapSetUser, user, profile, updateProfile]);

  return (
    <AuthContext.Provider value={value}>
      <IsOnBehalfOfBar user={user} profile={profile}/>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
