import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}/field-templates']['POST'];
type Input = ThisApi['params'] & { data: ThisApi['body'] };

const createFieldTemplate = async ({ creatorId, data: body }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaire-templates/{creatorId}/field-templates', {
    creatorId,
  });
  const data = await formsBackendClient.post(path, body);
  return data.data;
};

export default createFieldTemplate;
