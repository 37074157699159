import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/user-workflows/{userWorkflowId}']['DELETE'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const deleteUserWorkflow = async ({ userWorkflowId }: Input): Promise<Output> => {
  const response = await noodleApiClient.delete(`/workflows/user-workflows/${userWorkflowId}`);
  return response.data;
};

export default deleteUserWorkflow;
