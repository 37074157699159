import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/inbox/{inboxItemId}/read-status']['PUT'];

type Input = ThisApi['params'] & ThisApi['body'];

const changeReadStatus = async ({ inboxItemId, isRead }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/inbox/${inboxItemId}/read-status`, {
    isRead,
  });
  return response.data;
};

export default changeReadStatus;
