import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/subscriptions/{stripeAccountId}';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = THIS_API['response'];

const setDefaultPaymentMethodStripe = async ({ stripeAccountId, ...body }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { stripeAccountId });
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default setDefaultPaymentMethodStripe;
