import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{invoiceId}/permissions']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateInvoicePermissions = async ({ invoiceId, userIds }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/invoices/${invoiceId}/permissions`, { userIds });
  return response.data;
};

export default updateInvoicePermissions;