import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{productId}/availability']['GET'];

type Props = ThisApi['params'] & ThisApi['query'];

const getProductAvailability = async ({ productId, teamMemberPersonId }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/products/${productId}/availability`, {
    params: {
      teamMemberPersonId,
    },
  });
  return response.data;
};

export default getProductAvailability;
