import noodleApiClient from '@noodleApi';

type Props = {
  productId: string,
  commentId: string,
};

const deleteUserNotes = async ({ productId, commentId, ...restParams }: Props): Promise<void> => {
  await noodleApiClient.delete(`/products/${productId}/comment/${commentId}`, {
    params: restParams,
  });
};

export default deleteUserNotes;