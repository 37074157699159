import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/has-purchased-products']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response']['hasPurchasedProducts'];

const creatorHasPurchasedProducts = async ({ creatorId }: Input): Promise<Output> => {
  const data = await noodleApiClient.get(`/creators/${creatorId}/has-purchased-products`);
  return data.data.hasPurchasedProducts;
};

export default creatorHasPurchasedProducts;
