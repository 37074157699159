import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/members/{personId}']['GET'];

type Props = ThisApi['params'];

const getMemberDetails = async ({ creatorSlug, personId }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorSlug}/members/${personId}`);
  return response.data;
};

export default getMemberDetails;