import { useContext, useEffect, useMemo, useRef } from 'react';
import { useUser, useUserProfile } from '@providers/Auth';
import { useLocalStorageState } from '@providers/LocalStorageState';
import teamsContext from '@providers/Teams/TeamsContext';
import FeatureFlagsContext from './Context';
import checkFeatureFlag, { isNoodler, FEATURE_FLAG_KEY } from './checkFeatureFlag';
import localStorageStateSerializer from './localStorageStateSerializer';

const FeatureFlagProvider: React.FC = ({ children }) => {
  const [overrides, setOverrides] = useLocalStorageState<Partial<Record<FEATURE_FLAG_KEY, boolean>>>({
    defaultValue: {},
    key: 'overrides',
    prefix: 'feature-flags',
    ...localStorageStateSerializer,
  });
  const { creatorId } = useContext(teamsContext);
  const profile = useUserProfile();
  const [user] = useUser();
  const canOverride = isNoodler(profile) || (user && user.isOnBehalfOf);
  const canOverrideRef = useRef(canOverride);
  canOverrideRef.current = canOverride;

  useEffect(() => {
    if (!canOverride) {
      setOverrides({});
    }
  }, [canOverride, setOverrides]);

  useEffect(() => {
    window.setFeatureFlag = (key: string, value: boolean) => {
      if (canOverrideRef.current) {
        setOverrides(state => ({ ...state, [key]: value }));
      } else {
        console.error(new Error('Forbidden'));
      }
    };
    window.clearFeatureFlagOverride = (key: string) => {
      if (canOverrideRef.current) {
        setOverrides(state => ({ ...state, [key]: undefined }));
      } else {
        console.error(new Error('Forbidden'));
      }
    };
  }, [setOverrides]);

  const value = useMemo(() => ({
    checkFeatureFlag: (featureFlagKey: FEATURE_FLAG_KEY): boolean => {
      const override = overrides && overrides[featureFlagKey];
      if (typeof override === 'boolean') {
        return override;
      }
      return checkFeatureFlag(featureFlagKey, profile, creatorId);
    },
  }), [overrides, profile, creatorId]);

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagProvider;
