import DOMPurify from 'isomorphic-dompurify';

import { GA4_MEASUREMENT_ID } from '@configuration/client';

export { default as useGAClientId } from './useGAClientId';

// This needs to be included in _document.tsx, not _app.tsx
const GoogleAnalytics: React.FC = () => {
  if (!GA4_MEASUREMENT_ID) {
    return null;
  }

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${GA4_MEASUREMENT_ID}', {
  page_path: window.location.pathname,
});
`),
        }}
      />
    </>
  );
};

export default GoogleAnalytics;
