import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/files/{fileId}']['GET'];
type Input = ThisApi['params'];

const getDocumentRequestUserFile = async ({ fileId }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.get(`/document-request-users/files/${fileId}`);
  return response.data;
};

export default getDocumentRequestUserFile;
