import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/templates/{id}/line-items']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateTemplateLineItems = async ({ id, lineItems }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/invoices/templates/${id}/line-items`, { lineItems });
  return response.data;
};

export default updateTemplateLineItems;
