import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/carts/{id}']['GET'];
type Output = ThisApi['response'];

const getCartById = async (id: string): Promise<Output> => {
  const data = await noodleApiClient.get(`/carts/${id}`);
  return data.data;
};

export default getCartById;
