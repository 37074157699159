import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const PATH_TEMPLATE = '/my/bookmarks/{productId}';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['DELETE'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const deleteBookmark = async ({ productId } :Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { productId });
  const response = await noodleApiClient.delete(path);
  return response.data;
};

export default deleteBookmark;
