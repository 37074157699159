import { createContext,useCallback, useContext, useReducer } from 'react';
import ToastTypeVariants from './ToastTypeVariants';

export { ToastTypeVariants };

export type ToastMessage = {
  title: string;
  description: string;
} | string;

type Toast = {
  type?: ToastTypeVariants;
  id?: string;
  message: ToastMessage;
};

type State = Toast[];

const initialState: State = [];

type ActionType = 'ADD_TOAST' | 'REMOVE_TOAST';
type Action = { type: ActionType; id?: string; message?: ToastMessage };
type AddAction = Action & { toast: Toast };
type RemoveAction = Action;

type IToastContext = {
  addToast: (message: ToastMessage, type: ToastTypeVariants) => void;
  removeToast: (args: { message: ToastMessage; id?: string }) => void;
  toasts: Toast[];
};

const ToastContext = createContext<IToastContext>({
  addToast: (message, type) => type + message.toString(),
  removeToast: args => args.toString(),
  toasts: [],
});

export const isTitleDescriptionMessage = (message: ToastMessage): message is Exclude<ToastMessage, string> => typeof message !== 'string';

function ToastReducer(state: State, action: AddAction | RemoveAction): State {
  switch (action.type) {
  case 'ADD_TOAST': {
    const addAction = action as AddAction;
    return [
      ...state.filter(t => JSON.stringify(t) !== JSON.stringify(addAction.toast)),
      addAction.toast,
    ];
  }
  case 'REMOVE_TOAST': {
    const updatedToasts = state.filter((t: Toast) => {
      if (action.id) {
        return t.id !== action.id;
      }
      if (action.message) {
        return JSON.stringify(t.message) !== JSON.stringify(action.message);
      }
      return true;
    });
    return updatedToasts;
  }
  default: {
    throw new Error('unhandled action type');
  }
  }
}

export function ToastProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const [state, dispatch] = useReducer(ToastReducer, initialState);

  const addToast = useCallback((message: string | ToastMessage, type: ToastTypeVariants) => {
    const messageAsString = isTitleDescriptionMessage(message) ? message.description : message;
    dispatch({
      toast: { id: messageAsString, message, type },
      type: 'ADD_TOAST',
    });
  }, []);

  const removeToast = useCallback(({ message, id }: { message: ToastMessage, id?: string }) => {
    dispatch({ id, message, type: 'REMOVE_TOAST' });
  }, []);

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
        toasts: state,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}

export const useToastContext = (): IToastContext => useContext(ToastContext);
