import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/payments/revenue']['GET'];

type GetPaymentsResponse = ThisApi['response'];

const getPaymentRevenue = async ({ creatorId, search, filter, startDate, endDate }: ThisApi['params'] & ThisApi['query']): Promise<GetPaymentsResponse> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/payments/revenue`, {
    params: {
      endDate,
      filter,
      search,
      startDate,
    },
  });
  return response.data;
};

export default getPaymentRevenue;
