(() => {
  // @ts-expect-error This does not exist in our version of node
  if (typeof Promise.withResolvers === "undefined") {
    if (typeof window !== 'undefined') {
      // @ts-expect-error This does not exist outside of polyfill which this is doing
      window.Promise.withResolvers = function () {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
        // eslint-disable-next-line sort-keys-fix/sort-keys-fix
        return { promise, resolve, reject };
      };
    } else {
      // @ts-expect-error This does not exist outside of polyfill which this is doing
      global.Promise.withResolvers = function () {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
        // eslint-disable-next-line sort-keys-fix/sort-keys-fix
        return { promise, resolve, reject };
      };
    }
  }
}) ();

export {};