import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/form-request-templates']['POST'];

type Input = ThisApi['body'];

const postFormRequestTemplate = async (body: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.post('/form-request-templates', body);
  return data.data;
};

export default postFormRequestTemplate;
