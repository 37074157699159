import noodleApiClient from '@noodleApi';

type DeleteHandbookStepProps = {
  productId: string;
  actions: unknown,
};

const deleteHandbookStep = async ({ productId, actions }: DeleteHandbookStepProps): Promise<void> => {
  const data = await noodleApiClient.put(`/products/${productId}/steps`, actions);
  return data.data;
};

export default deleteHandbookStep;
