import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports-b2c/{creditReportId}/preauth-token']['POST'];

type Input = ThisApi['body'] & ThisApi['params'];
type Output = ThisApi['response'];

const getPreauthToken = async ({ creditReportId, userInfo }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/credit-reports-b2c/${creditReportId}/preauth-token`, { userInfo });
  return response.data;
};

export default getPreauthToken;
