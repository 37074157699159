import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{creatorSlug}/{productSlug}']['GET'];

type Props = ThisApi['params'] & ThisApi['query'];

const getProductBySlug = async ({ productSlug, creatorSlug, onlyActivePrices }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/products/${creatorSlug}/${productSlug}`, {
    params: {
      onlyActivePrices,
    },
  });
  return response.data;
};

export default getProductBySlug;
