import { MIXPANEL_TOKEN } from '@configuration/client';
import isInPopoverWidget from '@helpers/isInPopoverWidget';
import mixpanel from 'mixpanel-browser';

let appName = 'web';

export function mixpanelTrack(eventName: string, eventInfo?: Record<string,unknown>): void {
  const isWidgetEvent = isInPopoverWidget();
  if (navigator?.cookieEnabled && MIXPANEL_TOKEN) {
    mixpanel.track(eventName, {...eventInfo, appType: appName, isWidgetEvent});
  }
}

export function mixpanelAlias(aliasId: string): void {
  let currentDistinctId;
  if (navigator?.cookieEnabled && MIXPANEL_TOKEN) {
    currentDistinctId = mixpanel.get_distinct_id();
    if(aliasId !== currentDistinctId) {
      mixpanel.alias(aliasId, currentDistinctId);
    }
  }
}

export function mixpanelSet(userInfo: Record<string,unknown>): void {
  if (navigator?.cookieEnabled && MIXPANEL_TOKEN) {
    mixpanel.people.set(userInfo);
  }
}

export function mixpanelReset(): void {
  if (navigator?.cookieEnabled && MIXPANEL_TOKEN) {
    mixpanel.reset();
  }
}

export function getMixpanelId(): string | null { // eslint-disable-line import/no-unused-modules
  if (navigator?.cookieEnabled && MIXPANEL_TOKEN) {
    return mixpanel.get_distinct_id();
  }
  return null;
}

// Should only be used in MixpanelIdentify
export function setMixpanelAppName(thisAppType: string): void {
  appName = thisAppType;
}
