import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/auth/my-account']['PUT'];
type Output = ThisApi['response'];
type Input = ThisApi['body'];

const completeAccount = async (payload: Input): Promise<Output> => {
  const response = await noodleApiClient.put('/auth/my-account', payload);
  return response.data;
};

export default completeAccount;
