import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useUser } from '@hooks';

const ErrorContext: React.FC = () => {
  const [user] = useUser();
  const userId = user?.id || null;
  useEffect(() => {
    if (userId) {
      Sentry.setUser({ id: userId });
    } else {
      Sentry.setUser(null);
    }
  }, [userId]);

  return null;
};

export default ErrorContext;
