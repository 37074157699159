import noodleMediaClient, { ApiDefinition } from '@mediaApi';

type ThisApi = ApiDefinition['/vimeo-video-status']['GET'];
type Props = ThisApi['query'];

const getVimeoVideoStatus = async (params: Props): Promise<ThisApi['response']> => {
  const response = await noodleMediaClient.get('/vimeo-video-status', { params });
  return response.data;
};

export default getVimeoVideoStatus;
