import React, { Fragment, FC, useState, useEffect } from 'react';

import Head from 'next/head';
import { isInPopoverWidget } from '@helpers/helper';
import Spacer from '@components/Spacer';

import PoweredByNoodle from '@components/PoweredByNoodle';

const BaseLayout: FC = ({ children }) => {
  const [showPowered, setShowPowered] = useState(false);
  useEffect(() => {
    setShowPowered(!isInPopoverWidget());
  }, []);
  return (
    <Fragment>
      <Head>
        <link rel="icon" href="/favicon-32x32.png" />
      </Head>
      {children}
      {showPowered ? <PoweredByNoodle style={{ margin: '64px 0' }} /> : <Spacer size={80} />}
    </Fragment>
  );
};

export default BaseLayout;
