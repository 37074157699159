import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/express-dashboard-link/{stripeAccountId}']['GET'];

const getExpressDashboardLink = async ({ creatorId, stripeAccountId }: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/express-dashboard-link/${stripeAccountId}`);
  return response.data;
};

export default getExpressDashboardLink;
