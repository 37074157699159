// We currently use google translate, valid language codes are here.
// https://cloud.google.com/translate/docs/languages
export const SUPPORTED_LANGUAGES = [
  {
    code: 'en',
    flag: '🇺🇸',
    label: 'English',
  },
  {
    code: 'es',
    flag: '🇲🇽',
    label: 'Español',
  },
  {
    code: 'hi',
    flag: '🇮🇳',
    label: 'हिन्दी',
  },
  {
    code: 'fr',
    flag: '🇫🇷',
    label: 'Français',
  },
  {
    code: 'pt',
    flag: '🇵🇹',
    label: 'Português',
  },
  {
    code: 'zh-CN',
    flag: '🇨🇳',
    label: '中文',
  },
  {
    code: 'ar',
    flag: '🇸🇦',
    label: 'العربية',
  },
] as const;

type Language = typeof SUPPORTED_LANGUAGES[number];
export type LanguageCode = Language['code'];
export const SUPPORTED_LANGUAGE_CODES = SUPPORTED_LANGUAGES.map(l => l.code);
export const DEFAULT_LANGUAGE: Language = SUPPORTED_LANGUAGES[0];
