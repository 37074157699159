import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/compiled-documents';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getUserWorkflowCompiledDocuments = async ({ page, perPage, userWorkflowId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { userWorkflowId });
  const response = await noodleApiClient.get(path, { params: { page, perPage } });
  return response.data;
};

export default getUserWorkflowCompiledDocuments;