import { useContext, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useUser, usePrevious } from '@hooks';
import { MIXPANEL_TOKEN, MIXPANEL_ENVIRONMENT } from '@configuration/client';
import useUserProfile from '@providers/Auth/useUserProfile';
import { PWAContext } from '@providers/PWA';
import { mixpanelTrack, mixpanelReset, mixpanelSet, setMixpanelAppName } from './index';

const debugEnabled = MIXPANEL_ENVIRONMENT === 'development';

const MixpanelProvider: React.FC = () => {
  const [user] = useUser();
  const profile = useUserProfile();
  const { appName, isInApp } = useContext(PWAContext);
  const pathName = usePathname();
  const router = useRouter();

  const userId = user?.id || null;
  const isOnBehalfOf = user?.isOnBehalfOf ?? false;
  const previousUser = usePrevious(userId);

  useEffect(() => {
    if(navigator.cookieEnabled && MIXPANEL_TOKEN) {
      mixpanel.init(MIXPANEL_TOKEN || '', { debug: debugEnabled });
      if (isOnBehalfOf) {
        mixpanel.disable();
      } else if (userId) {
        mixpanel.identify(userId);
      }
    }
  }, [userId, isOnBehalfOf]);

  useEffect(() => {
    if (profile) {
      mixpanelSet({
        '$email': profile.email,
        '$name': profile.name,
        'Phone Number': profile.phoneNumber,
        'User ID': profile.id,
      });
    }
  }, [profile]);

  useEffect(() => {
    if (isInApp) {
      setMixpanelAppName(appName || 'other-app');
    } else {
      setMixpanelAppName('web');
    }
  }, [appName, isInApp]);

  useEffect(() => {
    if (navigator.cookieEnabled && MIXPANEL_TOKEN) {
      if (!userId && previousUser) {
        mixpanelTrack('User Logout');
        mixpanelReset();
      }
    }
  }, [userId, previousUser]);

  useEffect(() => {
    if (pathName) {
      mixpanelTrack(`Page Visit: ${pathName}`, {
        path: router.pathname,
      });
    }
  }, [pathName]);

  return null;
};

export default MixpanelProvider;
