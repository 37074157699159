import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/organizations/{organizationId}/members/{id}']['PUT'];

type Input = ThisApi['params'] & ThisApi['body'];

const updateOrganizationMember = async ({ organizationId, id, ...updates }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.put(`/organizations/${organizationId}/members/${id}`, updates);
  return data.data;
};

export default updateOrganizationMember;
