import noodleApiClient, { ApiDefinition } from '@noodleApi';

type Input = ApiDefinition['/creators/{creatorId}/team-member/{teamMemberId}']['PUT']['params'] & ApiDefinition['/creators/{creatorId}/team-member/{teamMemberId}']['PUT']['body'];
type Output = ApiDefinition['/creators/{creatorId}/team-member/{teamMemberId}']['PUT']['response'];

const updateCreatorTeamMember = async ({ creatorId, teamMemberId, role }: Input): Promise <Output> => {
  await noodleApiClient.put(`/creators/${creatorId}/team-member/${teamMemberId}`, { role });
};

// eslint-disable-next-line import/no-unused-modules
export default updateCreatorTeamMember;
