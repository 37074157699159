import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/invoice-templates']['GET'];
type Input = ThisApi['params'] & ThisApi['query'];

const getInvoiceRequestTemplates = async ({ creatorId, search, page, perPage }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/invoice-templates`, {
    params: {page, perPage, search },
  });
  return response.data;
};

export default getInvoiceRequestTemplates;
