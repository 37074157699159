import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/products/{creatorSlug}/{productSlug}/draft-initiate-ctas';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getDraftInitiateCTAsForProduct = async ({ creatorSlug, productSlug, page, perPage }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorSlug, productSlug });
  const data = await noodleApiClient.get(path, { params: { page, perPage } });
  return data.data;
};

export default getDraftInitiateCTAsForProduct;
