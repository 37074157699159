import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/auth/{userId}/identifier']['GET'];
type Input = ThisApi['params'];

const getUserIdentifier = async ({ userId }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/auth/{userId}/identifier', { userId });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getUserIdentifier;
