import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/form-request-templates']['GET'];

type Input = ThisApi['params'] & ThisApi['query'];

const getCreatorFormTemplates = async ({ creatorId, search, page, perPage, requireValidQuestionnaire, tags }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/form-request-templates`, {
    params: {
      page,
      perPage,
      requireValidQuestionnaire,
      search,
      tags,
    },
  });
  return response.data;
};

export default getCreatorFormTemplates;
