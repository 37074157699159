import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/workflows']['POST'];

const createWorkflow = async ({ creatorId, name, referenceId, referenceType }: ThisApi['body'] & ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/creators/${creatorId}/workflows`, {
    name,
    referenceId,
    referenceType,
  });
  return response.data;
};

export default createWorkflow;
