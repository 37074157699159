import { createContext, useEffect, useState } from 'react';
import { useUser } from '@providers/Auth';

const CACHING_TIME = 2 * 60 * 1000; // 2 mins

const defaultSectionState = {
  data: {},
  fetchedTime: null,
  isFetched: false,
  isFetching: false,
  isRejected: false,
};

const initialContextState = {
  fetchSectionData: () => {},
  getContextSection: () => {},
  isContextInitialized: false,
  parentHostHeight: undefined,
  setContextSection: ({ section, data } = {}) => {} /* eslint-disable-line no-unused-vars */, // so typescript gets correct patternupdateContextSection
  updateContextSection: ({ section, data } = {}) => {} /* eslint-disable-line no-unused-vars */, // so typescript gets correct patternupdateContextSection
};

export const RootContext = createContext({
  ...initialContextState,
});

const AppContextWrapper = ({ children }) => {
  const [user] = useUser();
  const [rootContext, setRootContext] = useState({
    ...initialContextState,
  });

  const userId = user?.id;

  // clear root context on logout
  useEffect(() => {
    if (!userId) {
      setRootContext(rc => {
        const newRootContext = { ...rc };
        const keys = Object.keys(rc);
        keys.forEach(key => {
          if (newRootContext?.[key]?.isFetched) {
            newRootContext[key] = { ...defaultSectionState };
          }
        });
        return newRootContext;
      });
    }
  }, [userId]);

  const setContextSection = ({ section, data, options = {} }) => {
    setRootContext(rc => ({
      ...rc,
      [section]: {
        ...(rc[section] || defaultSectionState),
        data,
        ...options,
      },
    }));
  };

  const updateContextSection = ({ section, data, options = {} }) => {
    setRootContext(rc => ({
      ...rc,
      [section]: {
        ...(rc[section] || defaultSectionState),
        data: { ...rc[section]?.data || {}, ...data },
        ...options,
      },
    }));
  };

  const getContextSection = fn => fn(rootContext);

  const fetchSectionData = async ({ section, fetchingFn, isOmitCache = false }) => {
    const initialData = getContextSection(rc => rc[section]) || defaultSectionState;

    if ((initialData.isFetching || (initialData?.fetchedTime && new Date().getTime() - initialData.fetchedTime < CACHING_TIME)) && !isOmitCache) {
      return initialData?.data;
    }
    setRootContext(rc => ({
      ...rc,
      [section]: {
        ...(rc[section] || defaultSectionState),
        isFetching: true,
        isRejected: false,
      },
    }));
    try {
      const data = await fetchingFn();
      setRootContext(rc => ({
        ...rc,
        [section]: {
          ...rc[section],
          data,
          fetchedTime: new Date().getTime(),
          isFetched: true,
          isFetching: false,
          isRejected: false,
        },
      }));
      return data;
    } catch (e) {
      setRootContext(rc => ({
        ...rc,
        [section]: {
          ...rc[section],
          isFetching: false,
          isRejected: true,
          ...(e.response?.data ? { data: e.response.data } : {}),
        },
      }));
    }
    return rootContext[section];
  };

  useEffect(() => {
    const eventHandler = event => {
      if (event?.data?.message === 'host-height') {
        const { hostHeight } = event.data.value;

        if (hostHeight) {
          setRootContext(rc => ({
            ...rc,
            parentHostHeight: hostHeight,
          }));
        }
      }
    };

    window.addEventListener('message', eventHandler, false);

    return () => {
      window.removeEventListener('message', eventHandler, false);
    };
  }, []);

  return (
    <RootContext.Provider
      value={{
        fetchSectionData,
        getContextSection,
        rootContext,
        setContextSection,
        setRootContext,
        updateContextSection,
      }}
    >
      <div className="context-wrapper" style={{ ...(rootContext.parentHostHeight ? { '--host-height': `${rootContext.parentHostHeight}px` } : {}) }}>
        {children}
      </div>
    </RootContext.Provider>
  );
};

export default AppContextWrapper;
