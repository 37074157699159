import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/{workflowId}/invoices/{id}';
type ThisApi = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateWorkflowInvoice = async ({ data, id, workflowId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/workflows/${workflowId}/invoices/${id}`, { data });
  return response.data;
};

export default updateWorkflowInvoice;