import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/conversations/{conversationSid}/message';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = THIS_API['response'];

const postConversationMessage = async ({ conversationSid, ...body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, {conversationSid});
  const data = await noodleApiClient.post(path, {...body});
  return data.data;
};

export default postConversationMessage;