import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/workflows/steps/{stepId}']['GET'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const getWorkflowStep = async ({ stepId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(buildPath<ThisApi>('/workflows/steps/{stepId}', {
    stepId,
  }));
  return response.data;
};

export default getWorkflowStep;
