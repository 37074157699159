import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import isBoolean from 'lodash/isBoolean';

import useNoodleApi from "@hooks/useNoodleApi";
import useUser from "@providers/Auth/useUser";
import * as tsClient from "@tsClient";
import { logError } from "@providers/ErrorTracking";
import ApiError from "@helpers/ApiError";
import storage from '@helpers/storage';
import MessagesContext, { DismissedMessages, MessagesContextType } from "./MessagesContext";

const DEFAULT_MESSAGES: DismissedMessages = {};
const LOCAL_STORAGE_KEY = 'anonymously-dismissed-messages';

const ignorePaths = [
  '/auth/logout', // when logging out, don't make the request the token will be gone by the time the request starts.
];

const MessagesProvider: React.FC = ({ children }) => {
  const [user] = useUser();
  const [isInitialized, setIsInitialized] = useState(false);
  const [fetchedForUser, setFetchedForUser] = useState<string | null>(null);
  const [localDismissedMessages, setLocalDismissedMessages] = useState(DEFAULT_MESSAGES);
  const router = useRouter();

  const { error: getDismissedMessageError, fetchingState, getData: getDismissedMessagesFn } = useNoodleApi(tsClient.getDismissedMessages);

  const currentPath = router.pathname;
  const userId = user?.id;

  const dismissMessage = async (messageTipKey: string): Promise<void> => {
    const newDismissedMessages = {
      [messageTipKey]: (new Date()).toISOString(),
    };

    setLocalDismissedMessages(prev => ({
      ...prev,
      ...newDismissedMessages,
    }));
    if (userId) {
      try {
        await tsClient.createDismissedMessages(newDismissedMessages);
      } catch(error) {
        logError(ApiError.create(error));
      }
    } else {
      storage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newDismissedMessages));
    }
  };

  useEffect((): void => {
    if (getDismissedMessageError) {
      logError(getDismissedMessageError);
    }
  }, [getDismissedMessageError]);

  const isMessageDismissed: MessagesContextType['isMessageDismissed'] = useCallback((key, { days } = {}) => {
    if (!key) {
      return false;
    }

    const value = localDismissedMessages?.[key];

    if (value === undefined || value === null) {
      return false;
    }

    if (isBoolean(value)) {
      return value;
    }

    if (days) {
      const dateDiffInDays = (new Date().getTime() - new Date(value).getTime()) / (1000 * 3600 * 24);
      return (dateDiffInDays < days);
    }

    return true;
  }, [localDismissedMessages]);

  useEffect((): void => {
    if (userId && fetchedForUser !== userId && !ignorePaths.includes(currentPath)) {
      setFetchedForUser(userId);
      const fetchDismissedMessage = async (): Promise<void> => {
        const { data } = await getDismissedMessagesFn();
        setLocalDismissedMessages(data || DEFAULT_MESSAGES);
        setIsInitialized(true);
      };
      fetchDismissedMessage();
    }
  }, [currentPath, fetchedForUser, userId, getDismissedMessagesFn]);

  useEffect((): void => {
    if (!userId) {
      setFetchedForUser(null);
      storage.getItem(LOCAL_STORAGE_KEY)
        .then(str => str ? JSON.parse(str) as DismissedMessages : DEFAULT_MESSAGES)
        .then(str => {
          setLocalDismissedMessages(str);
        });
    }
  }, [userId]);

  return (
    <MessagesContext.Provider
      value={{
        dismissMessage,
        isFetching: fetchingState.isFetching,
        isInitialized,
        isMessageDismissed,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;
