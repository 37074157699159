import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/products/{productId}/workflows';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getProductWorkflows = async ({ productId, page, perPage}: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { productId });
  const response = await noodleApiClient.get(path, { params: { page, perPage }});
  return response.data;
};

export default getProductWorkflows;