/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/nextjs';
import type { CaptureContext, ScopeContext, SeverityLevel } from '@sentry/types';
import { AnyError } from '@typings/utility';
import defaultsDeep from 'lodash/defaultsDeep';

type Extras = ScopeContext['extra'];
type Tags = ScopeContext['tags'];
type MoreContext = {
  tags?: Tags;
  level?: SeverityLevel;
};
type Options = Extras & MoreContext;

interface LoggableError extends Error {
  extra?: Extras;
}

const makeLoggableError = (thing: unknown): LoggableError => {
  if (thing instanceof Error) {
    return thing as LoggableError;
  }
  if (typeof thing === 'string') {
    return new Error(thing) as LoggableError;
  }

  // @todo - non-Error and non-string throws need more thought.
  // Just make sure they don't die for now.
  const error = new Error('Something went wrong') as LoggableError;
  error.extra = thing as Extras;
  return error;
};

const hasCorrelationId = (err: AnyError): err is { correlationId: string } => Boolean(err?.correlationId);

const shouldLogError = (error: LoggableError): boolean => {
  if (error && (error as unknown as { statusCode: number }).statusCode === 404) {
    return false;
  }
  return true;
};

// export const logError = (err: unknown, extra?: Extras, { tags, level }: MoreContext = {}): void => {
export const logError = (err: unknown, options?: Options ): void => {
  const { tags, level, ...extra } = options || {};
  const loggableError = makeLoggableError(err);
  const captureContext: CaptureContext = {};
  const useExtra: Extras | undefined = (loggableError.extra || extra)
    ? {
      ...(loggableError.extra || {}),
      ...(extra || {}),
    }
    : undefined;
  if (useExtra ) {
    captureContext.extra = useExtra;
  }
  if (tags) {
    captureContext.tags = tags;
  }
  if (level) {
    captureContext.level = level;
  }

  if (hasCorrelationId(err)) {
    if (!captureContext.tags) {
      captureContext.tags = {};
    }
    if (!captureContext.tags.correlationId) {
      captureContext.tags.correlationId = err.correlationId;
    }
  }

  if (shouldLogError(loggableError)) {
    console.warn('logged error', loggableError, JSON.stringify(captureContext)); // eslint-disable-line no-console
    console.warn('logged error stack', loggableError.stack); // eslint-disable-line no-console
  }
  // count on sentry ignoreEvent to suppress sentry.
  Sentry.captureException(loggableError, captureContext);
};

export const logErrorFactory = (defaults: Options): typeof logError => (error, options = {}) => {
  const mergedOptions = defaultsDeep(options, defaults);
  return logError(error, mergedOptions);
};

export type ErrorFlow = 'purchase' | 'onboarding' | 'chat' | 'input-composer' | 'initializing';
export type { SeverityLevel };
