import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/building-blocks/{referenceId}/workflows']['GET'];

type Props = ThisApi['params'] & ThisApi['query'];

const getWorkflowsFromBuildingBlock = async ({ page, perPage, referenceId }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/workflows/building-blocks/${referenceId}/workflows`, {
    params: { page, perPage },
  });
  return response.data;
};

export default getWorkflowsFromBuildingBlock;
