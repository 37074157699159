import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/bookings/{id}/permissions']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getBookingPermissions = async ({ id }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/bookings/${id}/permissions`);
  return response.data;
};

export default getBookingPermissions;
