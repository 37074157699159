import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/external-data-sets/by-id/{id}']['GET'];
type Input = ThisApi['params'];

const getDataReferenceById = async ({ id }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/external-data-sets/by-id/{id}', { id });
  const data = await formsBackendClient.get(path);
  return data.data;
};

export default getDataReferenceById;