import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/templates']['GET'];
type Output = ThisApi['response'];

const getWorkflowTemplates = async (): Promise<Output> => {
  const response = await noodleApiClient.get('/workflows/templates');
  return response.data;
};

export default getWorkflowTemplates;
