import noodleApiClient from '@noodleApi';

type Props = {
  productId: string,
};

const deleteWelcomeVideo = async ({ productId }: Props): Promise<void> => {
  await noodleApiClient.delete(`/products/${productId}/video`);
};

export default deleteWelcomeVideo;