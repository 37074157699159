import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/inbox/unread-count']['GET'];

type Input = ThisApi['params'];

const getCreatorInboxItemsUnreadCount = async ({ creatorId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/inbox/unread-count`);
  return response.data;
};

export default getCreatorInboxItemsUnreadCount;
