import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/products/{productId}';
type THIS_API = ApiDefinition['/products/{productId}']['PUT'];
type Input = THIS_API['params'] & { body: THIS_API['body'] };
type Output = THIS_API['response'];

const updateProductById = async ({ productId, body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { productId });
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default updateProductById;
