import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}/payments']['POST'];
type Input = ThisApi['params'] & ThisApi['body'];

const createInvoicePaymentFromOutsideNoodle = async ({ id, ...body }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/invoices/${id}/payments`, body);
  return response.data;
};

export default createInvoicePaymentFromOutsideNoodle;
