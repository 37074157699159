import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/form-requests/{formRequestId}']['PUT'];

type Input = ThisApi['params'] & ThisApi['body'];
type Output = ThisApi['response'];

const updateFormRequest = async ({ formRequestId, ...body }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/form-requests/{formRequestId}', { formRequestId });
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default updateFormRequest;