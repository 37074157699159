import { useEffect, useState } from "react";
import { GA4_MEASUREMENT_ID } from '@configuration/client';

const useGAClientId = (): string | null => {
  const [gaClientId, setGAClientId] = useState<string | null>(null);

  useEffect(() => {
    if (window.gtag && GA4_MEASUREMENT_ID) {
      window.gtag(
        'get',
        GA4_MEASUREMENT_ID,
        'client_id',
        (clientId) => { setGAClientId(clientId as string); },
      );
    }
  }, []);

  return gaClientId;
};

export default useGAClientId;