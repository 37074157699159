import { createContext, useState, useEffect } from 'react';
import Router from 'next/router';

import ErrorResolver from '@lib/ErrorResolver';
import ApiError from '@helpers/ApiError';

interface IErrorContext {
  error: ApiError | null,
  setError: (e: ApiError | null) => void,
}

export type ErrorGateSSRProps ={
  ssrError: ReturnType<ApiError['toJSON']> | null;
};

const initialContextState = {
  error: null,
  setError: () => undefined,
};

export const ErrorContext = createContext<IErrorContext>({
  ...initialContextState,
});

const ErrorGate: React.FC<ErrorGateSSRProps> = ({ children, ssrError }) => {
  const [errorContext, setErrorContext] = useState<IErrorContext>({
    ...initialContextState,
  });

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      if (errorContext.error) {
        setTimeout(() => {
          setErrorContext(ec => ({ ...ec, error: null }));
        }, 1000);
      }
    });
  }, [errorContext.error]);

  const setError = (error: ApiError | null):void => setErrorContext(prevState => ({ ...prevState, error }));

  const { error } = errorContext;

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
      }}
    >
      <ErrorResolver error={ssrError || error || null}>
        {children}
      </ErrorResolver>
    </ErrorContext.Provider>
  );
};

export default ErrorGate;
