import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/custom-terms/{id}/agree']['POST'];
type Input = ThisApi['params'] & ThisApi['body'];

const agreeToCustomTerms = async ({ id, customerSignatorName, customerSignature }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/custom-terms/${id}/agree`, {
    customerSignatorName,
    customerSignature,
  });
  return response.data;
};

export default agreeToCustomTerms;
