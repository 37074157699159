import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{productId}/availability']['POST'];

type Props = ThisApi['params'] & ThisApi['body'];

const createProductAvailability = async ({ productId, availability, teamMemberPersonId, timezone }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/products/${productId}/availability`, {
    availability,
    teamMemberPersonId,
    timezone,
  });
  return response.data;
};

export default createProductAvailability;
