import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/currency/convert']['GET'];
type Input = THIS_API['query'];
type Output = THIS_API['response'];

const convertCurrency = async ({ amount, from, to }: Input): Promise<Output> => {
  const response = await noodleApiClient.get('/currency/convert', {
    params:{
      amount,
      from,
      to,
    },
  });
  return response.data;
};

export default convertCurrency;
