import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/templates/{id}']['DELETE'];
type Input = ThisApi['params'];

const archiveInvoiceTemplate = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.delete(`/invoices/templates/${id}`);
  return response.data;
};

export default archiveInvoiceTemplate;
