import DOMPurify from 'isomorphic-dompurify';
import React from "react";
import { LINKED_IN_PARTNER_ID } from '@configuration/client';

// This needs to be included in _document.tsx, not _app.tsx
const LinkedInInsights: React.FC = () => {
  if (!LINKED_IN_PARTNER_ID) {
    return null;
  }
  return (
    <>
      <script
        id='linked-in-insights-loader-prep'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push('${LINKED_IN_PARTNER_ID}');
  `),
        }}
      />
      <script
        id='linked-in-insights-loader'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
(function(l) {
  if (!l) {
    window.lintrk = function(a,b){
      window.lintrk.q.push([a,b])
    };
    window.lintrk.q=[]
  }
  var s = document.getElementsByTagName('script')[0];
  var b = document.createElement('script');
  b.type = 'text/javascript';
  b.async = true;
  b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
  s.parentNode.insertBefore(b, s);
})(window.lintrk);
    `),
        }}
      />
      <noscript id='linked-in-insights-noscript'>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          height='1'
          width='1'
          style={{ display: 'none' }}
          alt=""
          src={`https://px.ads.linkedin.com/collect/?pid=${LINKED_IN_PARTNER_ID}&fmt=gif`}
        />
      </noscript>
    </>
  );
};

export default LinkedInInsights;
