import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/calendar-accounts']['GET'];
type Input = ThisApi['params'] & ThisApi['query'];
type Output = ThisApi['response'];

const getCalendarAccounts = async ({ creatorId, personId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/calendar-accounts`, {
    params: {
      personId,
    },
  });
  return response.data;
};

export default getCalendarAccounts;
