import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/subscriptions/{subscriptionId}/canceled-at';
type THIS_API = ApiDefinition['/subscriptions/{subscriptionId}/canceled-at']['POST'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const cancelNonStripeSubscrtiption = async ({ subscriptionId }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { subscriptionId });
  const response = await noodleApiClient.post(path);
  return response.data;
};

export default cancelNonStripeSubscrtiption;
