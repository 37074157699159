import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { IP_INFO_TOKEN } from '@configuration/client';
import { logError } from '@providers/ErrorTracking';

type Geolocation = {
  ip: string;
  country?: {
    capital: string;
    currency: string;
    flag: string;
    ip: string;
    iso_code: string;
    languages: {
      iso_code: string;
      name: string;
      name_native: string;
    }[];
    name_native: string;
    name: string;
    phone_code: string;
  };
  continent: {
    code: string;
  }
};

let promiseForData: Promise<AxiosResponse<Geolocation>> | undefined;

const useIPGeolocation = (): [Geolocation | null, boolean] => {
  const [geolocation, setGeolocation] = useState<Geolocation | null>(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        if (!promiseForData) {
          promiseForData = axios.get<Geolocation>(`https://api.geoapify.com/v1/ipinfo?apiKey=${IP_INFO_TOKEN}`);
        }
        const { data } = await promiseForData;
        setGeolocation(data);
      } catch (e) {
        logError(e);
      }
      setIsFetching(false);
    };
    getData();
  }, []);

  return [geolocation, isFetching];
};

export default useIPGeolocation;
