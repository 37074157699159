import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/permissions']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateDocumentRequestUserPermissions = async ({ id, userIds }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.put(`/document-request-users/${id}/permissions`, { userIds });
  return response.data;
};

export default updateDocumentRequestUserPermissions;