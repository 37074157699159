import { AxiosRequestConfig } from 'axios';
import noodleMediaClient, { ApiDefinition } from '@mediaApi';

type THIS_API = ApiDefinition['/files']['POST'];
type Input = {
  config: AxiosRequestConfig;
  fileSize: number;
  referenceId: string;
  referenceType: string;
  data: THIS_API['body'],
};
type Output = THIS_API['response'];

const uploadFile = async ({ data, fileSize, referenceId, referenceType, config}: Input ): Promise<Output> => {
  const response = await noodleMediaClient.post('/files', data, {
    ...config,
    params: {
      fileSize,
      referenceId,
      referenceType,
      ...(config.params || {}),
    },
  });
  return response.data;
};

export default uploadFile;
