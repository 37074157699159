import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

const PATH_TEMPLATE = '/creators/{creatorId}/workflows/{workflowSlug}/versions';
type ThisApi = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Props = ThisApi['params'];

const getWorkflowVersions = async ({ creatorId, workflowSlug }: Props): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>(PATH_TEMPLATE, { creatorId, workflowSlug });
  const response = await noodleApiClient.get(path);
  return response.data;
};

export default getWorkflowVersions;
