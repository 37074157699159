// Javascript versions of the colors
// These should match the ones in _colors.scss, if changing this file update _colors.scss too
enum COLORS {
  primary = '#010101',
  success = '#1CAB47',
  // warning = '#D3820B',
  attention = '#D3820B',
  error = '#cc1426',
  'gray-100' = '#010101', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  'gray-75' = '#666666',
  'gray-50' = '#aaaaaa',
  'gray-25' = '#ebebeb',
  'gray-0' = '#ffffff',
}

export type COLOR_KEYS = keyof typeof COLORS;
export default COLORS;
