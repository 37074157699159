import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/persons/{id}']['PUT'];
type Input = {
  personId: string;
} & ApiDefinition['/persons/{id}']['PUT']['body'];

const updatePerson = async ({ personId, ...body }: Input ): Promise<Omit<THIS_API['response'], 'data'>> => {
  const response = await noodleApiClient.put<THIS_API['response']>(`/persons/${personId}`, body);
  const person = response.data;
  return person;
};

export default updatePerson;
