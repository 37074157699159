import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/files']['GET'];
type Input = ThisApi['params'] & ThisApi['query'];

const getDocumentRequestUserFiles = async ({ id, accessToken, perPage }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.get(`/document-request-users/${id}/files`, {
    params: { accessToken, perPage },
  });
  return response.data;
};

export default getDocumentRequestUserFiles;
