import { Fragment } from 'react';
import Head from 'next/head';
import Spacer from '@components/Spacer';

const EmptyLayout: React.FC = ({ children }) => (
  <Fragment>
    <Head>
      <link rel="icon" href="/favicon-32x32.png" />
    </Head>
    {children}
    <Spacer size={80} />
  </Fragment>
);

export default EmptyLayout;
