import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/string-to-slug']['GET'];
type Input = THIS_API['query'];
type Output = THIS_API['response'];

const getSlugAndAvailability = async ({ text, gaClientId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get('/creators/string-to-slug', { params: { gaClientId, text } });
  return response.data;
};

export default getSlugAndAvailability;
