import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/content-library/{creatorSlug}/hero-links';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = THIS_API['params'] & { body: THIS_API['body'] };
type Output = THIS_API['response'];

const updateCreatorHeroLinks = async ({ creatorSlug, body }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorSlug });
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default updateCreatorHeroLinks;
