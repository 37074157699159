import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}/{questionnaireTemplateId}']['GET'];
type Input = ThisApi['params'];

const getQuestionnaireTemplate = async ({ creatorId, questionnaireTemplateId }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaire-templates/{creatorId}/{questionnaireTemplateId}', { creatorId, questionnaireTemplateId });
  const data = await formsBackendClient.get(path);
  return data.data;
};

export default getQuestionnaireTemplate;
