import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}/line-items']['POST'];
type Input = ThisApi['params'] & ThisApi['body'];

const setInvoiceLineItems = async ({ id, lineItems }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/invoices/${id}/line-items`, { lineItems });
  return response.data;
};

export default setInvoiceLineItems;
