import { createContext } from 'react';
import { getTeams } from '@tsClient/my';
import * as ApiModels from '@typings/api-models';
import { Team, TeamOwner } from './constants';

type TeamsContextType = {
  hasFetchedTeams: boolean;
  setCurrentTeam: (newTeam: Team | null) => void;
  creatorId: string | null;
  currentTeam: Team | null;
  currentTeamOwner: TeamOwner | null;
  refetchTeams: () => Promise<Awaited<ReturnType<typeof getTeams>>['items']>,
  setTeamUserId: (id: string) => void;
  teams: Team[];
  teamUserId: string | null;
  role:Omit<ApiModels.TeamMemberRoles, 'id' | 'teamMemberId' > ;
};

const TeamsContext = createContext<TeamsContextType>({
  creatorId: null,
  currentTeam: null,
  currentTeamOwner: null,
  hasFetchedTeams: false,
  refetchTeams: async () => [],
  role:{
    admin: false,
    finance: false,
    manageTeam: false,
    role: "caseWorker",
  },
  setCurrentTeam: () => undefined,
  setTeamUserId: () => undefined,
  teamUserId: null,
  teams: [],
});

export default TeamsContext;
