import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/files/{fileId}/reject']['POST'];
type Input = ThisApi['params'] & ThisApi['query'] & ThisApi['body'];

const rejectUploadedFile = async ({ accessToken, id, fileId, rejectionMessage }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.post(`/document-request-users/${id}/files/${fileId}/reject`, { rejectionMessage }, {
    params: { accessToken },
  });
  return response.data;
};

export default rejectUploadedFile;
