import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/conversation-list']['GET'];

type Input = ThisApi['query'] & ThisApi['params'];
type Output = ThisApi['response'];

const getCreatorConversations = async ({ page, perPage, filter, search, creatorId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/conversation-list`, {
    params: {
      filter,
      page,
      perPage,
      search,
    },
  });
  return response.data;
};

export default getCreatorConversations;
