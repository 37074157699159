import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/notifications/{deviceId}']['DELETE'];
type Input = ThisApi['params'];
type Output = ThisApi['response'];

const deregisterPushNotification = async ({ deviceId }: Input): Promise<Output> => {
  const data = await noodleApiClient.delete(`/notifications/${deviceId}`);
  return data.data;
};

export default deregisterPushNotification;
