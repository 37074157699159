import { nanoid } from "nanoid";

let canUseLocalStorage: boolean | undefined;

const buildKey = (prefix: string, key: string): string => `${prefix}::${key}`;

const testCanUseLocalStorage = (): boolean => {
  if (canUseLocalStorage === undefined) {
    try {
      const key = nanoid();
      window.localStorage.setItem(key, key);
      window.localStorage.removeItem(key);
      canUseLocalStorage = true;
    } catch (_error) {
      canUseLocalStorage = false;
    }
  }
  return canUseLocalStorage;
};

const set = (prefix: string, key: string, value: string): void => {
  testCanUseLocalStorage();
  if (canUseLocalStorage) {
    window.localStorage.setItem(buildKey(prefix, key), value);
  }
};

const get = (prefix: string, key: string): string | null => {
  testCanUseLocalStorage();
  if (canUseLocalStorage) {
    return window.localStorage.getItem(buildKey(prefix, key));
  }
  return null;
};

const remove = (prefix: string, key: string): void => {
  testCanUseLocalStorage();
  if (canUseLocalStorage) {
    window.localStorage.removeItem(buildKey(prefix, key));
  }
};

const clear = (...prefixes: string[]): void => {
  testCanUseLocalStorage();
  if (canUseLocalStorage) {
    for (let i = 0; i < window.localStorage.length; i += 1) {
      const key = window.localStorage.key(i);
      if (key) {
        for (let j = 0; j < prefixes.length; j += 1) {
          const prefix = prefixes[j];
          if (key.startsWith(buildKey(prefix, ''))) {
            window.localStorage.removeItem(key);
          }
        }
      }
    }
  }
};

export default { clear, get, remove, set };
