import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}/payment-plans']['POST'];
type Input = ThisApi['body'] & ThisApi['params'];

const createPaymentPlan = async ({ id, ...body }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/invoices/${id}/payment-plans`, body);
  return response.data;
};

export default createPaymentPlan;
