import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/messages/noodle-broadcasts/update/{id}']['PUT'];
type Input = ThisApi['body'];

const updateNoodleBroadcasts = async (body: Input ): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/messages/noodle-broadcasts/update/${body.messageId}`, body);
  return response.data;
};

export default updateNoodleBroadcasts;
