import documentApiClient, { ApiDefinition } from '@documentsApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/documents/{documentId}']['GET'];
type Input = ThisApi['query'] & ThisApi['params'];

const getDocumentById = async ({ documentId, accessToken }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/documents/{documentId}', { documentId });

  const response = await documentApiClient.get(path, {
    params: { accessToken },
  });
  return response.data;
};

export default getDocumentById;