import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/is-team-member']['GET'];
type Input = THIS_API['query'] & THIS_API['params'];
type Output = THIS_API['response'];

const isTeamMember = async ({ creatorId, email, phone }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/is-team-member`, { params: { email, phone } });
  return response.data;
};

export default isTeamMember;
