import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '../utilities/buildPath';

type THIS_API = ApiDefinition['/my/creators/{creatorId}/payment-methods/{paymentMethodId}']['DELETE'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const deletePaymentMethod = async ({ creatorId, paymentMethodId }: Input): Promise<Output> => {
  const path = buildPath('/my/creators/{creatorId}/payment-methods/{paymentMethodId}', { creatorId, paymentMethodId });
  const response = await noodleApiClient.delete(path);
  return response.data;
};

export default deletePaymentMethod;
