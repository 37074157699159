import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';
import { MediaStatus } from '@typings/graphql-models';

const PATH_TEMPLATE = 'medias/{mediaId}';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const deleteFile = async ({ mediaId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { mediaId });
  const response = await noodleApiClient.put(path, { mediaStatus: MediaStatus.Removed });
  return response.data;
};

export default deleteFile;
