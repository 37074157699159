import { useEffect, EffectCallback } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import Script from 'next/script';
import { FB_PIXEL_ID } from '@configuration/client';
import isInIframe from '@helpers/isInIframe';

import { useRouter } from 'next/router';
import * as fbq from './index';

const FacebookPixelProvider: React.FC = () => {
  const router = useRouter();

  useEffect((): void => {
    fbq.init(FB_PIXEL_ID);
  }, []);

  useEffect((): ReturnType<EffectCallback> => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageView();

    const handleRouteChange = (): void => {
      fbq.pageView();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  if (!FB_PIXEL_ID || isInIframe()) {
    return null;
  }

  return (
    <Script
      id="fb-pixel"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
`),
      }}
    />
  );
};

export const FacebookPixelNoScript: React.FC = () => (
  FB_PIXEL_ID && !isInIframe()
    ? (
      // eslint-disable-next-line
      <img
        alt={""}
        height="1"
        width="1"
        style={{ display: 'none' }}
        src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
      />
    )
    : null
);

export default FacebookPixelProvider;
