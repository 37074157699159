import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/conversations/{conversationSid}/ai';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = void;

const setAIEnabledOnConversation = async ({ conversationSid, isEnabled }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { conversationSid });
  const response = await noodleApiClient.put(path, { isEnabled });
  return response.data;
};

export default setAIEnabledOnConversation;
