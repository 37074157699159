import * as ApiModels from '@typings/api-models';
import noodleApiClient from '@noodleApi';

type CheckoutProfile = Pick<ApiModels.Person, 'id' | 'email' | 'name' | 'phoneNumber'>;

const getCheckoutProfile = async (): Promise<CheckoutProfile> => {
  const data = await noodleApiClient.get('/persons/checkout-profile');
  return data.data.profile;
};

export default getCheckoutProfile;
