import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from './utilities/buildPath';

const pathTemplate = '/creators/{creatorSlug}/example-chat-messages';
type ThisApi = ApiDefinition['/creators/{creatorSlug}/example-chat-messages']['GET'];
type Input = ThisApi['params'] & ThisApi['query'];
type Output = ThisApi['response'];

const getCreatorExampleChatMessages = async ({ creatorSlug, perPage, page }: Input): Promise<Output> => {
  const path = buildPath(pathTemplate, { creatorSlug });
  const response = await noodleApiClient.get(path, {
    params: {
      page,
      perPage,
    },
  });
  return response.data;
};

export default getCreatorExampleChatMessages;
