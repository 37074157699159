import React, {ReactElement} from 'react';
import classNames from 'classnames';
import Shimmer from 'components/Shimmer';
import s from './MediaLoader.module.scss';

const MediaLoader = ({
  message,
  subMessage,
  rounded = false,
  className,
  isProcessMessage,
  isSmall = false,
  style,
} : {
  message?: string,
  subMessage?: string,
  rounded?: boolean,
  className?: string,
  isProcessMessage?: boolean,
  isSmall?: boolean,
  style?: React.CSSProperties,
}) : ReactElement => {
  const containerClasses = classNames(s['media-loader__container'], className);
  return (
    <div className={classNames(s['media-loader'])}>
      <div className={containerClasses} style={style}>
        <Shimmer
          isProcessMessage={isProcessMessage}
          message={message}
          subMessage={subMessage}
          rounded={rounded}
          isSmall={isSmall}
        />
      </div>
    </div>
  );
};

export default MediaLoader;
