import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/workflows/{workflowId}/owners']['PUT'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const updateWorkflowOwners = async ({ personIds, workflowId }: Input): Promise<Output> => {
  const response = await noodleApiClient.put(`/workflows/${workflowId}/owners`, { personIds });
  return response.data;
};

export default updateWorkflowOwners;
