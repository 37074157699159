import { useEffect, FC } from 'react';
import NoodleIcon from '@components/Icons/Noodle';
import Link from 'next/link';
import { Inter } from 'next/font/google';
import { LinkedinLogo } from '@phosphor-icons/react';
import { getUrl, IDENTIFIERS } from '@/helpers/urlsHelper';
import Button from '@/components/DesignLibrary/Button';
import s from './LandingLayout.module.scss';

const font = Inter({
  display: 'swap',
  subsets: ['latin'],
  weight: ['700'],
});

const LandingLayout: FC = ({ children }) => {
  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#f7fafd';

    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  return (
    <>
      <nav className={s.nav}>
        <div className={s.content}>
          <Link className={s.logo} href={getUrl(IDENTIFIERS.HOME)}>
            <NoodleIcon width={24} height={24} fill="var(--color-noodle)" />
            <span style={font.style}>noodle</span>
          </Link>
          <Button href="https://noodle.shop/platform" size="sm" variant="secondary" className={s.platform}>
            Platform
          </Button>
          <div>
            <Button href={getUrl(IDENTIFIERS.CREATOR_SIGN_IN)} size="sm" variant="secondary" className={s.login}>
              Log In
            </Button>
            <Button href="https://noodle.shop/attorneys/meet" size="sm" variant="primary" className={s.more}>
              Learn More
            </Button>
          </div>
        </div>
      </nav>
      {children}
      <footer className={s.footer}>
        <div className={s.content}>
          <NoodleIcon width={24} height={24} fill="var(--color-noodle)" />
          <p className="body-md-bold">© 2024 Noodle Shops Inc</p>
          <div className={s.links}>
            <Link href="https://noodle.shop/privacy-policy">Privacy Policy</Link>
            <Link href="https://noodle.shop/terms-and-conditions">Terms and Conditions</Link>
            <Link href="https://blog.noodle.shop/?_gl=1*cr5gf2*_gcl_au*MTQ3NDcwNjkwNC4xNzE4MDExMjY0*_ga*MTY5MjYyMjk0LjE3MTE3MjUxMjI.*_ga_G1J6Q0F9Z0*MTcyMTY1Mzg1OC4yMDUuMC4xNzIxNjUzODU4LjYwLjAuMA..">
              Blog
            </Link>
            <Link href="https://noodle.shop/contact">Contact</Link>
            <Link href="https://noodle.shop/creator-success/noodle-subscription">Pricing</Link>
            <Link href="https://www.linkedin.com/company/noodleshops" aria-label="Linkedin">
              <LinkedinLogo width={20} height={20} fill="rgb(50, 117, 176)" weight="fill" />
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingLayout;
