import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/uscis-case-status']['GET'];

const getUscisCaseStatus = async ({ userWorkflowId }: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/workflows/user-workflows/${userWorkflowId}/uscis-case-status`);
  return response.data;
};

export default getUscisCaseStatus;
