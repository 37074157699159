import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/sockets/token']['GET'];
type Output = THIS_API['response'];

const getSocketToken = async ({ creatorId }: { creatorId?: string | null }): Promise<Output> => {
  const response = await noodleApiClient.get('/sockets/token', { params: { ...creatorId ? { creatorId } : {} } });
  return response.data;
};

export default getSocketToken;
