import { logError } from '@providers/ErrorTracking';

const attemptPageReload = (page: string): ReturnType<typeof setTimeout> | null => {
  if (typeof document === 'undefined') {
    return null;
  }
  const limit = 4;

  const parsedUrl = new URL(window.location.href);

  let reloadAttempt = parseInt(parsedUrl.searchParams.get('reloadAttempt') ?? '0', 10);
  if (Number.isNaN(reloadAttempt)) {
    reloadAttempt = 0;
  }

  if (reloadAttempt >= limit) {
    logError(new Error('attemptPageReload limit reached'), { limit, page, url: window.location.href });
    return null;
  }

  return setTimeout(() => {
    parsedUrl.searchParams.set('reloadAttempt', (reloadAttempt + 1).toFixed(0));
    window.location.href = parsedUrl.toString();
  }, 200);
};

export default attemptPageReload;