const serialize = (item: number): string => item.toString();

const deserialize = (str: string): number => {
  const parsed = parseFloat(str);
  if (typeof parsed !== 'number' || Number.isNaN(parsed)) {
    throw new Error(`Failed to deserialize "${str}" to a number`);
  }
  return parsed;
};

export default { deserialize, serialize };