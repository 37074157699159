import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{creatorSlug}/{productSlug}/handbook']['GET'];

type Props = ThisApi['params'];

const getHandbook = async ({ productSlug, creatorSlug }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/products/${creatorSlug}/${productSlug}/handbook`);
  return response.data;
};

export default getHandbook;
