import buildPath from '@tsClient/utilities/buildPath';
import noodleDocumentsClient, { ApiDefinition } from '@documentsApi';

type THIS_API = ApiDefinition['/auth/creators/{creatorId}/document-access-password/is-valid']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const hasDocumentAccessPassword = async ({ creatorId }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>('/auth/creators/{creatorId}/document-access-password/is-valid', { creatorId });
  const response = await noodleDocumentsClient.get(path);
  return response.data;
};

export default hasDocumentAccessPassword;
