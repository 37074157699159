import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/form-requests/{formRequestId}/permissions']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateFormRequestPermissions = async ({ formRequestId, userIds }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/form-requests/${formRequestId}/permissions`, { userIds });
  return response.data;
};

export default updateFormRequestPermissions;