import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/custom-terms/{id}/permissions']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateCustomTermsPermissions = async ({ id, userIds }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/custom-terms/${id}/permissions`, { userIds });
  return response.data;
};

export default updateCustomTermsPermissions;
