import { LanguageCode, SUPPORTED_LANGUAGE_CODES } from './constants';

const validateLanguageCode = (str: string | LanguageCode | null | undefined): LanguageCode | null => {
  if (!str) {
    return null;
  }

  if (SUPPORTED_LANGUAGE_CODES.includes(str as LanguageCode)) {
    return str as LanguageCode;
  }

  return null;
};

export default validateLanguageCode;