import { Fragment } from 'react';

import Page401 from '@lib/Page401';
import Page404 from '@lib/Page404';
import ErrorsPage from '@lib/ErrorsPage';

type ErrorsPageProps = Parameters<typeof ErrorsPage>[0];
interface ErrorContextProvideProps {
  error: Pick<ErrorsPageProps, 'message' | 'statusCode' | 'data'> | null;
}

const ErrorResolver: React.FC<ErrorContextProvideProps> = ({ error, children }) => {
  if (error) {
    if (error.statusCode === 401) {
      return <Page401 />;
    }
    if (error.statusCode === 404) {
      return <Page404 />;
    }

    return (
      <ErrorsPage
        data={error.data}
        message={error.message}
        statusCode={error.statusCode}
      />
    );
  }
  return <Fragment>{children}</Fragment>;
};

export default ErrorResolver;
