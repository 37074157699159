import { QueryStringItem } from './typings';

const getArray = (item: QueryStringItem): (string | boolean)[] => {
  if (item === null || item === undefined) {
    return [];
  }

  if (Array.isArray(item)) {
    return item;
  }
  return [item];
};

export default getArray;
