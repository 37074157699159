import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}/user-workflows']['POST'];
type Input = ThisApi['params'] & ThisApi['body'];
type Output = ThisApi['response'];

const initiateWorkflowAsCreator = async ({
  associatedUserWorkflowId,
  workflowId,
  customerId,
  contexts,
  invoices,
  ownerIds,
}: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/workflows/${workflowId}/user-workflows`, { associatedUserWorkflowId, contexts, customerId, invoices, ownerIds });
  return response.data;
};

export default initiateWorkflowAsCreator;
