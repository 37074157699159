import noodleApiClient from '@noodleApi';
import * as Types from "@typings/graphql-models";

type UpdateSubscriptionProps = {
  id: Types.Scalars['ID'];
  updates: unknown
};

const updateSubscriptionModel = async ({id, updates}: UpdateSubscriptionProps): Promise<void> => {
  const response = await noodleApiClient.put(`/subscription-models/${id}`, {data: updates});
  return response.data;
};

export default updateSubscriptionModel;
