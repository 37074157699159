import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports-b2c/{creditReportId}/verify-user-identity']['POST'];

type Input = {
  creditReportId: string;
  email: string;
  userInfo: ThisApi['body']['userInfo'];
};

type Output = ThisApi['response'];

const verifyUserIdentity = async ({ creditReportId, email, userInfo }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/credit-reports-b2c/${creditReportId}/verify-user-identity`, { email, userInfo });

  return response.data;
};

export default verifyUserIdentity;
