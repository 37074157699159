import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/conversations/{conversationId}';
type THIS_API = ApiDefinition['/conversations/{conversationId}']['PUT'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = THIS_API['response'];

const updateConversation = async ({ conversationId, ...body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { conversationId });
  const response = await noodleApiClient.put(path, body);
  return response.data;
};

export default updateConversation;
