import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}/steps/{stepId}']['PUT'];

const updateWorkflowStep = async ({ id, stepId, updates }: ThisApi['params'] & { updates: ThisApi['body'] }): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/workflows/${id}/steps/${stepId}`, {
    ...updates,
  });
  return response.data;
};

export default updateWorkflowStep;