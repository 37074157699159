import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisRequest = ApiDefinition['/ai/price-title']['POST'];
type Input = ThisRequest['body'];
type Output = ThisRequest['response'];

const generatePriceTitle = async (payload: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/ai/price-title', payload);
  return response.data;
};

export default generatePriceTitle;