import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/associated-user-workflows']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getAssociatedUserWorkflows = async ({ userWorkflowId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/workflows/user-workflows/${userWorkflowId}/associated-user-workflows`);
  return response.data;
};

export default getAssociatedUserWorkflows;
