import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/files']['POST'];
type Input = ThisApi['params'] & ThisApi['body'];

const createDocumentRequestUserFile = async ({ id, isRequired, isForYourEyesOnly, title, description }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.post(`/document-request-users/${id}/files`, {
    description,
    isForYourEyesOnly,
    isRequired,
    title,
  });
  return response.data;
};

export default createDocumentRequestUserFile;
