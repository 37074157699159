import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorId}';
type THIS_API = ApiDefinition['/creators/{creatorId}']['PUT'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = THIS_API['response'];

const updateCreator = async ({ creatorId, ...payload }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId });
  const response = await noodleApiClient.put(path, payload);
  return response.data;
};

export default updateCreator;
