import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-requests/{id}']['GET'];
type Input = ThisApi['params'];

const getDocumentRequestById = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.get(`/document-requests/${id}`);
  return response.data;
};

export default getDocumentRequestById;
