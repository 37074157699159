import zod from 'zod';
import { serializers } from '@providers/LocalStorageState';

const OverridesSchema = zod.record(zod.string(), zod.boolean());

const localStorageStateSerializer = {
  deserialize: serializers.json.deserializeFactory(OverridesSchema),
  serialize: serializers.json.serialize,
};

export default localStorageStateSerializer;