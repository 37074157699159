import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/payouts']['GET'];

type GetPayoutsResponse = ThisApi['response'];

const getPayouts = async ({ creatorSlug, ...restProps }: ThisApi['params'] & ThisApi['query']): Promise<GetPayoutsResponse> => {
  const response = await noodleApiClient.get(`/creators/${creatorSlug}/payouts`, { params: { ...restProps }});
  return response.data;
};

export default getPayouts;