import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/carts/create-payment-intent']['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const createPaymentIntent = async ({
  accountId,
  customTermsId,
  discountCodeId,
  paymentMethodId,
  personId,
  prices,
  sessionDuration,
  sessionTime,
  gaClientId,
  localCurrency,
  teamMemberPersonId,
  utmParams,
  workflowReferenceId,
}: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/carts/create-payment-intent`, {
    accountId,
    customTermsId,
    discountCodeId,
    gaClientId,
    localCurrency,
    paymentMethodId,
    personId,
    prices,
    sessionDuration,
    sessionTime,
    teamMemberPersonId,
    utmParams,
    workflowReferenceId,
  });
  return response.data;
};

export default createPaymentIntent;
