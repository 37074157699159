import isBrowser from "@helpers/isBrowser";
import { JWT_COOKIE_NAME } from "@configuration/client";
import createCookie from "./createCookie";

const createJWTCookie = (token: string): void => {
  if (isBrowser()) {
    window.httpToken = token;
    createCookie(JWT_COOKIE_NAME, token, 30);
  }
};

export default createJWTCookie;
