import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/integrations';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getUserWorkflowIntegrations = async ({ userWorkflowId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/workflows/user-workflows/${userWorkflowId}/integrations`);
  return response.data;
};

export default getUserWorkflowIntegrations;
