import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/stripe/setup-intents']['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const createSetupIntent = async ({ personId, creatorId }: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/stripe/setup-intents', { creatorId, personId });
  return response.data;
};

export default createSetupIntent;
