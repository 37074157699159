import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/notifications/subscribe']['POST'];
type Input = ThisApi['body'];
type Output = ThisApi['response'];

const registerPushNotification = async ({ subscription }: Input): Promise<Output> => {
  const data = await noodleApiClient.post('/notifications/subscribe', { subscription });
  return data.data;
};

export default registerPushNotification;
