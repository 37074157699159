import { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import HistoryContext from '@providers/History/Context';

type Props = { children: JSX.Element | JSX.Element[]; };

const HistoryProvider = ({ children }: Props): ReactElement => {
  const router = useRouter();
  const [history, setHistory] = useState<string[]>([]);
  const { asPath, isReady } = router;
  useEffect(() => {
    if (isReady) {
      setHistory(prev => [...prev, asPath]);
    }
  }, [isReady, asPath]);
  return (
    <HistoryContext.Provider value={{
      history,
    }}>
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryProvider;
