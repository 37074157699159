import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/permissions']['GET'];
type Input = ThisApi['params'];

const getDocumentRequestUserPermissions = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.get(`/document-request-users/${id}/permissions`);
  return response.data;
};

export default getDocumentRequestUserPermissions;