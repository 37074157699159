/*
 * The intent to this file is to hold models that need to be shared between noodle-api and clients (noodle-frontent).
 * A copy of this file should exist on every client that interacts with noodle-api.
 *
 * There should not be any associations in these definitions because it is hard to guarantee that all of the properties exist on the associated model.
 * The consumer of this file should do something like Pick<ThisType, 'id'> & { associatedModel: Pick<AssociatedModel, 'id'> }
 * NOT Pick<ThisType, 'id' | 'associatedModel'> because this would say that the whole AssociatedModel is attached.
 *
 * Any scalars that need to be different between the client and the server should be taken care of by the respective Scalars files.
 *
 * DateTime
 *   on noodle-api datetime fields are Date objects.
 *   on clients, these will be strings.
 *
 */
import { z } from 'zod';
import * as GraphQLModels from '@typings/graphql-models';
import * as DocumentApiModels from '@typings/document-api-models';
import StripeModels from '@typings/Stripe';
import { Roles, SlateNode } from '@typings/common';
import { NoOptionalProperties } from '@typings/utility';
import { Descendant } from 'slate';

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Json: Record<string, unknown>;
  Hex: string;
};

export type RichText = {
  html: string;
  text: string;
};

export type Asset = {
  fileName: string;
  id: string;
  mimeType?: string | null;
  url: string;
  size?: number | null;
};

export type Color = {
  hex: Scalars['Hex'];
};

export const { Currency } = GraphQLModels;
export type Currency = GraphQLModels.Currency;

export type CurrencyType = 'USD' | 'CAD' | 'EUR' | 'GBP';
export type CurrencyValue = {
  currency: CurrencyType;
  amount: number;
};

export type IntlAddress = {
  city: string;
  country: string;
  line1: string;
  line2?: string;
  postCode: string;
};

export type USAddress = {
  city: string;
  state: string;
  line1: string;
  line2?: string;
  zip: string;
};

export type Name = {
  firstName: string;
  middleName?: string;
  lastName: string;
};

export type MedianIncome = {
  medianDifference?: number | null;
  currentMonthlyIncome?: number | null;
  currentAnnualizedIncome?: number | null;
  medianHouseholdIncome?: number | null;
  deductions?: number | null;
  isOverMedian?: boolean | null;
  name?: Name | null;
  address?: USAddress | null ;
  householdSize?: number | null;
  dateOfFiling?: Date | null;
};

export type AvailabilityBlock = {
  id: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  creatorTimezone: string;
  status: string;
  creatorId: string;
  productId: string;
};

export type AvailabilityGroup = {
  id: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  numDays: number;
  days: number[];
  creatorId: string;
  productId: string;
};

export enum StripeAccountType {
  Connect = 'Connect',
  Express = 'Express',
  NoodleMor = 'NoodleMOR'
}

export enum CustomTermsDynamicVariableType {
  Info = 'info',
  Signature = 'signature',
}

export enum AddressUnitType {
  Apartment = 'APT',
  Floor = 'Floor',
  Suite = 'Suite',
}

export enum BureauType {
  Equifax = 'EQUIFAX',
  TransUnion = 'TRANSUNION',
  Experian = 'EXPERIAN',
  B3 = '3b',
  B1 = '1b',
}

export enum BureauService {
  Bankruptcy = 'bankruptcy',
  BankruptcyVantage4 = 'bankruptcy-vantage4',
  PrequalVantage4 = 'prequal-vantage4',
}

export type Account = {
  creatorId: string;
  email: string;
  id: string;
  password: string | null;
  provider: string;
};

export type TeamRole = 'admin' | 'finance' | 'caseWorker';

export type TeamMemberRoles = {
  id: string;
  teamMemberId: string;
  admin: boolean;
  finance: boolean;
  manageTeam: boolean;
  role: TeamRole;
};

export type Creator = NoOptionalProperties<Pick<GraphQLModels.Creator,
  | 'businessCategory'
  | 'confidoFirmId'
  | 'confidoStatus'
  | 'contactPhoneNumber'
  | 'countryCode'
  | 'creatorDomain'
  | 'creatorSupportEmail'
  | 'defaultCurrency'
  | 'facebookFollowers'
  | 'facebookUrl'
  | 'followupCadence'
  | 'followupCadenceUnits'
  | 'hasFinishedOnboarding'
  | 'id'
  | 'instagramFollowers'
  | 'instagramUrl'
  | 'isAiEnabled'
  | 'isBuyEnabled'
  | 'lastViewedDashboardAt'
  | 'name'
  | 'noodlePhoneNumber'
  | 'notifyOnEveryCustomerMessage'
  | 'oneLiner'
  | 'personId'
  | 'routableCompanyId'
  | 'slug'
  | 'stripeAccountType'
  | 'stripe_account_id'
  | 'successPaymentRedirectUrl'
  | 'tikTokUrl'
  | 'tiktokFollowers'
  | 'timezone'
  | 'twitterFollowers'
  | 'twitterUrl'
  | 'utmCampaignId'
  | 'utmContent'
  | 'utmSource'
  | 'webUrl'
  | 'youtubeFollowers'
  | 'youtubeUrl'
>> & {
  defaultCurrency?: Currency | null;
  hasSetUpAgency: boolean;
  isAttorney: boolean;
  lastSeen?: Scalars['DateTime'];
  logoForBranding?: Pick<Asset, 'url'> | null;
  needsPaymentMethod: boolean;
  nextBillingDate: string | null;
  numLeads: number;
  numRecentBroadcasts: number;
  paymentProvider: 'stripe' | 'confido' | 'none';
  usesConfido: boolean;
  usesStripe: boolean;
};

export type ConfidoStatus = 'CREATED' | 'APP_IN_DRAFT' | 'APP_SUBMITTED' | 'APP_IN_REVIEW' | 'ACTIVE' | 'INACTIVE';
export type ConfidoPaymentMethod = 'CREDIT' | 'DEBIT' | 'ACH';

export type CreatorSocialMedia = {
  githubAvatarUrl: Scalars['String'] | null;
  githubEmail: Scalars['String'] | null;
  githubId: Scalars['String'] | null;
  githubName: Scalars['String'] | null;
  githubUsername: Scalars['String'] | null;
  id: Scalars['ID'];
  instagramBio: Scalars['String'] | null;
  instagramFollowerCount: Scalars['Int'] | null;
  instagramFullName: Scalars['String'] | null;
  instagramId: Scalars['String'] | null;
  instagramProfilePhotoUrl: Scalars['String'] | null;
  instagramUsername: Scalars['String'] | null;
  linkedinId: Scalars['String'] | null;
  linkedinName: Scalars['String'] | null;
  tiktokFollowerCount: Scalars['Int'] | null;
  tiktokUsername: Scalars['String'] | null;
  twitterFollowersCount: Scalars['Int'] | null;
  twitterId: Scalars['String'] | null;
  twitterName: Scalars['String'] | null;
  twitterProfileImageUrl: Scalars['String'] | null;
  twitterUsername: Scalars['String'] | null;
  youtubeChannelDescription: Scalars['String'] | null;
  youtubeChannelId: Scalars['String'] | null;
  youtubeChannelName: Scalars['String'] | null;
  youtubeChannelSubscriberCount: Scalars['Int'] | null;
  youtubeChannelVideoCount: Scalars['Int'] | null;
  youtubeChannelViewCount: Scalars['Int'] | null;
};

export type Payment = {
  amount: number;
  amountRefunded: number;
  applicationFees: number;
  confidoTransactionId: string | null;
  createdAt: Scalars['DateTime'],
  creatorCurrency: string | null;
  customerCurrency: string | null;
  discounts: number;
  failureReason: string | null;
  id: Scalars['ID'],
  invoiceId: string | null;
  last4: string | null;
  netRevenue: number;
  notes: string | null;
  paymentPlanId: string | null;
  passedProcessingFeesToCustomer: boolean;
  paymentMethodType: string | null;
  // paymentMethodType: 'outside-of-noodle' | 'Card' | 'ACH' | null;
  paymentStatus: 'succeeded' | 'requires_payment_method' | 'failed' | 'pending' | 'voided';
  payoutId: string | null,
  personId: string | null;
  personProductId: string | null;
  personSubscriptionId: string | null;
  priceId: string | null;
  priceTitle: string | null;
  processingFees: number;
  processingFeesRefunded: number;
  productId: string | null;
  productTitle: string | null;
  provider: 'stripe' | 'confido' | 'outside-of-noodle';
  succeededAt: Scalars['DateTime'] | null;
  taxAmount: number;
  type: string;
};

export type Payout = {
  id: Scalars['ID'];
  amount: number;
  endAt: Scalars['DateTime'] | null;
  submittedAt: Scalars['DateTime'] | null;
  expectedPaidDate: Scalars['DateTime'] | null;
  status: string;
  routablePayoutId: string | null;
  creatorId: string | null;
  paymentsCount: number;
  createdAt: Scalars['DateTime'];
};

export type Person = {
  id: string;
  email: string | null;
  role: Roles;
  phoneNumber: string | null;
  contactPhoneNumber: string | null;
  name: string | null;
  isVerified: boolean;
  backupAuthData: string | null;
  createdAt: Scalars['DateTime'];
  preferredLanguage: string | null;
  primaryColour?: Scalars['Hex'] | null;
};

export type PersonAttorney = {
  id: string;
  email: string;
  personId: string;
  barNumber: string | null;
  firstName: string;
  middleName: string | null;
  lastName: string;
  daytimePhoneNumber: string | null;
  mobilePhoneNumber: string | null;
  organizationName: string | null;
  uscisAccountNumber: string | null;
  faxNumber: string | null;
  licensingAuthority: string | null;
  mailingAddressCity: string | null;
  mailingAddressState: string | null;
  mailingAddressStreet: string | null;
  mailingAddressUnit: AddressUnitType | null;
  mailingAddressUnitNumber: number | null;
  mailingAddressZip: string | null;
  physicalAddressCity: string | null;
  physicalAddressState: string | null;
  physicalAddressStreet: string | null;
  physicalAddressUnit: AddressUnitType | null;
  physicalAddressUnitNumber: number | null;
  physicalAddressZip: string | null;
};

export enum NoodleProductTypes {
  Broadcast = 'Broadcast',
  Byte = 'Byte',
  Charge = 'Charge',
  Handbook = 'Handbook',
  InPersonSession = 'InPersonSession',
  Lite = 'Lite',
  Noodle = 'Noodle',
  NoodleSaaS = 'NoodleSaaS',
  OnlineSession = 'OnlineSession',
  ProductGroup = 'ProductGroup',
  Response = 'Response',
  Subscription = 'Subscription',
  Consultation = 'Consultation',
  // This was added to noodle-frontend, but isn't a real type. Is only there for the CreateServiceForm
  // Need a better way to handle
  Collection = 'Collection',
}

export enum SubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Expired = 'Expired',
  Incomplete = 'Incomplete',
  IncompleteExpired = 'Incomplete_expired',
  PastDue = 'Past_due',
  Trialing = 'Trialing',
  Unpaid = 'Unpaid'
}

export enum ProductState {
  Free = 'free',
  NotForSale = 'not_for_sale',
  OnSale = 'on_sale'
}

export enum CaptureMethod {
  AUTOMATIC = 'automatic',
  AUTO_ASYNC = 'automatic_async',
  MANUAL = 'manual',
}

export type NoodleProductPrice = {
  id: string;
  price: number;
  priceTitle?: string | null;
  priceSubDescription?: string | null;
  isActive?: boolean | null;
  numBookings?: number | null;
  currency: GraphQLModels.Currency;
  priceDescription?: { html: string } | null;
};

export type Product = Pick<GraphQLModels.Product,
  | 'address'
  | 'canUpsell'
  | 'confidoBankAccountId'
  | 'createdAt'
  | 'description'
  | 'id'
  | 'includeHandbooks'
  | 'includesBroadcasts'
  | 'includesFreeMembership'
  | 'isActive'
  | 'isTierBasedPermissioningEnabled'
  | 'lastUpdate'
  | 'noodleApplicationFlatFee'
  | 'secondaryStripeAccountId'
  | 'shortDescription'
  | 'showOnOneLink'
  | 'slug'
  | 'state'
  | 'stripeId'
  | 'title'
  | 'initiateCaseRank'
  | 'numConcurrentBookings'
> & {
  defaultSchedulingPersonId: string | null;
  isBuyEnabled: boolean;
};

export type ProductType = Pick<GraphQLModels.ProductType,
  | 'id'
  | 'noodleProductType'
  | 'title'
  | 'thumbnail'
  | 'slug'
>;

export type HandbookStep = {
  id: string;
  repetitionAmount?: number | null
  step?: number | null;
};

export type HandbookPhoto = Pick<GraphQLModels.Asset,
  | 'id'
  | 'url'
  | 'mimeType'
  | 'youTubeLink'
  | 'vimeoUri'
  | 'fileName'
  | 'size'
> & {
  loomEmbedUrl?: string | null;
};

export enum TaxBehavior {
  Exclusive = 'exclusive',
  Inclusive = 'inclusive',
  Unspecified = 'unspecified'
}

export enum Interval {
  Day = 'DAY',
  Month = 'MONTH',
  Sixmonths = 'SIXMONTHS',
  Threemonths = 'THREEMONTHS',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type Price = Pick<GraphQLModels.Price,
  | 'bookingCalendarLink'
  | 'currency'
  | 'id'
  | 'isActive'
  | 'isArchived'
  | 'isMostPopular'
  | 'numBookings'
  | 'price'
  | 'priceSubDescription'
  | 'priceTitle'
  | 'recurringInterval'
  | 'sessionDuration'
  | 'sessionInterval'
  | 'sessionTime'
  | 'stripePriceId'
  | 'trialPeriodDays'
  | 'usageType'
  | 'isUpsell'
  | 'tierId'
  | 'isAchPaymentEnabled'
  | 'isCardPaymentEnabled'
  | 'isDebitCardPaymentEnabled'
  | 'originalPrice'
  | 'secondaryStripeAccountId'
  | 'confidoBankAccountId'
> & {
  createdAt: Scalars['DateTime'];
  isExemptFromApplicationFee: boolean;
  passProcessingFeesToCustomer: boolean;
};

export type Statistics = {
  invoices: number;
  invoiceFollowups: number;
  customTerms: number;
  customTermsFollowups: number;
  formRequests: number;
  formRequestFollowups: number;
  documentRequestFollowups: number;
  aiResponses: number;
  anvilDocs : number;
  uploadedDocs: number;
  activeMemberships: number;
  commentsByMembers: number;
  messagesSent: number;
  conversationsCreated: number;
};

export type RoutableCompany = {
  id: string,
  type: string,
  contacts: {
    results: Array<{
      id: string,
    }>
  },
  created_at: Scalars['DateTime'],
  display_name: string,
  external_id: string |null,
  is_customer: boolean,
  is_vendor: boolean,
  ledger: {
    customer_id: string | null,
    vendor_id: string | null
  },
  status: string,
  business_name: string;
};

export type ScheduledEvent = {
  id: Scalars['ID'];
  eventName: string;
  referenceId: string;
  referenceType: string;
  data: Scalars['Json'] | null;
  executeAt: Scalars['DateTime'];
  isExecuted: boolean;
};

export type Booking = {
  id: Scalars['ID'];
  startAt: Scalars['DateTime'] | null;
  endAt: Scalars['DateTime'] | null;
  productId: string;
  priceId: string;
  skippedAt: Scalars['DateTime'] | null;
  canceledAt: Scalars['DateTime'] | null;
  teamMemberPersonId?: string | null;
  meetingLink: string | null;
  userWorkflowId: string | null;
};

export type BookingPermission = {
  id: string;
  bookingId: string;
  personId: string;
};

export enum MessageType {
  ByteRoot = 'ByteRoot',
  ByteStep = 'ByteStep',
  ProductDiscussion = 'ProductDiscussion',
  PaidBroadcast = 'PaidBroadcast',
  FreeBroadcast = 'FreeBroadcast',
  Comment = 'Comment',
  Chat = 'Chat',
  ChatRoot = 'ChatRoot',
  Noodle = 'Noodle',
  CommunityPost = 'CommunityPost',
  WorkflowComment = 'WorkflowComment',
  WorkflowInternalNote = 'WorkflowInternalNote',
  NoodleBroadcast = 'NoodleBroadcast'
}

export enum MessageStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Error = 'ERROR'
}

export type Message = {
  id: string;
  sendCount: number;
  title: string | null;
  viewCount: number;
  text: string | null;
  slateAST: { children: SlateNode[] } | null;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: MessageType;
  isDeleted: boolean;
  isDismissed: boolean;
  rank: number;
  ownerId: string | null;
  isAIGenerated: boolean;
  parentId: string | null;
  bookingLink: string | null;
  isPinned: boolean;
  isContentHidden: boolean;
  isAvailableForFree: boolean;
  canReply: boolean;
  canReplyForFree: boolean;
  areCommentsHidden: boolean;
  messageStatus: MessageStatus;
  status: MessageStatus;
  metadata: Record<string, unknown> | null;
  businessCategories: string[] | null
};

export type MessageOwner = Pick<Person, 'id' | 'name' | 'email'> & {
  primaryColour: { hex: string } | null;
  image: { url: string } | null;
} | null;

export enum MediaStatus {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Removed = 'REMOVED',
  Uploading = 'UPLOADING'
}

export type Media = {
  id: string;
  fileSize: number | null;
  mediaStatus: MediaStatus;
  name: string | null;
  s3Id: string | null;
  s3OriginId: string | null;
  sendCount: number;
  title: string;
  transloaditS3Id: string | null;
  type: string | null;
  url: string | null;
  viewCount: number;
  vimeoThumbnailUrl: string | null;
  vimeoUri: string | null;
};

export type MessageMedia = {
  id: string;
  type: string;
  url: string | null;
  vimeoUri: string | null;
  vimeoThumbnailUrl: string | null;
};

export type GoogleOauth2Token = {
  id: Scalars['ID'];
  email: string;
};

export type CreatorCalendar = {
  id: Scalars['ID'];
  title: string;
  isEnabled: boolean;
};

// Keep in sync with CreatorCalendarAccount.Source
export enum CreatorCalendarAccountSource {
  Google = 'google',
  Outlook = 'outlook',
}

export type CreatorCalendarAccount = {
  id: Scalars['ID'];
  title: string;
  isConnected: boolean;
  source: CreatorCalendarAccountSource;
};

export type Tier = {
  id: string;
  title: string;
  description: string;
  productId?: string | null;
  isActive: boolean;
};

export type Testimonial = Pick<GraphQLModels.Testimonial,
  | 'id'
>;

export type DiscountCode = {
  id: string;
  code: string;
  percentOff: number | null;
  amountOff: number | null;
  minimumAmount: number | null;
  expirationDate: Scalars['DateTime'] | null;
  currency: string | null;
  maxRedemptions: number | null;
};

export type DiscountCodeProduct = {
  productId: string;
};

export enum ConversationStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  ResponseRequired = 'RESPONSE_REQUIRED',
}

export type Conversation = {
  conversationStatus: ConversationStatus;
  id: string;
  name: string;
  sid: string; // @deprecated
  isAiEnabled: boolean;
  endedAt?: Scalars['DateTime'] | null;
  rootMessageId: string | null;
  purchasesCount: number;
  createdAt: Scalars['DateTime'];
  lastActivityTime: Scalars['DateTime'] | null;
  lastMessage: string;
  purchasedProductTypes: NoodleProductTypes[];
  unreadCount: number;
  isScheduled: boolean;
  isSubscriber: boolean;
  isPaid: boolean;
  isLead: boolean;
  isAnonymous: boolean;
  isWorkflow: boolean;
  color: string | null;
  image: string | null;
};

export enum OrderStatuses {
  Failed = 'failed',
  Processing = 'processing',
  Succeeded = 'succeeded',
  Trialing = 'trialing',
}

export enum TransactionTypes {
  Free = 'free',
  OneTime = 'one_time',
  SubscriptionCreate = 'subscription_create',
  SubscriptionRenewal = 'subscription_renewal',
}

export type Tag = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum CreatorTierType {
  Enterprise = 'Enterprise',
  Free = 'Free',
  Growth = 'Growth',
  Scale = 'Scale',
  Superstar = 'Superstar'
}

export enum CreatorPayoutSchedule {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
}

export enum SourcePlatform {
  AppSumo = 'AppSumo',
  Internal = 'Internal'
}

export type AgencyTier = {
  id: string;
};

export type CreatorTier = {
  id: string;
  creatorTierType: CreatorTierType;
  title: string;
  broadcastsPerMonthLimit: number | null;
  bytesLimit: number | null;
  chatsLimit: number | null;
  leadsLimit: number | null;
  applicationFeeCharge: number;
  applicationFeeHandbook: number;
  applicationFeeInPersonSession: number;
  applicationFeeLite: number;
  applicationFeeInvoices: number;
  applicationFeeOnlineSession: number;
  applicationFeeProductGroup: number;
  applicationFeeResponse: number;
  applicationFeeSubscription: number;
  applicationFeeConsultations: number;
  canCreateCharge: boolean;
  canCreateHandbook: boolean;
  canCreateInPersonSession: boolean;
  canCreateLite: boolean;
  canCreateOnlineSession: boolean;
  canCreateProductGroup: boolean;
  canCreateResponse: boolean;
  canCreateSubscription: boolean;
  canCreateConsultation: boolean;
  upgradeUrl: string | null;
  membersLimit?: number | null;
  platform: SourcePlatform;
  payoutSchedule: CreatorPayoutSchedule;
};

export enum WORKFLOW_REFERENCE_TYPES {
  TEST_TYPE = 'test',
  PRODUCT = 'product',
}

export enum CONTEXT_TYPE {
  ADDITIONAL_INFO = 'additional-info',
  PERSON = 'person',
  SELECTION = 'selection',
}

export type Workflow = {
  creatorId: string;
  enableUscisUpdates: boolean;
  estimatedTotalFees: number | null;
  hasGeneratedPdfs: boolean;
  id: string;
  isArchived: boolean;
  isEnabled: boolean;
  isLocked: boolean;
  name: string;
  noodleFee: number;
  publishedAt: Scalars['DateTime'] | null;
  referenceId: string | null;
  referenceType: WORKFLOW_REFERENCE_TYPES;
  canInitiateAssociatedWorkflows: boolean;
  sendEmailToCreatorOnCompletion: boolean;
  sendEmailToCreatorOnFirstPayment: boolean;
  state: 'current' | 'draft' | 'stale';
  type: 'basic' | 'attorney-case';
  version: number;
  slug: string;
};

export type WorkflowReference = {
  id: string;
  type: WORKFLOW_REFERENCE_TYPES,
  title: string | null;
};

export type WorkflowContext = {
  id: string;
  isOptional: boolean;
  isTitleField: boolean;
  rank: number;
  type: CONTEXT_TYPE;
  label: string;
  options: { slug: string; label: string }[];
  slug: string | null;
  expressionId?: string | null;
};

export type CreatePersonContextData = { isCustomer: boolean; email: string; name: string; };
export type CreateInfoContextData = { value: string; };
export type CreateSelectionContextData = { slug: string; };
export type CreateNotesContextData = { notes: string; };

export enum VARIABLE_TYPE {
  PERSON_NAME = 'person-name',
  ENTERED_VALUE = 'entered-value',
}

export type WorkflowContextVariable = {
  id: string;
  workflowContextId: string;
  variableName: string;
  type: VARIABLE_TYPE;
};

export type UserWorkflowContext = {
  id: string;
  type: CONTEXT_TYPE;
  label: string;
  rank: number;
  value: string;
};

export enum StepType {
  TRIGGER = 'trigger',
  ACTION = 'action'
}

export enum STEP_STATUS {
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  IN_PROGRESS = 'in-progress',
}

export enum WORKFLOW_ACTIONS {
  SEND_MESSAGE = 'send-message',
  GENERATE_ALL_WORKFLOW_PDFS = 'generate-all-workflow-pdfs',
  SEND_EMAIL = 'send-email',
  SEND_DOCUMENT_REQUEST = 'send-document-request',
  TEST_ACTION = 'test-action',
}

export enum WORKFLOW_TRIGGERS {
  ALL_DEPENDENCIES = 'all-dependencies',
  CART_ABANDONED = 'cart-abandoned',
  CUSTOM_TERMS_AGREED = 'custom-terms-agreed',
  DOCUMENTS_UPLOADED = 'documents-uploaded',
  FORM_REQUEST_COMPLETED = 'form-request-completed',
  CREDIT_REPORT_COMPLETED = 'credit-report-completed',
  INITIATE_CTA = 'initiate-cta',
  INITIATE_THREAD = 'initiate-thread',
  PRODUCT_PURCHASED = 'product-purchased',
  PURCHASE_INITIATED = 'purchase-initiated',
  TEST_TRIGGER = 'test-trigger',
  UPCOMING_BOOKING = 'upcoming-booking',
  USER_WORKFLOW_CTA = 'user-workflow-cta',
  BOOKING_SCHEDULED = 'booking-scheduled',
}

export enum EXTERNAL_DEPENDENCY_REFERENCE_TYPES {
  DOCUMENT_REQUEST_USER = 'document-request-user',
  FORM_REQUEST = 'form-request',
}

export type UserWorkflow = {
  canceledAt: Scalars['DateTime'] | null;
  conversationSid: string | null;
  createdAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'] | null;
  hasAssignedTasks: boolean;
  id: string;
  lastActivityAt: Scalars['DateTime'];
  nextFollowupTime: Scalars['DateTime'] | null;
  noodleFee: number;
  numCompletedTasks: number;
  numStepsTaken: number;
  numTotalTasks: number;
  numWorkflowSteps: number;
  personId: string;
  completedAt: Scalars['DateTime'] | null;
  status: 'data-collection' | 'preparing-case' | 'filed-and-pending' | 'completed' | 'archived';
  title: string;
  uscisReceiptNumber: string | null;
  pausedUntil: Scalars['DateTime'] | null;
  filedAt: Scalars['DateTime'] | null;
  progress: number;
};

export type WorkflowConversation = {
  id: string;
  rootMessageId: string | null;
};

export const UserWorkflowCollaboratorSourceEnum = z.enum(['manually-added', 'customer', 'organization-member', 'creator', 'creator-team-member', 'context-person']);
export type UserWorkflowCollaborator = {
  id: string;
  source: z.infer<typeof UserWorkflowCollaboratorSourceEnum>,
  canAssign: boolean;
  canInviteCollaborators: boolean;
  canReceiveCustomerNotifications: boolean;
  canHavePermissionsChanged: boolean;
  name: string | null;
  color: string | null;
  logo: string | null;
  roleLabel?: string | null;
  email: string | null;
  personId: string;
};

export type WorkflowStep = {
  id: string;
  type: StepType;
  data: unknown;
  eventName: WORKFLOW_ACTIONS | WORKFLOW_TRIGGERS;
  workflowId: string;
  isRequired: boolean;
  title: string | null;
};

export type StepTaken = {
  id: string;
  status: STEP_STATUS;
  stepId: string;
  updatedAt: Scalars['DateTime'];
  lastActivityAt: Scalars['DateTime'];
};

export enum MESSAGE_ATTACHMENT_REFERENCE_TYPES {
  DOCUMENT_REQUEST_TEMPLATE = 'document-request-template',
  DOCUMENT_REQUEST_USER = 'document-request-user',
  CREDIT_REPORT = 'credit-report',
  PAYMENT_LINK = 'payment-link',
  FORM_REQUEST_TEMPLATE = 'form-request-template',
  FORM_REQUEST = 'form-request',
  INVOICE = 'invoice',
  INVOICE_REQUEST = 'invoice-request',
  CUSTOM_TERMS_TEMPLATE = 'custom-terms-template',
  CUSTOM_TERMS = 'custom-terms',
  BOOKING_PRICE = 'booking-price',
  BOOKING = 'booking',
}

export type MessageAttachment = {
  id: string;
  referenceId: string;
  referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES;
  messageId: string;
  canAccess: boolean;
  title: string | null; // @todo noodle-api isn't sending this... remove from here or start sending?
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  assigneeIds: string[];
  nextFollowupTime: Date;
  isFollowupEnabled: boolean;
  lastActivityAt: Scalars['DateTime'];
  completedAt: Scalars['DateTime'] | null;
  integrationSlugs: string[];
};

export enum FollowupCadenceUnits {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Weeks = 'weeks',
}

export type Customer = {
  id: string;
  personId: string;
  isVerified: boolean;
  creatorId: string;
  createdAt: Scalars['DateTime'];
  stripeCustomerId: string | null;
  canCreateOrganization: boolean;
  optedIntoBroadcasts: boolean;
  followupCadence: number | null;
  followupCadenceUnits: FollowupCadenceUnits | null;
};

export type AssignToPersonType = 'context' | 'team-member' | 'customer';
export type AssignToData = {
  personType: AssignToPersonType;
  referenceId: string;
};

export type WorkflowStepEventNameAndData =
  // Triggers
  | {
    eventName: WORKFLOW_TRIGGERS.TEST_TRIGGER,
    data: null;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.PRODUCT_PURCHASED,
    data: null;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.CART_ABANDONED,
    data: null;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.UPCOMING_BOOKING,
    data: null;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.DOCUMENTS_UPLOADED,
    data: null;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.USER_WORKFLOW_CTA,
    data: {
      title?: string;
    }
  }
  | {
    eventName: WORKFLOW_TRIGGERS.FORM_REQUEST_COMPLETED,
    data: unknown;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.CUSTOM_TERMS_AGREED,
    data: null;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.INITIATE_CTA,
    data: {
      CTA: string;
      description: string;
      initiatePrompt?: string;
      title: string; // @deprecated in favor of workflowStep.title
    };
  }
  | {
    eventName: WORKFLOW_TRIGGERS.INITIATE_THREAD,
    data: unknown;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.PURCHASE_INITIATED,
    data: unknown;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.ALL_DEPENDENCIES,
    data: unknown;
  }
  | {
    eventName: WORKFLOW_TRIGGERS.BOOKING_SCHEDULED,
    data: unknown;
  }
  // Actions
  | {
    eventName: WORKFLOW_ACTIONS.SEND_MESSAGE,
    data: {
      message: Message & {
        attachments: Array<MessageAttachment & { assignTo: AssignToData[] }>;
      }
    }; // @todo
  }
  | {
    eventName: WORKFLOW_ACTIONS.SEND_EMAIL,
    data: unknown; // @todo
  }
  | {
    eventName: WORKFLOW_ACTIONS.SEND_DOCUMENT_REQUEST,
    data: unknown; // @todo
  }
  | {
    eventName: WORKFLOW_ACTIONS.GENERATE_ALL_WORKFLOW_PDFS,
    data: unknown; // @todo
  }
  | {
    eventName: WORKFLOW_ACTIONS.TEST_ACTION,
    data: unknown; // @todo
  };

export type InitiateWorkflowCTA = {
  id: string;
  CTA: string;
  description: string;
  title: string;
  initiatePrompt?: string;
  workflowId: string;
  workflowEnabled: boolean;
};

export type CreateMessageAttachment =
  | {
    assignTo: AssignToData[];
    data: { userWorkflowId?: string; };
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_TEMPLATE
  }
  | {
    assignTo: AssignToData[];
    data: unknown;
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER
  }
  | {
    assignTo: AssignToData[];
    data: {
      workflowReferenceId: string;
      userWorkFlowId?: string;
    };
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.PAYMENT_LINK
  }
  | {
    assignTo: AssignToData[];
    data: { userWorkflowId?: string };
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT
  }
  | {
    assignTo: AssignToData[];
    data: { userWorkflowId?: string };
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST_TEMPLATE
  }
  | {
    assignTo: AssignToData[];
    data: { userWorkflowId?: string };
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS_TEMPLATE,
  }
  | {
    assignTo: AssignToData[];
    data: unknown;
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS,
  }
  | {
    assignTo: AssignToData[];
    data: {
      workflowReferenceId?: string;
      userWorkflowId?: string;
    };
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE_REQUEST,
  }
  | {
    assignTo: AssignToData[];
    data: unknown;
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE,
  }
  | {
    assignTo: AssignToData[];
    data: unknown;
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST
  }
  | {
    assignTo: AssignToData[];
    data: { userWorkflowId?: string; };
    expressionId?: string | null;
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING_PRICE;
  };

export enum FormProviders {
  Anvil = 'anvil',
  Typeform = 'typeform',
  Noodle = 'noodle',
}

export type FormRequestTemplateTag = {
  label: string;
  slug: string;
};

export type FormRequestTemplate = {
  autoAssignCreatorInWorkflows: boolean;
  autoAssignCustomerInWorkflows: boolean;
  canAutoComplete: boolean;
  creatorId: string;
  description: string | null;
  followupCadence: number;
  followupCadenceUnits: FollowupCadenceUnits;
  id: string;
  inheritanceScheme: 'none' | 'creator' | 'customer';
  isFollowupEnabled: boolean;
  name: string;
  provider: FormProviders;
  providerLocator: string;
  tags: FormRequestTemplateTag[] | null;
  enableAiSummary: boolean;
  usesCasebuilderQuestionnaire: boolean;
  aiSummaryVoice: string | null;
};

export enum FormStatus {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  SKIPPED = 'skipped',
}

// copied from @typeform/api-client Typeform.Response
type TypeformDocumentData = {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

// copied from @typeform/api-client Typeform.Response
export type FormRequestResponseTypeform = {
  /**
   * Unique ID for the response. Note that `response_id` values are unique per form but are not unique globally.
   */
  response_id?: string
  /**
   * Time of the form landing. In ISO 8601 format, UTC time, to the second, with T as a delimiter between the date and time.
   */
  landed_at?: string
  /**
   * Time that the form response was submitted. In ISO 8601 format, UTC time, to the second, with T as a delimiter between the date and time.
   */
  submitted_at?: string
  /**
   * Metadata about a client's HTTP request.
   */
  metadata?: {
    user_agent?: string
    /**
     * Derived from user agent
     */
    platform?: string
    referer?: string
    /**
     * Ip of the client
     */
    network_id?: string
  }
  hidden?: TypeformDocumentData
  /**
   * Subset of a complete form definition to be included with a submission.
   */
  definition?: {
    fields?: {
      id?: string
      type?: string
      title?: string
      description?: string
      allow_multiple_selections?: boolean // added manually, not sure why it's not in @typeform/api-client
    }[]
  }
  answers?: {
    field?: {
      /**
       * The unique id of the form field the answer refers to.
       */
      id?: string
      /**
       * The field's type in the original form.
       */
      type?: string
      /**
       * The reference for the question the answer relates to. Use the `ref` value to match answers with questions.
       * The Responses payload only includes `ref` for the fields where you specified them when you created the form.
       */
      ref?: string
      /**
       * The form field's title which the answer is related to.
       */
      title?: string
    }
    /**
     * The answer-field's type.
     */
    type?:
      | 'choice'
      | 'choices'
      | 'date'
      | 'email'
      | 'url'
      | 'file_url'
      | 'number'
      | 'boolean'
      | 'text'
      | 'payment'
    /**
     * Represents single choice answers for dropdown-like fields.
     */
    choice?: {
      label?: string
      other?: string
    }
    /**
     * Represents multiple choice answers.
     */
    choices?: {
      labels?: string[]
      other?: string
    }
    date?: string
    email?: string
    file_url?: string
    number?: number
    boolean?: boolean
    text?: string
    url?: string
    payment?: {
      amount?: string
      last4?: string
      name?: string
    }
  }[]
  calculated?: {
    score?: number
  }
};

export type AnvilPhoneValue = {
  num: string;
  region: string;
};

export type AnvilFullNameValue = {
  firstName: string;
  lastName: string;
  mi: string;
};

export type AnvilAddressValue = {
  city: string;
  country: string;
  state: string;
  street1: string;
  street2: string;
  zip: string;
};

export type AnvilCompoundSelectValue = {
  k: string;
  v: string;
};

export type AnvilArrayValue = Record<string, {
  type: string;
  value: unknown;
}>[];

export type AnvilValue =
  | AnvilPhoneValue
  | AnvilAddressValue
  | AnvilFullNameValue
  | AnvilCompoundSelectValue
  | AnvilArrayValue
  | Array<Record<string, {
    type: string;
    value: AnvilValue;
  }>>
  | unknown;

export type AnvilSubmission = {
  /** The `eid` is the object identifier. */
  eid: string;
  /** Possible values: `created`, `in-progress`, `waiting-to-sign`, `user-signed-and-waiting`, `users-turn-to-sign-ui`, `users-turn-to-sign-email`, `someone-else-signed-and-waiting`, `completed` */
  status: string;
  forge: {
    eid: string;
    id: number;
  } | null;
  /** The payload resolved with field aliases and field information. If you need to fetch the submission payload, use this. */
  resolvedPayload: Record<string, {
    id: string;
    type: string;
    label: string;
    value: AnvilValue;
  }>;
  /** The raw payload */
  payload: Record<string, {
    type: string;
    value: AnvilValue;
  }>;
  /** The raw payload without types */
  payloadValue: Record<string, AnvilValue>
  currentStep: number;
  totalSteps: number;
  continueURL: string;
  // weldData?: Maybe<WeldData>
  // forge?: Maybe<Forge>
  // user?: Maybe<User>
  // signer?: Maybe<Signer>
  // reviewData?: Maybe<Scalars["JSON"]>
  completionPercentage: number;
  isExcluded: boolean;
  touchedByUser: boolean;
  requestMeta: Record<string, unknown>;
  createdAt: string;
  updatedAt: string;
  completedAt: string;
};

export type AnvilFormPage = {
  id: string;
  title: string;
  fields: Array<{
    fields?: Array<{
      id: string;
      type: string;
      label: string;
      options?: Array<{ k: string; v: string }>;
    }>
    id: string;
    options?: {
      boxLabel?: string | null;
    }
    type: string;
    label: string;
  }>
};

export type FormRequestResponseAnvil = {
  pages: Array<AnvilFormPage>;
  // The full submission isn't sent in the webhook
  submission: Pick<AnvilSubmission,
    | 'eid'
    | 'completedAt'
    | 'status'
    | 'payload'
    | 'resolvedPayload'
  >;
};

export enum INVOICE_STATUS {
  IS_GENERATING = 'is-generating', // The amount/price has not been assigned to the invoice yet.
  PAID = 'paid', // Fully paid
  FINAL_PAYMENT_INITIATED = 'payment-initiated', // Final payment is underway, but not yet completed.
  IN_PROGRESS = 'in-progress', // Amount has been set, but not fully paid yet.
  PAYMENT_REQUESTED = 'payment-requested', // Payment request sent to payment processor, but not confirmed yet. Don't allow other payments while in this state.
  PAYMENT_FAILED = 'payment-failed', // Last payment had an error (maybe not wanted???).
  VOIDED = 'voided', // Need to change the price/amount
  SKIPPED = 'skipped', // Do not attempt to collect.
}

export type InvoiceTemplate = {
  id: string;
  creatorId: string;
  priceId: string | null;
  productId: string | null;
  title: string;
  followupCadence: number;
  followupCadenceUnits: FollowupCadenceUnits;
  autoAssignCreatorInWorkflows: boolean;
  autoAssignCustomerInWorkflows: boolean;
  isFollowupEnabled: boolean;
  isExemptFromApplicationFee: boolean;
  isPartialPaymentEnabled: boolean;
  isCreditCardPaymentEnabled: boolean;
  isDebitCardPaymentEnabled: boolean;
  isDebitCardPaymentRequiredForCustomer: boolean;
  isAchPaymentEnabled: boolean;
  passProcessingFeesToCustomer: boolean;
  currency: string;
  secondaryStripeAccountId: string | null;
  confidoBankAccountId: string | null;
  customTermsVariableName: string | null;
  slug: string;
};

export type Invoice = {
  amountDue: number;
  amountPaid: number;
  amountPending: number;
  amountTotal: number;
  amountRefunded: number;
  id: string;
  currency: string;
  createdAt: Scalars['DateTime'];
  creatorId: string;
  personId: string;
  priceId: string | null;
  productId: string | null;
  documentId: string | null;
  workflowReferenceId: string | null;
  status: INVOICE_STATUS;
  fallbackTitle: string | null;
  title: string | null;
  userWorkflowId: string | null;
  completedAt: Scalars['DateTime'] | null;
  initiatedAt: Scalars['DateTime'] | null;
  skippedAt: Scalars['DateTime'] | null;
  isExemptFromApplicationFee: boolean;
  isPartialPaymentEnabled: boolean;
  isCreditCardPaymentEnabled: boolean;
  isDebitCardPaymentEnabled: boolean;
  isDebitCardPaymentRequiredForCustomer: boolean;
  isAchPaymentEnabled: boolean;
  passProcessingFeesToCustomer: boolean;
  canBePaid: boolean;
  hasPayPermission: boolean;
  isFollowupEnabled: boolean;
  secondaryStripeAccountId: string | null;
  confidoPaymentLink: string | null;
  confidoBankAccountId: string | null;
  canAccess: boolean;
};

export type InvoicePermission = {
  id: string;
  invoiceId: string;
  personId: string;
};

export type InvoiceLineItem = {
  id: string;
  creatorId: string;
  invoiceId: string;
  item: string;
  price: number | null;
  description: string | null;
  quantity: number;
  priceId: string | null;
  customTermsVariableName: string | null;
};

export type InvoiceTemplateLineItem = {
  id: string;
  creatorId: string;
  invoiceTemplateId: string;
  item: string;
  price: number | null;
  description: string | null;
  quantity: number;
  priceId: string | null;
  customTermsVariableName: string | null;
};

export type CustomTerms = {
  id: string;
  termsText: string | null;
  agreedAt: Scalars['DateTime'] | null;
  skippedAt: Scalars['DateTime'] | null;
  documentId: string | null;
  userWorkflowId: string | null;
  personId: string;
};

export type CustomTermsPermission = {
  id: string;
  customTermsId: string;
  personId: string;
};

export type TypedCustomTermsDynamicVariable = {
  id: string;
  name: string;
  type: CustomTermsDynamicVariableType;
};

export type CustomTermsTemplate = {
  id: string;
  title?: string | null;
  templateContent?: string | null;
  companySignatory?: string | null;
  customTermsDynamicVariables: TypedCustomTermsDynamicVariable[];
  isFollowupEnabled: boolean;
  autoAssignCreatorInWorkflows: boolean;
  autoAssignCustomerInWorkflows: boolean;
  usesHandwrittenSignature: boolean;
  products: Pick<Product, 'id'>[];
  signatorySignature: string | null;
};

export type CreditReport = {
  id: string;
  creatorId: string;
  userWorkflowId: string | null;
  productId: string | null;
  completedAt: Scalars['DateTime'] | null;
  skippedAt: Scalars['DateTime'] | null;
  status: 'initiated' | 'approval-request-sent' | 'completed';
};

export type CreditReportPermission = {
  id: string;
  personId: string;
  creditReportId: string;
};

export type CreditReportResponse = {
  id: string;
  creditReportId: string;
  personId: string;
  bureau: BureauType;
  requestId: string;
  previousBankruptciesDocId: string;
  response: unknown; // jsonb
  previousBankruptciesResponse: unknown; // jsonb
  pdfRequestId: string | null;
  crsUserId: string | null;
  slug: 'main-debtor' | 'second-debtor';
};

export type FormResponseQuestionField = {
  question: {
    title: string;
  };
};

interface BaseField<T> {
  type: string;
  answer: T | null;
}

export type FormResponseBooleanField = BaseField<boolean> & { type: 'boolean' };
export type FormResponseDateOnlyField = BaseField<string> & { type: 'date-only' | 'date' };
export type FormResponseEmailField = BaseField<string> & { type: 'email' };
export type FormResponseIntegerField = BaseField<number> & { type: 'integer' };
export type FormResponseNumberField = BaseField<number> & { type: 'number' };
export type FormResponsePercentField = BaseField<number> & { type: 'percent' };
export type FormResponseDollarField = BaseField<number> & { type: 'dollar' };
export type FormResponseTextField = BaseField<string> & {
  type: 'long-text' | 'short-text' | 'phone-number' | 'numeric'
};
export type FormResponseSSNField = BaseField<string> & { type: 'ssn' };
export type FormResponseUrlField = BaseField<string> & { type: 'url' };
export type FormResponseYesNoField = BaseField<boolean> & { type: 'yes-no' };
export type FormResponseUnknownField = BaseField<unknown> & { type: 'unknown' };
export type FormResponseCurrencyField = BaseField<CurrencyValue> & { type: 'currency' };
export type FormResponseNameField = BaseField<Name> & { type: 'name' };
export type FormResponseUSAddressField = BaseField<USAddress> & { type: 'address-us' };
export type FormResponseIntlAddressField = BaseField<IntlAddress> & { type: 'address-international' };

export type FormResponseMultipleChoiceField =
  | {
    type: 'multiple-choice';
    allowMultipleSelections: false;
    answer: string | null
  }
  | {
    type: 'multiple-choice';
    allowMultipleSelections: true;
    answer: string[] | null;
  }
  | {
    type: 'multi-select';
    allowMultipleSelections: true;
    answer: { key: string; label: string; }[] | null;
  }
  | {
    type: 'single-select';
    allowMultipleSelections: false;
    answer: { key: string; label: string; } | null;
  }
  | {
    type: 'selection';
    allowMultipleSelections: false;
    answer: { key: string; label: string; } | null;
  };

export type FormResponseAnswerField =
  | FormResponseBooleanField
  | FormResponseDateOnlyField
  | FormResponseEmailField
  | FormResponseIntegerField
  | FormResponseMultipleChoiceField
  | FormResponseNumberField
  | FormResponsePercentField
  | FormResponseDollarField
  | FormResponseTextField
  | FormResponseSSNField
  | FormResponseUnknownField
  | FormResponseUrlField
  | FormResponseYesNoField
  | FormResponseCurrencyField
  | FormResponseNameField
  | FormResponseUSAddressField
  | FormResponseIntlAddressField
  | { type: 'array'; answer: Array< Array<FormResponseAnswerField & { id: string; question: { title: string } }>> | null };

export type FormResponseField =
  & FormResponseAnswerField
  & FormResponseQuestionField
  & { id: string };

export type FormRequestResponseNoodle = Array<FormResponseField>;

/* Fixing caused errors...
type NoodleQuestionnaireResponseQuestionAnswer = {
  id: string;
  question: {
    title: string;
  };
  allowMultipleSelections?: boolean;
  answer: unknown;
  type: string;
};

export type FormRequestResponseNoodle = Array<NoodleQuestionnaireResponseQuestionAnswer>;
*/
export type FormRequest = {
  id: string;
  formTemplateId: string;
  creatorId: string;
  personId: string;
  status: FormStatus;
  url: string;
  response: FormRequestResponseTypeform | FormRequestResponseAnvil | FormRequestResponseNoodle | null;
  hasEditPermission: boolean;
  hasViewPermission: boolean;
  completedAt: Scalars['DateTime'] | null;
  skippedAt: Scalars['DateTime'] | null;
  userWorkflowId: string | null;
  formReferenceId: string;
  aiSummary:string | null;
};

export type FormRequestPermission = {
  id: string;
  formRequestId: string;
  personId: string;
};

export type MessageAttachmentDocumentRequestData = Pick<DocumentApiModels.DocumentRequestUser, 'id' | 'status'> & {
  documentRequest: Pick<DocumentApiModels.DocumentRequest, 'id' | 'title' | 'isForCreator'>;
  actionRequiredFiles: number;
  uploadedRequiredFiles: number;
  totalRequiredFiles: number;
  uploadedFiles: number;
  totalFiles: number;
};

export type MessageAttachmentFormRequestData = Pick<FormRequest, 'id' | 'formTemplateId' | 'personId' | 'creatorId' | 'status' | 'url' | 'skippedAt'> & {
  template: Pick<FormRequestTemplate, 'id' | 'name' | 'creatorId' | 'provider'>;
};

export type MessageAttachmentCreditReportData = CreditReport & {
  responses: Array<{
    creditReportResponseId: string;
    personId: string;
    isCompleted: boolean;
  }>
};

export type MessageAttachmentPaymentLinkData = {
  price: Pick<Price, 'id' | 'priceTitle'> & {
    product?: Pick<Product, 'id' | 'slug' | 'isActive' | 'title'> & {
      productTypes?: Pick<ProductType, 'title' | 'noodleProductType'>[];
      creator?: Pick<Creator, 'id' | 'slug' | 'name'> & {
        image?: Pick<Asset, 'url'> | null;
      };
    };
  };
  workflowReferenceId?: string;
};

export type MessageAttachmentInvoiceRequestData = {
  isEmptyInvoice?: boolean;
  fallbackTitle?: string | null;
  workflowReferenceId?: string;
  userWorkflowId?: string;
};

export type MessageAttachmentCustomTermsData = {
  customTerms: Pick<CustomTerms, 'id' | 'termsText' | 'agreedAt' | 'skippedAt' | 'documentId'>;
  template: Pick<CustomTermsTemplate, 'id' | 'title'> & {
    creator: Pick<Creator, 'id' | 'slug'>;
  };
};

export type MessageAttachmentInvoiceData = {
  invoice: Pick<Invoice,
    | 'amountDue'
    | 'amountPaid'
    | 'amountPending'
    | 'amountTotal'
    | 'amountRefunded'
    | 'canBePaid'
    | 'creatorId'
    | 'currency'
    | 'documentId'
    | 'fallbackTitle'
    | 'id'
    | 'isPartialPaymentEnabled'
    | 'personId'
    | 'productId'
    | 'status'
    | 'title'
  >;
};

export type MessageAttachmentBookingData = {
  booking: Pick<Booking, 'id' | 'startAt' | 'endAt' | 'priceId' | 'productId' | 'skippedAt' | 'teamMemberPersonId'> & {
    price: Pick<Price, 'id' | 'priceTitle'> & {
      product: Pick<Product, 'id' | 'title'>;
    }
    person: Pick<Person, 'id' | 'name'> & {
      primaryColour?: { hex: string } | null;
      image?: { url: string } | null;
    };
    creator: Pick<Creator, 'id' | 'timezone'>;
    teamMemberPerson: Pick<Person, 'id' | 'name'> & {
      primaryColour?: { hex: string } | null;
      image?: { url: string } | null;
    } | null;
  }
};

export type MessageAttachmentWithTypedData =
  | {
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER;
    data: MessageAttachmentDocumentRequestData;
    expressionId?: string | null;
  }
  | {
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST;
    data: MessageAttachmentFormRequestData;
    expressionId?: string | null;
  }
  | {
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT;
    data: MessageAttachmentCreditReportData;
    expressionId?: string | null;
  }
  | {
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.PAYMENT_LINK;
    data: MessageAttachmentPaymentLinkData;
    expressionId?: string | null;
  }
  | {
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE;
    data: MessageAttachmentInvoiceData;
    expressionId?: string | null;
  }
  | {
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE_REQUEST;
    data: MessageAttachmentInvoiceRequestData;
    expressionId?: string | null;
  }
  | {
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS;
    data: MessageAttachmentCustomTermsData;
    expressionId?: string | null;
  }
  | {
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING;
    data: MessageAttachmentBookingData;
    expressionId?: string | null;
  };

export type {
  Roles,
  SlateNode,
};

export type SecondaryStripeAccount = {
  id: string;
  stripeAccountId: string;
  name: string;
  creatorId: string;
};

export type DestinationAccount = {
  id: string;
  provider: 'stripe' | 'confido';
  providerId: string;
  name: string;
  creatorId: string;
  isFromDefault: boolean;
};

export type Document = {
  createdAt: string;
  fileName: string;
  fileSize: number;
  id: string;
  mimeType: string;
  title: string | null; // @deprecated - attribute of UserWorkflowDocument, not Document.
};

export type Organization = {
  id: string;
  name: string;
  ownerId: string;
  creatorId: string;
};

export type OrganizationMember = {
  id: string;
  personId: string;
  organizationId: string;
  canAssign: boolean;
  canInviteCollaborators: boolean;
  canReceiveCustomerNotifications: boolean;
  canInitiateWorkflows: boolean;
  canMakePayments: boolean;
  inviteToAllCases: boolean;
};

export type PaymentMethod = Pick<StripeModels.PaymentMethod, 'id' | 'type'> & {
  card: Pick<NonNullable<StripeModels.PaymentMethod['card']>, 'brand' | 'last4' | 'exp_month' | 'exp_year' | 'funding'> | null;
  us_bank_account: Pick<NonNullable<StripeModels.PaymentMethod['us_bank_account']>, 'bank_name' | 'last4'> | null;
  verifyWithMicrodeposits: {
    arrivalDate: Scalars['DateTime'];
    verificationUrl: string;
    microdepositType: 'amounts' | 'descriptor_code' | 'unknown';
  } | null;
};

export enum FollowupHistoryStatus {
  SENT = 'sent',
  SCHEDULED = 'scheduled',
  CANCELED = 'canceled',
}

export enum FollowupHistoryType {
  EMAIL = 'email',
  SMS = 'sms',
}

export type FollowupHistory = {
  id: string;
  messageAttachmentId: string;
  followupMessageText: string;
  messageId: string | null;
  status: FollowupHistoryStatus;
  type: FollowupHistoryType;
  scheduledAt: Scalars['DateTime'];
};

export type UserWorkflowDocument = {
  id: string;
  rank: number;
  referenceId: string | null;
  referencePublishedAt: Scalars['DateTime'] | null;
  referenceType: 'anvil' | 'direct-document' | 'document-request-file' | 'document-request-file-document' | 'mapping-destination' | 'manually-added' | 'ai-bot' | 'custom-terms' | null;
  referenceVersion: string | null;
  templateReferenceId: string | null;
  templateReferenceType: 'anvil' | 'direct-document' |'document-request-file' | 'document-request-user-file' | 'manually-added' | 'ai-bot' | 'custom-terms-template' | 'mapping-destination' | null;
  title: string | null;
  userWorkflowId: string;
  generatedContent: { children: Descendant[] } | null;
};

export type TeamMember = {
  id: string;
  email: string | null;
  name: string | null;
  image: { url: string } | null;
  phoneNumber: string | null;
  primaryColour: { hex: string } | null;
  role: TeamRole;
  teamMemberId: string;
};

export type TaskTypedData = (
  | {
    data: {
      productId: string | null;
    };
    action: 'generate-invoice'
  }
  | {
    data: {
      productId: string | null;
    };
    action: 'pay-invoice'
  }
  | {
    data: Record<string, never>;
    action: 'review-document-request'
  }
  | {
    data: Record<string, never>;
    action: 'upload-document-request'
  }
  | {
    data: Record<string, never>;
    action: 'agree-to-terms'
  }
  | {
    data: Record<string, never>;
    action: 'submit-document-request-for-review';
  }
  | {
    data: Record<string, never>;
    action: 'generate-terms'
  }
  | {
    data: Record<string, never>;
    action: 'complete-form-request'
  }
  | {
    data: {
      customerName?: string | null;
      lastPurchasedPrice?: string | null;
      senderId?: string | null;
    };
    action: 'respond-to-conversation'
  }
  | {
    data: {
      senderId?: string | null;
    };
    action: 'respond-to-discussion'
  }
  | {
    data: {
      booking: Pick<Booking, 'productId' | 'id' | 'priceId' | 'startAt'> & {
        price: Pick<Price, 'id' | 'priceTitle'> & {
          product: Pick<Product, 'id' | 'title'>;
        };
        person: Pick<Person, 'id' | 'name'> & {
          primaryColour?: { hex: string } | null;
          image?: { url: string } | null;
        };
        creator: Pick<Creator, 'id' | 'timezone'>;
      }
    };
    action: 'schedule-booking';
  }
  | {
    data: {
      booking: Pick<Booking, 'productId' | 'id' | 'priceId' | 'startAt'> & {
        price: Pick<Price, 'id' | 'priceTitle'> & {
          product: Pick<Product, 'id' | 'title'>;
        };
        person: Pick<Person, 'id' | 'name'> & {
          primaryColour?: { hex: string } | null;
          image?: { url: string } | null;
        };
        creator: Pick<Creator, 'id' | 'timezone'>;
      }
    };
    action: 'select-booking-availability';
  }
  | {
    data: Record<string, never>;
    action: 'buy-credit-report';
  }
  | {
    data: Record<string, never>;
    action: 'approve-credit-report';
  }
);

export type Task = {
  id: string;
  title: string;
  createdAt: Scalars['DateTime'];
  referenceType:
    | 'custom-terms'
    | 'document-request-user'
    | 'form-request'
    | 'invoice'
    | 'conversation'
    | 'booking'
    | 'credit-report';
  referenceId: string;
  data: unknown;
  action: TaskTypedData['action'];
};

export type WorkflowInvoice = {
  id: string;
  expressionId: string | null;
  workflowId: string;
  invoiceTemplateId: string;
  title: string | null;
};

export type ProcessingFeesResponse = {
  achFee: number;
  internationalCardFee: number;
  USCardFee: number;
};

export type AiFollowUpItem = {
  id: string;
  date: Date;
  text: string;
  title: string;
  type: FollowupHistoryType;
  person: {
    id: string;
    color: string | null;
    name: string | null;
    numActiveWorkflows: number;
    url: string | null;
  }
};

export type DailyBrief = {
  text: string;
  generatedAt: Date;
};

export type BuildingBlockDependencyCondition = {
  type: 'form-field-response';
};

export type ConditionVariable = {
  referenceType: 'form-field-variable';
};

export type ConfidoBankAccount = {
  id: string;
  category: 'operating' | 'trust';
  nickname: string;
  isDefault: boolean;
  lastFour: string | null;
};

export type TemplateMessage = {
  paragraphs: Array<{
    text: string | string[];
  }>;
  attachments: Array<{
    assignToSlugs?: string[];
    referenceSlug: string; // possible to type? would need to be keyof invoices, formRequests, etc depending on referenceType
    referenceType: MessageAttachment['referenceType'],
  }>;
};

export type WorkflowTemplate = {
  category: 'unknown' | 'immigration-attorney' | 'bankruptcy-attorney';
  customTerms: Array<{
    isFollowupEnabled: boolean;
    newDynamicVariables: string[];
    slug: string;
    title: string;
    templateContent: string;
  }>;
  description: string;
  documentRequests: Array<Pick<DocumentApiModels.DocumentRequest,
    | 'isFollowupEnabled'
    | 'title'
  > & {
    files: Array<
      & {
        title: string;
        description: string;
        isRequired: boolean;
        isForYourEyesOnly: boolean;
      }
      & {
        group?: {
          slug: string;
          rank: number;
        }
      }
    >;
    slug: string;
  }>;
  formRequests: Array<Pick<FormRequestTemplate,
    | 'canAutoComplete'
    | 'followupCadence'
    | 'followupCadenceUnits'
    | 'inheritanceScheme'
    | 'isFollowupEnabled'
    | 'name'
    | 'provider'
    | 'providerLocator'
  > & {
    slug: string;
  }>;

  invoices: Array<Pick<InvoiceTemplate,
    | 'followupCadence'
    | 'followupCadenceUnits'
    | 'isAchPaymentEnabled'
    | 'isCreditCardPaymentEnabled'
    | 'isDebitCardPaymentEnabled'
    | 'isPartialPaymentEnabled'
    | 'isExemptFromApplicationFee'
    | 'isFollowupEnabled'
    | 'passProcessingFeesToCustomer'
    | 'title'
  > & {
    lineItems: Array<Pick<InvoiceTemplateLineItem,
      | 'item'
      | 'price'
      | 'quantity'
    >>;
    slug: string;
  }>;
  product: {
    slug: string;
    title: string;
  };
  productType: {
    slug: string;
    title: string;
  };
  createdAt: Scalars['DateTime'];
  slug: string;
  title: string;
  version: number;
  workflows: Array<{
    compiledDocuments: Array<
      | { type: 'manually-added'; title: string; }
      | { type: 'anvil-cast'; anvilName: string; noodleName?: string }
      | { type: 'document-request'; slug: string; }
      | { type: 'document-request-file'; title: string; slug: string; }
      | { type: 'document-request-group'; slug: string; }
      | { type: 'direct-document'; id: string; title: string; }
      | { type: 'ai-bot'; bot: 'h1bCoverLetterBotV1', title: 'H1B Cover letter' }
    >;
    context: Array<
      & Pick<WorkflowContext, 'label' | 'type' | 'isTitleField'>
      & {
        variableName: string | null;
        slug: string;
      }
    >;
    enableUscisUpdates?: boolean;
    hasGeneratedPdfs: boolean;
    invoices: string[]; // keyof invoices
    name: string;
    sendEmailToCreatorOnCompletion: boolean;
    threads: Array<{
      initiate: (
        | {
          eventName: WORKFLOW_TRIGGERS.INITIATE_CTA,
          CTA: string;
          description: string;
        }
        | {
          eventName: WORKFLOW_TRIGGERS.USER_WORKFLOW_CTA,
          title: string;
        }
        | {
          eventName: WORKFLOW_TRIGGERS.INITIATE_THREAD,
          title: string;
          buildingBlockDependencies?: Array<{
            referenceSlug: string;
            referenceType: MessageAttachment['referenceType'];
            conditions?: Array<{
              type: BuildingBlockDependencyCondition['type'];
              expression: string;
              variables: Array<{
                name: string;
                referenceType: ConditionVariable['referenceType'];
                referenceId: string;
              }>
            }>
          }>
        }
        | {
          eventName: WORKFLOW_TRIGGERS.PURCHASE_INITIATED,
          title: string;
        }
          | {
          eventName: WORKFLOW_TRIGGERS.PRODUCT_PURCHASED,
          title: string;
        }
        | {
          eventName: WORKFLOW_TRIGGERS.BOOKING_SCHEDULED,
          title: string;
        }
      );
      messages: Array<TemplateMessage | TemplateMessage[]>;
    }>;
  }>,
};

const CaseStatus = z.object({
  case_status: z.object({
    currentActionCode: z.string().optional(),
    currentActionCodeDate: z.nullable(z.string()).optional(),
    current_case_status_desc_en: z.string(),
    current_case_status_desc_es: z.string(),
    current_case_status_text_en: z.string(),
    current_case_status_text_es: z.string(),
    formType: z.string(),
    hist_case_status: z.nullable(z.array(z.object({
      action_code: z.string().optional(),
      completed_text_en: z.string(),
      completed_text_es: z.string(),
      date: z.string(),
      jurisdiction: z.nullable(z.string()).optional(),
      jurisdictionDescription: z.nullable(z.string()).optional(),
    }))),
    jurisdiction: z.nullable(z.string()).optional(),
    jurisdictionDescription: z.nullable(z.string()).optional(),
    modifiedDate: z.nullable(z.string()),
    receiptNumber: z.string(),
    submittedDate: z.nullable(z.string()),
  }),
  message: z.string(),
});

export type UscisCaseStatus = z.infer<typeof CaseStatus>;

export type PaymentPlan = {
  id: string;
  invoiceId: string;
  paymentMethodId: string;
  status: 'active' | 'completed' | 'failed' | 'canceled';
  frequency: 'monthly' | 'weekly' | 'biweekly';
  amount: number;
  startDate: Scalars['DateTime'];
  nextPaymentDate: Scalars['DateTime'] | null;
  canceledAt: Scalars['DateTime'] | null;
  createdAt: Scalars['DateTime'];
};

export type WorkflowOwner = {
  personId: string;
  workflowId: string;
};

export type ExpressionVariable = {
  id: string;
  variableName: string;
  selector: string;
  expressionId: string;
  referenceId: string;
  referenceType: 'workflow-attachment' | 'workflow-context';
};

export type Expression = {
  id: string;
  expression: string;
  variables: Pick<ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
};

export type CommonWorkflowResponse =
  & Pick<Workflow,
    | 'creatorId'
    | 'estimatedTotalFees'
    | 'hasGeneratedPdfs'
    | 'id'
    | 'isEnabled'
    | 'isLocked'
    | 'name'
    | 'noodleFee'
    | 'referenceId'
    | 'referenceType'
    | 'canInitiateAssociatedWorkflows'
    | 'sendEmailToCreatorOnCompletion'
    | 'sendEmailToCreatorOnFirstPayment'
    | 'type'
    | 'version'
    | 'enableUscisUpdates'
    | 'slug'
  >
  & {
    /** @deprecated use threads + stepsMap instead */
    steps: Array<Pick<WorkflowStep, 'id' | 'type' | 'eventName' |'data' | 'title'>>;
    stepsMap: Record<string /* id */, Pick<WorkflowStep,
      | 'data'
      | 'eventName'
      | 'id'
      | 'title'
      | 'type'
    >>;
    owners: Array<Pick<WorkflowOwner, 'workflowId' | 'personId'>>;
    contexts: (Pick<WorkflowContext, 'id' | 'label' | 'type' | 'isTitleField' | 'isOptional' | 'options' | 'slug'> & {
      expression?: Expression | null,
      variables: Pick<WorkflowContextVariable, 'id' | 'variableName'>[];
    })[];
    workflowInvoices: Array<Pick<WorkflowInvoice, 'id' | 'title' | 'invoiceTemplateId'> & {
      expression?: Expression | null,
      invoiceTemplate:
        & Pick<InvoiceTemplate,
          | 'autoAssignCreatorInWorkflows'
          | 'autoAssignCustomerInWorkflows'
          | 'creatorId'
          | 'id'
          | 'isAchPaymentEnabled'
          | 'isCreditCardPaymentEnabled'
          | 'isDebitCardPaymentEnabled'
          | 'isFollowupEnabled'
          | 'isPartialPaymentEnabled'
          | 'passProcessingFeesToCustomer'
          | 'priceId'
          | 'secondaryStripeAccountId'
          | 'title'
          | 'confidoBankAccountId'
          | 'isExemptFromApplicationFee'
        >
        & {
          lineItems: Pick<InvoiceTemplateLineItem, 'id' | 'item' | 'price' | 'quantity' | 'description' | 'customTermsVariableName'>[];
        }
    }>;
    buildingBlocks: {
      customTerms: Pick<CustomTermsTemplate, 'id' | 'title'>[];
      documentRequests: Pick<DocumentApiModels.DocumentRequest, 'id' | 'title'>[];
      formRequests: Pick<FormRequestTemplate, 'id' | 'name'>[];
      invoices: Pick<InvoiceTemplate, 'id' | 'title'>[];
      bookingPrices: Pick<GraphQLModels.Price, 'id' | 'priceTitle'>[];
    };
    threads: Array<{
      rank: number;
      stepIds: string[];
    }>;
  };

export type SimpleWorkflowResponse =
  & Pick<Workflow,
    | 'creatorId'
    | 'id'
    | 'isEnabled'
    | 'isLocked'
    | 'name'
    | 'noodleFee'
    | 'referenceId'
    | 'referenceType'
    | 'type'
    | 'version'
    | 'slug'
  >
  & {
    steps: Array<Pick<WorkflowStep, 'id' | 'type' | 'eventName' |'data' | 'title'>>;
    contexts: (Pick<WorkflowContext, 'id' | 'label' | 'type' | 'isTitleField' | 'isOptional' | 'slug' | 'options'> & {
      expression?: Expression | null,
      variables: Pick<WorkflowContextVariable, 'id' | 'variableName'>[];
    })[];
    workflowInvoices: Array<Pick<WorkflowInvoice, 'id' | 'title' | 'invoiceTemplateId'> & {
      expression?: Expression | null,
      invoiceTemplate:
        & Pick<InvoiceTemplate,
          | 'autoAssignCreatorInWorkflows'
          | 'autoAssignCustomerInWorkflows'
          | 'creatorId'
          | 'id'
          | 'isAchPaymentEnabled'
          | 'isCreditCardPaymentEnabled'
          | 'isDebitCardPaymentEnabled'
          | 'isDebitCardPaymentRequiredForCustomer'
          | 'isFollowupEnabled'
          | 'isPartialPaymentEnabled'
          | 'passProcessingFeesToCustomer'
          | 'priceId'
          | 'secondaryStripeAccountId'
          | 'title'
          | 'confidoBankAccountId'
          | 'isExemptFromApplicationFee'
        >
        & {
          lineItems: Pick<InvoiceTemplateLineItem, 'id' | 'item' | 'price' | 'quantity' | 'description' | 'customTermsVariableName'>[];
        }
    }>;
  };

export type PersonProduct = {
  id: string;
  createdAt: Scalars['DateTime'];
  priceId: string;
  productId: string;
  creatorId: string;
  personId: string;
  customerId: string;
  confidoPaymentId: string | null;
  paymentIntentId: string | null;
  localCurrency: string | null;
  conversationId: string | null;
  customTermsId: string | null;
  discountCodeId: string | null;
  externalLicenseId: string | null;
  externalPurchaseId: string | null;
  hasViewedConfirmationPage: boolean;
  isFulfilled: boolean;
  sessionDuration: number | null;
  sessionTime: string | null; // Date + time without timezone. so leave as a string
  };

export type UserWorkflowOwner = {
  personId: string;
  userWorkflowId: string;
};

export enum ACTIVITY_TYPES_AND_ACTIONS_ROLLUP {
  COMMENT_LEFT = 'comment-left',
  DOCUMENT_UPLOADED = 'document-uploaded',
  DOCUMENT_REQUEST_COMPLETED = 'document-request-completed',
  DOCUMENT_REQUEST_SUBMITTED_FOR_REVIEW = 'document-request-submitted-for-review',
  TERMS_AGREED = 'terms-agreed',
  USER_WORKFLOW_INITIATED = 'user-workflow-initiated',
  USER_WORKFLOW_STATUS_CHANGED = 'user-workflow-status-changed',
  FORM_REQUEST_COMPLETED = 'form-request-completed',
  PAYMENT_SUCCEEDED = 'payment-succeeded',
  PAYMENT_INITIATED = 'payment-initiated',
  PAYMENT_FAILED = 'payment-failed',
  CASE_NOTE_CREATED = 'case-note-created',
}

export enum ACTIVITY_TYPES {
  DOCUMENT_REQUEST = 'document-request',
  FORM_REQUEST = 'form-request',
  TERMS = 'terms',
  MESSAGE = 'message',
  STATUS_CHANGE = 'status-change',
  PAYMENT = 'payment',
  CASE_NOTE = 'case-note',
}

export type ActivityNoteAddedData = {
  text: string;
  metadata: {
    contactType?: string | null;
    date?: string | null;
    time?: string | null;
  } | null;
};

type USER_WORKFLOW_STATUS = 'data-collection' | 'preparing-case' | 'filed-and-pending' | 'completed' | 'archived';
type ACTIVITY_ACTIONS_WITHOUT_STATUSES = 'signed' | 'uploaded-document' | 'submitted-for-review' | 'completed' | 'initiated' | 'succeeded' | 'failed';

export type ACTIVITY_ACTIONS = ACTIVITY_ACTIONS_WITHOUT_STATUSES | USER_WORKFLOW_STATUS | null;

export type UserWorkflowActivity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  userWorkflowId: Scalars['ID'];
  personId: Scalars['ID'] | null;
  personName: Scalars['String'] | null;
  details: Scalars['Json'];
  activityType: ACTIVITY_TYPES_AND_ACTIONS_ROLLUP;
  action: ACTIVITY_ACTIONS,
  type: ACTIVITY_TYPES | null,
};

export type InboxItemType = 'chat' | 'workflow-comment' | 'task' | 'noodle-broadcast';

export type TypedInboxItemMeta =
  | {
    type: 'chat';
    meta: {
      conversationSid: string;
      message: string;
      messageId: string;
    };
  }
  | {
    type: 'workflow-comment';
    meta: {
      userWorkflowId: string;
      message: string;
      workflowName: string;
    };
  }
  | {
    type: 'task',
    meta: unknown;
  }
  | {
    type : 'noodle-broadcast',
    meta : {
      message: string;
      messageId: string;
      title: string;
    }
  };

export type UserInboxItem = {
  id: string;
  personId: string;
  senderId: string | null;
  taskId: string | null;
  type: TypedInboxItemMeta['type'];
  meta: unknown;
  isRead: boolean;
  createdAt: Date;
};

export type CommonUserInboxItem = Pick<UserInboxItem, 'id' | 'personId' | 'isRead' | 'createdAt'>
  & TypedInboxItemMeta
  & {
    sender: Pick<Person, 'name'> | null,
    task:
      & Pick<Task, 'id' | 'title' | 'createdAt' | 'referenceId'>
      & TaskTypedData
      & {
        userWorkflow: Pick<UserWorkflow, 'id'> & {
          person: Pick<Person, 'id' | 'name' | 'email'>;
          workflow: Pick<Workflow, 'id' | 'name'>;
        } | null
      } | null,
  };

export type UserInboxUpdatedMessageData = {
  inboxItemId: string;
  isInboxItemRead: boolean;
};

export type businessCategory = {
  businessCategory : string[]
};

export type NoodleBroadcast = {
  ownerId : string | undefined;
  title : string;
  metadata : businessCategory;
  type : string;
  text : string[] | null;
};

export enum SENT_SMS_STATUS {
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum SENT_SMS_TYPE {
  FOLLOWUP = 'followup',
  STANDARD = 'standard',
  CASE_INVITE = 'case-invite',
  OPT_IN = 'opt-in',
  CHAT_REMINDER = 'chat-reminder',
  BOOKING_REMINDER = 'booking-reminder',
  INCOMING_RESPONSE = 'incoming-response',
  INCOMING_MESSAGE = 'incoming-message',
}

export enum WORKFLOW_ATTACHMENT_STATUS {
  IN_PROGRESS = 'in-progress',
  COMPLETE = 'complete',
}

export type SentSmsMessage = {
  id: string;
  createdAt: Scalars['DateTime'];
  status: SENT_SMS_STATUS;
  type: SENT_SMS_TYPE;
  numSegments: number;
  messageBody: string;
  fromNumber: string;
  toNumber: string;
  billedInvoiceId: string | null;
  userWorkflowId: string | null;
  creatorId: string;
  personId: string;
};

export type UserInfo = {
  city: string;
  dob?: string;
  fname: string;
  lname: string;
  mname?: string;
  mobile: string;
  ssn: string;
  state: string;
  street1: string;
  street2?: string | number;
  zip: string;
};

export type UserVerificationResponse = {
  expires: string;
  mobile: string;
  token: string;
};

export type VerificationLinkResponse = {
  smsMessage: string;
  token: string;
  expires: string;
};

export type SmfaVerificationStatusResponse = {
  id: string;
  email: string;
  fname: string;
  lname: string;
  idpass: boolean;
  createdAt: string;
  updatedAt: string;
  smsMsg: boolean;
  emailMsg: boolean;
  pushMsg: boolean;
  flags: number;
  justEnrolled: boolean;
};

export type TeamRoleBooleans = 'finance' | 'manageTeam' | 'admin';
