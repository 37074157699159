import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/conversations/open-graph']['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const getCountersByLink = async ({ ...body }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>('/conversations/open-graph');
  const response = await noodleApiClient.post(path, body);
  return response.data;
};

export default getCountersByLink;
