import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/my/creators/{creatorId}/confido-payment-methods']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getConfidoPaymentMethodsForCreator = async ({ creatorId, forUserId }: Input): Promise<Output> => {
  const data = await noodleApiClient.get(`/my/creators/${creatorId}/confido-payment-methods`, {
    params: { forUserId },
  });
  return data.data;
};

export default getConfidoPaymentMethodsForCreator;
