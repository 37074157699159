import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/workflows/user-workflows/{userWorkflowId}/comments']['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const createWorkflowConversationMessage = async ({ userWorkflowId, ...body }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/workflows/user-workflows/${userWorkflowId}/comments`, body);
  return response.data;
};

export default createWorkflowConversationMessage;
