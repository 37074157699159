import buildPath from '@tsClient/utilities/buildPath';
import noodleDocumentsClient, { ApiDefinition } from '@documentsApi';

type THIS_API = ApiDefinition['/auth/creators/{creatorId}/document-access-password']['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const saveDocumentAccessPassword = async ({creatorId, password }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>('/auth/creators/{creatorId}/document-access-password', { creatorId });
  const response = await noodleDocumentsClient.post(path, { password });
  return response.data;
};

export default saveDocumentAccessPassword;
