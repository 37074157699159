import { AxiosError } from 'axios';

const NO_STATUS_RETRY_METHODS = ['GET', 'OPTIONS', 'HEAD'];

const axiosRetryCondition = (error: AxiosError): boolean => {
  const statusCode = error.response?.status;
  return (!statusCode && error.config?.method && NO_STATUS_RETRY_METHODS.includes(error.config.method.toUpperCase()))
    || statusCode === 502 || statusCode === 504;
};

export default axiosRetryCondition;
