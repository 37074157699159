import isBrowser from "@helpers/isBrowser";

type CookieAsJson = Record<string, string>;

const getCookiesAsJson = (): CookieAsJson => {
  if (isBrowser()) {
    try {
      return document.cookie
        .split('; ')
        .reduce<CookieAsJson>((prev, current) => {
          const [name, ...value] = current.split('=');
          const acc = { ...prev };
          acc[name] = value.join('=');
          return acc;
        }, {});
    } catch {
      return {};
    }
  }
  return {};
};

export default getCookiesAsJson;
