import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}/{questionnaireTemplateId}/section-templates/{sectionTemplateId}/field-templates']['GET'];
type Input = ThisApi['params'];

const getFieldTemplatesForSectionTemplate = async ({ creatorId, questionnaireTemplateId, sectionTemplateId }: Input): Promise<ThisApi['response']> => {
  const data = await formsBackendClient.get(`/questionnaire-templates/${creatorId}/${questionnaireTemplateId}/section-templates/${sectionTemplateId}/field-templates`);
  return data.data;
};

export default getFieldTemplatesForSectionTemplate;
