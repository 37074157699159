import noodleMediaClient, { ApiDefinition } from '@mediaApi';

type ThisApi = ApiDefinition['/vimeo-video-data']['GET'];
type Props = ThisApi['query'];

const getVideoData = async (params: Props): Promise<ThisApi['response']> => {
  const response = await noodleMediaClient.get('/vimeo-video-data', { params: { fields: 'play', ...params } });
  return response.data;
};

export default getVideoData;
