import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorId}/person';
type THIS_API = ApiDefinition['/creators/{creatorId}/person']['GET'];
type Input = THIS_API['params'];

type Output = THIS_API['response'];

const getCreatorPerson = async ({ creatorId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId });
  const response = await noodleApiClient.get(path);

  return response.data;
};

export default getCreatorPerson;