import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/customers/for-creator/{creatorId}']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const getCustomerStripe = async ( { creatorId, personId }: Input): Promise<Output['customer']> => {
  const data = await noodleApiClient.get(`/customers/for-creator/${creatorId}`, { params: { personId }});
  return data.data.customer;
};

export default getCustomerStripe;
