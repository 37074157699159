import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorSlug}/anonymous-conversations';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const findOrCreateAnonymousConversationWithCreator = async ({ creatorSlug }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorSlug });
  const data = await noodleApiClient.post(path);
  return data.data;
};

export default findOrCreateAnonymousConversationWithCreator;
