import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/workflows/templates/{templateSlug}']['DELETE'];
type Input = ThisApi['params'];

const archiveWorkflowTemplate = async ({ templateSlug }: Input): Promise<void> => {
  const path = buildPath<ThisApi>('/workflows/templates/{templateSlug}', { templateSlug });
  await noodleApiClient.delete(path);
};

export default archiveWorkflowTemplate;
