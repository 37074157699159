import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/bookings/{id}']['PUT'];
type Input = THIS_API['params'] & { updates: THIS_API['body'] };
type Output = THIS_API['response'];

const updateBooking = async ({ id, updates }: Input): Promise<Output> => {
  const response = await noodleApiClient.put(`/bookings/${id}`, updates);
  return response.data;
};

export default updateBooking;
