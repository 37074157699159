import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/workflows/{workflowId}/contexts']['GET'];

type Props = ThisApi['params'];

const getWorkflowContexts = async ({ workflowId }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/workflows/${workflowId}/contexts`);
  return response.data;
};

export default getWorkflowContexts;