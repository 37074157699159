import buildPath from '@/tsClient/utilities/buildPath';
import integrationsClient, { ApiDefinition } from '@/tsClient/servers/integrationsService';

type THIS_API = ApiDefinition['/{provider}/creators/{creatorId}/account']['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const createCreatorIntegrationAccount = async ({ creatorId, provider, ...body }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/{provider}/creators/{creatorId}/account', { creatorId, provider });
  const response = await integrationsClient.post(path, body);
  return response.data;
};

export default createCreatorIntegrationAccount;
