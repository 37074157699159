import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/my/dismissed-messages']['GET'];

type GetPersonResponse = ThisApi['response'];

const getDismissedMessages = async (): Promise<GetPersonResponse> => {
  const response = await noodleApiClient.get('/my/dismissed-messages');
  return response.data;
};

export default getDismissedMessages;
