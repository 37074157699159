import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { logError } from '@providers/ErrorTracking';
import attemptPageReload from './attemptPageReload';

type Props = {
  page: string;
  hasData: boolean;
};

const usePageReloader = ({ hasData, page }: Props): boolean => {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      if (!hasData) {
        logError(new Error('Inital load had uninitialized params'), { page });
        const timeout = attemptPageReload(page);
        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }
    }
    return () => {};
  }, [router.isReady, hasData, page]);
  return router.isReady && hasData;
};

export default usePageReloader;