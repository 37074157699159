import { Fragment } from 'react';
import Head from 'next/head';
import { Layout } from '@typings/NextJS';

const EmptyLayoutZeroPadding: Layout = ({children}) => (
  <Fragment>
    <Head>
      <title>Noodle</title>
      <link rel="icon" href="/favicon-32x32.png" />
    </Head>
    {children}
  </Fragment>
);

export default EmptyLayoutZeroPadding;
