import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/payments/{paymentId}/refunds']['POST'];

type Input = ThisApi['params'];
type Output = ThisApi['response'];

const refundPayment = async ({ paymentId }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/payments/{paymentId}/refunds', { paymentId });
  const response = await noodleApiClient.post(path, {});
  return response.data;
};

export default refundPayment;
