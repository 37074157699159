import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/my/associated-users']['POST'];
type Input = ThisApi['body'];
type Output = ThisApi['response'];

const createAssociatedUser = async ({ creatorSlug, email, name }: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/my/associated-users', {
    creatorSlug,
    email,
    name,
  });

  return response.data;
};

export default createAssociatedUser;
