import buildPath from '@tsClient/utilities/buildPath';
import noodleDocumentsClient, { ApiDefinition } from '@documentsApi';

type THIS_API = ApiDefinition['/auth/document-access-token']['POST'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const getDocumentAccessToken = async (body: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>('/auth/document-access-token');
  const response = await noodleDocumentsClient.post(path, body);
  return response.data;
};

export default getDocumentAccessToken;
