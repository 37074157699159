import { sendMessageUp } from "@helpers/iframeMessages";
import isBrowser from "@helpers/isBrowser";
import { JWT_COOKIE_NAME } from "@configuration/client";
import createCookie from "./createCookie";
import createLegacyCookie from "./createLegacyCookie";

const clearJWTCookie = (): void => {
  if (isBrowser()) {
    sendMessageUp({
      message: 'set-client-cookie',
      value: { token: undefined },
    });

    window.httpToken = undefined;
    createLegacyCookie(JWT_COOKIE_NAME, '', -1);
    createCookie(JWT_COOKIE_NAME, '', -1);
  }
};

export default clearJWTCookie;
