import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/messages']['GET'];

const getCreatorComments = async ({ filter, creatorId, page, perPage, sort }: ThisApi['params'] & ThisApi['query']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/messages`, {
    params: {
      filter,
      page,
      perPage,
      sort,
    },
  });
  return response.data;
};

export default getCreatorComments;
