import { useContext, useEffect } from 'react';
import { useIsUserInitialized, useUser } from '@providers/Auth';
import teamsContext from '@providers/Teams/TeamsContext';
import { usePrevious } from '@hooks';
import storage from './storage';
import { ALL_PREFIXES } from './typings';

const clearLocalStorageState = (): void => {
  storage.clear(...ALL_PREFIXES);
};

const LocalStorageStateProvider = (): null => {
  const [user] = useUser();
  const { creatorId } = useContext(teamsContext);
  const previousCreatorId = usePrevious(creatorId);
  const previousUserId = usePrevious(user?.id);
  const isUserInitialized = useIsUserInitialized();

  // Clear dashboard data on logout so next creator doesn't see it.
  useEffect(() => {
    if (isUserInitialized && (!user || (previousUserId && (user.id !== previousUserId)))) {
      clearLocalStorageState();
    }
  }, [isUserInitialized, user, previousUserId]);

  useEffect(() => {
    if (previousCreatorId && previousCreatorId !== creatorId) {
      storage.clear(
        'dashboard',
        'document-access-provider',
        'workflows',
        'user-global',
      );
    }
  },[previousCreatorId, creatorId]);

  return null;
};

export default LocalStorageStateProvider;
export { clearLocalStorageState };
