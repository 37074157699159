import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/form-request-templates/{id}']['DELETE'];

type Input = ThisApi['params'];

const archiveFormRequestTemplate = async ({ id }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.delete(`/form-request-templates/${id}`);
  return data.data;
};

export default archiveFormRequestTemplate;
