import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/medias']['POST'];

const createEmptyMedia = async (): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post('/medias');
  return { ...response.data, mediaId: response.data.id };
};

export default createEmptyMedia;
