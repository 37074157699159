import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}/payment-plans']['DELETE'];
type Input = ThisApi['params'];

const cancelPaymentPlan = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.delete(`/invoices/${id}/payment-plans`);
  return response.data;
};

export default cancelPaymentPlan;
