import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/customer']['GET'];

const getNoodleCustomer = async ({ creatorId }: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/customer`);
  return response.data;
};

export default getNoodleCustomer;
