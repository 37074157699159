import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaires/{questionnaireId}/status']['PUT'];
type Input = ThisApi['params'];
type Body = ThisApi['body'];

const changeQuestionnaireStatus = async ({ questionnaireId }: Input, body: Body): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaires/{questionnaireId}/status', { questionnaireId });
  const data = await formsBackendClient.put(path, body);
  return data.data;
};

export default changeQuestionnaireStatus;
