import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/form-request-templates/{id}']['PUT'];

type Input = ThisApi['params'] & { updates: ThisApi['body'] };

const updateFormRequestTemplate = async ({ id, updates }: Input): Promise<ThisApi['response']> => {
  const data = await noodleApiClient.put(`/form-request-templates/${id}`, updates);
  return data.data;
};

export default updateFormRequestTemplate;
