import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{productId}/availability-blocks']['PUT'];

const getAvailabilityBlocks = async ({
  blockData,
  productId,
  year,
  teamMemberPersonId,
  timezone,
  month,
}: ThisApi['params'] & ThisApi['query'] & ThisApi['body']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/products/${productId}/availability-blocks`, { blockData }, {
    params: {
      month,
      teamMemberPersonId,
      timezone,
      year,
    },
  });
  return response.data;
};

export default getAvailabilityBlocks;
