import { ParsedUrlQuery } from "querystring";
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import { getEcommerceItemFromProduct } from "./ecommerce";

type ProductItem = ReturnType<typeof getEcommerceItemFromProduct>;
type BasicEventData = {
  event_category: string;
  event_label: string;
};
type GA_EVENTS =
  | [ 'click_buy_free_product_button', BasicEventData]
  | [ 'add_to_cart', { items: ProductItem[] }]
  | [ 'add_shipping_info', { items: ProductItem[] }]
  | [ 'begin_checkout', { items: ProductItem[] }]
  | [ 'creator_url_created', BasicEventData]
  | [ 'creator_sign_up', BasicEventData]
  | [ 'claim_noodle_page', BasicEventData]
  | [ 'view_item', { items: ProductItem[] }]
  | [ 'submit_waitlist_form', BasicEventData]
  | [ 'waitlist_lead_submission', BasicEventData]
  | [ 'popover_widget_opened', BasicEventData]
  | [ string, BasicEventData]; // catch all

export function gaEvent(...args: GA_EVENTS): void {
  const [eventName, eventParams] = args;
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  }
}

export function getUTMParams(params: ParsedUrlQuery): Record<string, string> {
  return Object.keys(params)
    .filter(p => p.includes('utm_'))
    .reduce((acc, val) => ({
      ...acc,
      [val]: deserializeQueryStringItem(params[val]),
    }), {});
}
