import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorSlug}/members/{personId}/notes']['GET'];

type Props = ThisApi['params'] & ThisApi['query'];

const getMemberNotes = async ({ creatorSlug, personId, page, perPage }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(
    `/creators/${creatorSlug}/members/${personId}/notes`,
    { params: { page, perPage } },
  );
  return response.data;
};

export default getMemberNotes;