import noodleApiClient, { ApiDefinition } from '@noodleApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/workflows/steps/{stepId}/user-workflows']['POST'];
type Input = ThisApi['params'] & ThisApi['body'];
type Output = ThisApi['response'];

const initiateWorkflow = async ({ contextAdditionalInfo, contextPeople, contextSelections, stepId, notes }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/workflows/steps/{stepId}/user-workflows', {
    stepId,
  });
  const response = await noodleApiClient.post(path, { contextAdditionalInfo, contextPeople, contextSelections, notes });
  return response.data;
};

export default initiateWorkflow;
