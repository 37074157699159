import classNames from 'classnames';
import s from './Spacer.module.scss';

type Props = {
  size?: string | number;
  isLine?: boolean;
  className?: string;
};

const Spacer: React.FC<Props> = ({ size = 16, isLine = false, className = undefined }) => (
  <div className={classNames(className, s['n-spacer'], { [s['n-spacer--line']]: isLine })} style={{ height: size, minHeight: size }} />
);

export default Spacer;
