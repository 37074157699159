import noodleMediaClient from '@mediaApi';

const mediaApiPath = '/branding-logo';

const handleUploadLogoAsset = async (formData: FormData): Promise<{ id: string }> => {
  const data = await noodleMediaClient.post(mediaApiPath, formData);
  return data.data;
};

export default handleUploadLogoAsset;
