import { useContext } from 'react';
import AuthContext from './AuthContext';
import { Profile } from './constants';

const useUserProfile = (): Profile | null => {
  const { profile } = useContext(AuthContext);
  return profile;
};

export default useUserProfile;
