import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/stripe/creators/{creatorId}/secondary-accounts']['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const createSecondaryStripeAccount = async ({ creatorId, name }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/stripe/creators/${creatorId}/secondary-accounts`, {
    name,
  });
  return response.data;
};

export default createSecondaryStripeAccount;
