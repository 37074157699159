import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/team-member-cases-filed']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getTeamMemberCasesFiled = async ({ creatorId }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/team-member-cases-filed`);
  return response.data;
};

export default getTeamMemberCasesFiled;
