import documentApiClient, { ApiDefinition } from '@documentsApi';

type ThisApi = ApiDefinition['/document-request-users/{id}/files/{fileId}/documents/{documentId}']['DELETE'];
type Input = ThisApi['params'];

const removeDocumentFromDocumentRequestUserFile = async ({ id, fileId, documentId }: Input): Promise<ThisApi['response']> => {
  const response = await documentApiClient.delete(`/document-request-users/${id}/files/${fileId}/documents/${documentId}`);
  return response.data;
};

export default removeDocumentFromDocumentRequestUserFile;
