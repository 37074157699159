import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/creators/{creatorId}/products-for-sharing']['GET'];
type Input = ThisApi['params'];

const getProductsForSharing = async ({ creatorId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/creators/${creatorId}/products-for-sharing`);
  return response.data;
};

export default getProductsForSharing;
