import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/persons/subscriptions']['GET'];
type Input = ThisApi['query'];

const getUserSubscriptions = async ({ asCreatorId }: Input = {}): Promise<ThisApi['response']['subscriptions']> => {
  const data = await noodleApiClient.get('/persons/subscriptions', {
    params: {
      asCreatorId,
    },
  });
  return data.data.subscriptions;
};

export default getUserSubscriptions;
