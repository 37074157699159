import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/my/dismissed-messages']['POST'];
type Input = ThisApi['body'];

const createDismissedMessages = async ({...body}: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post('/my/dismissed-messages', body);
  return response.data;
};

export default createDismissedMessages;
