import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/confido-onboarding-token']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getConfidoOnboardingToken = async ({ creatorId }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/creators/{creatorId}/confido-onboarding-token', { creatorId });
  const data = await noodleApiClient.get(path);
  return data.data;
};

export default getConfidoOnboardingToken;
