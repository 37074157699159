import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/external-data-sets/by-slug/{slug}/versions']['GET'];
type Input = ThisApi['params'];

const getDataReferenceVersionsBySlug = async ({ slug }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/external-data-sets/by-slug/{slug}/versions', { slug });
  const data = await formsBackendClient.get(path);
  return data.data;
};

export default getDataReferenceVersionsBySlug;