import React, { createContext, useCallback, useContext, useMemo, useState, CSSProperties, useEffect, useRef } from 'react';
import defaultsDeep from 'lodash/defaultsDeep';
import { useRouter } from 'next/router';
import COLORS, { COLOR_KEYS } from '@styles/colors';
import getCreatorUI from '@tsClient/getCreatorUI';
import { logErrorFactory } from '@providers/ErrorTracking';
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import tinycolor from 'tinycolor2';

type CreatorUIData = {
  id: string;
  slug: string;
  creatorDomain?: string | null;
  colors: { [K in COLOR_KEYS]?: string };
  name?: string | null;
  person?: {
    image?: {
      url: string;
    } | null;
  } | null;
};

type CreatorUIInput = {
  id: string;
  creatorDomain?: string | null;
  slug: string;
  primaryColour?: {
    hex: string;
  } | null;
  name?: string | null;
  person?: {
    image?: {
      url: string;
    } | null;
  } | null;
};

type CreatorSlug = string | null;

type ContextValue = {
  isLoaded: boolean;
  creatorSlug: CreatorSlug;
  uiData: CreatorUIData;
  setCreatorSlug: (args: { creatorSlug: CreatorSlug; hideContentWhileLoading?: boolean }) => void;
};

interface Style extends CSSProperties {
  '--color-primary'?: string;
  '--primary'?: string;
  '--primary-gradient'?: string;
  '--shadow-primary'?: string;
}

const defaultUIData: CreatorUIData = {
  colors: {},
  creatorDomain: null,
  id: '',
  name: '',
  person: null,
  slug: '',
};

const creatorUIToUIData = (creatorUI: CreatorUIInput): CreatorUIData => {
  const partialUIData: CreatorUIData = {
    colors: {},
    creatorDomain: creatorUI.creatorDomain,
    id: creatorUI.id,
    name: creatorUI.name,
    person: creatorUI.person,
    slug: creatorUI.slug,
  };

  if (typeof creatorUI?.primaryColour?.hex === 'string') {
    partialUIData.colors.primary = creatorUI.primaryColour.hex;
  }
  return partialUIData;
};

export const uiDataToStyle = (uiData: Partial<CreatorUIData>): Style => {
  const newStyle: Style = {};
  // If this is updated, make the same change to NOODLE_STYLE below.
  if (uiData.colors?.primary) {
    newStyle['--color-primary'] = uiData.colors.primary;
    newStyle['--primary-gradient'] = `linear-gradient(180deg, ${uiData.colors.primary}00 48%, ${tinycolor(uiData.colors.primary).darken(10).toString()} 96%)`;
    newStyle['--shadow-primary'] = `0px 8px 24px ${uiData.colors.primary}50`;
  }
  return newStyle;
};

export const NOODLE_THEME_STYLE = uiDataToStyle({
  colors: {
    primary: 'var(--color-noodle)',
  },
});

const logError = logErrorFactory({
  tags: {
    flow: 'creator-ui-provider',
  },
});

const CreatorUIContext = createContext<ContextValue>({
  creatorSlug: null,
  isLoaded: false,
  setCreatorSlug: () => {
    /* */
  },
  uiData: defaultUIData,
});

type ProviderProps = {
  creatorSlug?: CreatorSlug;
  creatorUI?: CreatorUIInput;
};

const CreatorUIProvider: React.FC<ProviderProps> = ({ children, creatorSlug: creatorSlugFromSSR = null, creatorUI: creatorUIFromSSR = null }) => {
  const [isInBrowser, setIsInBrowser] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [creatorSlugRequested, setCreatorSlugRequested] = useState<CreatorSlug>(null);
  const [hideContentWhileLoading, setHideContentWhileLoading] = useState(true);
  const [{ creatorSlug: creatorSlugInUse, uiData, uiStyle }, setUI] = useState<{
    creatorSlug: CreatorSlug;
    uiData: CreatorUIData;
    uiStyle: Style;
  }>(() => ({
    creatorSlug: null,
    uiData: defaultUIData,
    uiStyle: uiDataToStyle(defaultUIData),
  }));

  const router = useRouter();
  const creatorSlugParam = deserializeQueryStringItem(router.query.creatorSlug) || null;

  // When doing .setCreatorSlug, the setCreatorSlugRequested happens, then the params and SSR useEffects.
  // So the null from params/SSR ends up being used.
  // Can't use state to signal that those useEffect should not fire because that setState is in the same render loop.
  // So not visible as we march back up the render tree.
  // Could delay the .setCreatorSlugRequested one render, but that means that there is a flash of the default style
  // Using a ref to communicate immediately to avoid this flash.
  const autoDetectChange = useRef(true);

  useEffect(() => {
    setIsInBrowser(true);
  }, []);

  // Handle changes that come from SSR through the creatorSLug and creatorUI props
  useEffect(() => {
    if (autoDetectChange.current) {
      if (creatorSlugFromSSR && creatorUIFromSSR) {
        // If creatorUI and creatorSlug come from SSR, just use them
        setHideContentWhileLoading(true);
        setCreatorSlugRequested(creatorSlugFromSSR);
        const creatorSlug = creatorSlugFromSSR;
        const newUIData = creatorUIToUIData(creatorUIFromSSR);
        const newUIStyle = uiDataToStyle(newUIData);
        setUI({ creatorSlug, uiData: newUIData, uiStyle: newUIStyle });
      } else if (!creatorSlugFromSSR && !creatorUIFromSSR) {
        // If neither creatorUI nor creatorSlug come from SSR, clear the style
        if (!creatorSlugParam) {
          setCreatorSlugRequested(null);
          setUI({
            creatorSlug: null,
            uiData: defaultUIData,
            uiStyle: uiDataToStyle(defaultUIData),
          });
        }
      } else if (creatorSlugFromSSR) {
        // If only the creator slug comes in, set that as the requested slug to start the process of fetching the ui.
        setHideContentWhileLoading(true);
        setCreatorSlugRequested(creatorSlugFromSSR);
      } else {
        logError(new Error('Specified creatorUI but not creatorSlug in SSR'));
      }
    }
  }, [creatorSlugParam, creatorUIFromSSR, creatorSlugFromSSR]);

  // Handle changes that come from router params
  // Wait till the router is ready, to not get the initial null on router.query.creatorSlug
  useEffect(() => {
    if (router.isReady && !creatorSlugFromSSR && autoDetectChange.current) {
      setHideContentWhileLoading(true);
      setCreatorSlugRequested(creatorSlugParam);
    }
  }, [router.isReady, creatorSlugParam, creatorSlugFromSSR]);

  // Whenever a new slug is requested, fetch their UI from the server.
  useEffect(() => {
    if (creatorSlugRequested !== creatorSlugInUse) {
      const fetchCreatorUI = async (): Promise<void> => {
        setIsLoaded(false);
        let newUIData: CreatorUIData | null = null;
        if (creatorSlugRequested) {
          try {
            const response = await getCreatorUI({ creatorSlug: creatorSlugRequested });
            newUIData = creatorUIToUIData(response);
          } catch (error) {
            logError(error, { creatorSlug: creatorSlugRequested });
          }
        }
        if (!newUIData) {
          newUIData = defaultUIData;
        }
        setUI({
          creatorSlug: creatorSlugRequested,
          uiData: newUIData,
          uiStyle: uiDataToStyle(newUIData),
        });
        setIsLoaded(true);
      };
      fetchCreatorUI();
    } else {
      setIsLoaded(true);
    }
  }, [creatorSlugRequested, creatorSlugInUse]);

  const setCreatorSlug = useCallback<ContextValue['setCreatorSlug']>(({ creatorSlug, hideContentWhileLoading: hideContentWhileLoadingForNext }) => {
    setHideContentWhileLoading(hideContentWhileLoadingForNext ?? true);
    setCreatorSlugRequested(creatorSlug);
    autoDetectChange.current = false;
  }, []);

  // only skip autoDetectChange for one render... so reset it on ever render.
  // This must be after the useEffects that use autoDetectChange.
  useEffect(() => {
    autoDetectChange.current = true;
  });

  const creatorUIIsLoaded = router.isReady && isInBrowser && isLoaded && creatorSlugRequested === creatorSlugInUse;

  const showContent = router.isReady && isInBrowser && (!hideContentWhileLoading || (isLoaded && creatorSlugRequested === creatorSlugInUse));

  const style = showContent ? uiStyle : { ...uiStyle, display: 'none' };

  const value = useMemo(
    () => ({
      creatorSlug: creatorSlugInUse,
      isLoaded: creatorUIIsLoaded,
      setCreatorSlug,
      uiData,
    }),
    [creatorSlugInUse, creatorUIIsLoaded, setCreatorSlug, uiData],
  );

  return (
    <CreatorUIContext.Provider value={value}>
      <div id="creator-ui-provider-style-wrapper" style={style}>
        {children}
      </div>
    </CreatorUIContext.Provider>
  );
};

export const useCreatorUI = (): CreatorUIData | null => {
  const context = useContext(CreatorUIContext);
  if (context === undefined) {
    throw new Error('useCreatorUI must be used within a CreatorUIProvider');
  }

  const { uiData } = context;
  const creatorColors = uiData.colors;
  const colors = useMemo(() => defaultsDeep({}, creatorColors, COLORS), [creatorColors]);

  if (!uiData?.id) {
    return null;
  }

  return {
    ...uiData,
    colors,
    name: uiData.name,
    person: uiData.person,
  };
};

export const useSetCreatorUISlug: ContextValue['setCreatorSlug'] = ({ creatorSlug, hideContentWhileLoading }) => {
  const context = useContext(CreatorUIContext);
  if (context === undefined) {
    throw new Error('setCreatorForUI must be used within a CreatorUIProvider');
  }
  const { setCreatorSlug } = context;
  useEffect(() => {
    setCreatorSlug({ creatorSlug, hideContentWhileLoading });
  }, [setCreatorSlug, creatorSlug, hideContentWhileLoading]);
};

export default CreatorUIProvider;
