import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/reviews/get-product-reviews';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['GET'];
type Input = THIS_API['query'];
type Output = THIS_API['response'];

const getProductReviewsList = async ({ productId }: Input ): Promise<Output> => {
  const path = buildPath<THIS_API>(PATH_TEMPLATE);
  const response = await noodleApiClient.get(path, { params: { productId } });
  return response.data;
};

export default getProductReviewsList;
