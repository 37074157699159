import getArray from '@helpers/deserializeQueryStringItem/getArray';
import { QueryStringItem } from './typings';

export type Options = {
  type: 'array';
  item: 'string' | 'boolean';
  defaultValue?: string[];
};

function handleArray<Response = string>(item: QueryStringItem): Response | undefined;
function handleArray<Response = string>(item: QueryStringItem, opts: Options & { defaultValue?: never }): Response | undefined;
function handleArray<Response = string>(item: QueryStringItem, opts: Options & { defaultValue: string }): Response;
function handleArray<Response = string>(item: QueryStringItem, opts?: Options): Response | undefined {
  let value = getArray(item);

  const defaultValue = opts?.defaultValue ?? undefined;
  if ((value === undefined || value.length === 0) && defaultValue !== undefined) {
    value = defaultValue;
  }

  return value as unknown as Response;
}

export default handleArray;
