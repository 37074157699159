import { createContext } from 'react';

export type HistoryState = {
  history: string[];
};

const HistoryContext = createContext<HistoryState>({
  history: [],
});

export default HistoryContext;
