import { useEffect, useMemo, useCallback, useContext, useState, useRef } from 'react';
import { parseAsStringLiteral, useQueryState } from 'nuqs';
import { useRouter } from 'next/router';
import { useLocalStorageState } from '@/providers/LocalStorageState';
import { AuthContext } from '@/providers/Auth';
import { mixpanelTrack } from '@/providers/Mixpanel';
import TranslationContext from './TranslationContext';
import handleTranslate from './handleTranslate';
import { DEFAULT_LANGUAGE, LanguageCode, SUPPORTED_LANGUAGE_CODES } from './constants';
import validateLanguageCode from './validateLanguageCode';
import languageSerialization from './languageSerialization';
import { logError } from '../ErrorTracking';
import { useFeatureFlag } from '../FeatureFlags';

const TranslationProvider: React.FC = ({ children }) => {
  const { profile } = useContext(AuthContext);
  const router = useRouter();
  const [isReadyForTranslation, setIsReadyForTranslation] = useState(false);
  const currentLanguageRef = useRef(DEFAULT_LANGUAGE.code);
  const isReadyForTranslationRef = useRef(isReadyForTranslation);
  isReadyForTranslationRef.current = isReadyForTranslation;
  const [queryLanguage, setQueryLanguage] = useQueryState('lang', parseAsStringLiteral(SUPPORTED_LANGUAGE_CODES));
  const [storedLanguage, setStoredLanguage] = useLocalStorageState<LanguageCode | null>({
    deserialize: languageSerialization.deserialize,
    key: 'language',
    prefix: 'user-global',
    serialize: languageSerialization.serialize,
  });

  const systemLanguage = useMemo(() => {
    const [primaryLanguage] = navigator.languages?.length ? navigator.languages : [navigator.language];
    return validateLanguageCode(primaryLanguage);
  }, []);

  const effectiveLanguage = useMemo(() => (
    validateLanguageCode(queryLanguage)
    || storedLanguage
    || systemLanguage
    || DEFAULT_LANGUAGE.code
  ), [queryLanguage, storedLanguage, systemLanguage]);

  // Wait for content to be loaded before translating to stored language.
  useEffect(() => {
    const timeout = setTimeout(() => setIsReadyForTranslation(true), 1_000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const validLanguage = validateLanguageCode(profile?.preferredLanguage);
    if (validLanguage) {
      setStoredLanguage(validLanguage);
    }
    // Only trigger when the profile.id changes
  }, [profile?.id, setStoredLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout: Parameters<typeof clearTimeout>[0];
    if (isReadyForTranslation) {
      if (effectiveLanguage !== DEFAULT_LANGUAGE.code) {
        currentLanguageRef.current = effectiveLanguage;
        document.documentElement.setAttribute('lang', effectiveLanguage);
        // set direction attribute for Arabic
        document.documentElement.setAttribute('dir', effectiveLanguage === 'ar' ? 'rtl' : 'ltr');
        handleTranslate(effectiveLanguage);
      } else if (currentLanguageRef.current !== DEFAULT_LANGUAGE.code) {
        // @todo - must be a better way to go back to english than to reload...
        timeout = setTimeout(() => window.location.reload(), 10);
      }
    }

    // Observe the DOM for changes and re-translate.
    const observer = new MutationObserver((mutations) => {
      let shouldTranslate = false;
      mutations.forEach((mutation) => {
        if (mutation.target.nodeName.toLowerCase() === 'textarea' || mutation.target.nodeName.toLowerCase() === 'input') {
          return;
        }
        if (isReadyForTranslationRef.current && currentLanguageRef.current !== DEFAULT_LANGUAGE.code) {
          shouldTranslate = true;
        }
      });
      if (shouldTranslate) {
        handleTranslate(currentLanguageRef.current);
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      clearTimeout(timeout);
      observer.disconnect();
    };
  }, [effectiveLanguage, isReadyForTranslation, router.pathname]);

  const setQueryLanguageWrapped = useCallback((newLanguage: LanguageCode) => {
    mixpanelTrack('Changed language query string', {
      fromLanguage: currentLanguageRef.current,
      newLanguage,
    });
    const validLanguage = validateLanguageCode(newLanguage);
    if (!validLanguage) {
      logError(new Error('Invalid language code'), { newLanguage });
      return;
    }
    setQueryLanguage(validLanguage);
  }, [setQueryLanguage]);

  const setLanguage = useCallback(async (newLanguage: LanguageCode): Promise<void> => {
    mixpanelTrack('Changed language', {
      fromLanguage: currentLanguageRef.current,
      newLanguage,
    });
    const validLanguage = validateLanguageCode(newLanguage);
    setQueryLanguage(null);
    if (!validLanguage) {
      logError(new Error('Invalid language code'), { newLanguage });
      setStoredLanguage(null);
      return;
    }

    setStoredLanguage(validLanguage);
  }, [setStoredLanguage, setQueryLanguage]);

  const value = useMemo(() => ({
    language: effectiveLanguage,
    setLanguage,
    setQueryLanguage: setQueryLanguageWrapped,
  }), [effectiveLanguage, setLanguage, setQueryLanguageWrapped]);

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

const TranslationProviderWrapped: React.FC = ({ children }) => {
  const router = useRouter();

  const isCreatorSlug = useMemo(() => router.pathname.startsWith('/[creatorSlug]'), [router.pathname]);
  const enableTranslations = useFeatureFlag('enable-translations') && isCreatorSlug;

  if (!enableTranslations) {
    return <>{children}</>;
  }

  return <TranslationProvider>{children}</TranslationProvider>;
};

export default TranslationProviderWrapped;
