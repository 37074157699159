import { FC, PropsWithChildren, ReactNode } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_OAUTH2_CLIENT_ID } from '@configuration/client';
import { logError } from '@providers/ErrorTracking';

const GoogleOauth2Provider: FC<PropsWithChildren<ReactNode>> = ({ children }) => {
  if (!GOOGLE_OAUTH2_CLIENT_ID) {
    return (<>{children}</>);
  }

  const handleScriptLoadError = (): void => {
    logError(new Error('Failed to load google oauth2 provider'));
  };

  return (
    <GoogleOAuthProvider
      clientId={GOOGLE_OAUTH2_CLIENT_ID}
      onScriptLoadError={handleScriptLoadError}
    >
      {children}
    </GoogleOAuthProvider>
  );
};

export default GoogleOauth2Provider;
