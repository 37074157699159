import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/invoices/{id}']['DELETE'];

type Props = ThisApi['params'];

const voidInvoice = async ({ id }: Props): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.delete(`/invoices/${id}`);
  return response.data;
};

export default voidInvoice;
