/*
 * Take a string from the query string and return the value deserialized
 */
import { logError } from '@providers/ErrorTracking';
import { QueryStringItem } from './typings';
import handleString, { Options as StringOptions } from './handleString';
import handleBoolean, { Options as BooleanOptions } from './handleBoolean';
import handleArray, { Options as ArrayOptions } from './handleArray';
import handleEnum, { Options as EnumOptions } from './handleEnum';

type Options =
  | StringOptions
  | EnumOptions<unknown>
  | BooleanOptions
  | ArrayOptions;

// @todo - Type checking of opts here isn't super important. But it would be nice to not have to do `as any`
type AnyOptions = any; // eslint-disable-line @typescript-eslint/no-explicit-any

// @todo parse integer
// @todo parse date-time
// @todo parse date-only
// @todo parse float - should integer use this with round?
// @todo parse array of all of the above (and string);

const nullOverUndefined = (v: unknown | undefined): unknown | null => v === undefined ? null : v;

function deserializeQueryStringItem<Response = string>(item: QueryStringItem): Response | null;
function deserializeQueryStringItem<Response = string>(item: QueryStringItem, opts: StringOptions & { defaultValue?: never }): Response | null;
function deserializeQueryStringItem<Response = string>(item: QueryStringItem, opts: StringOptions & { defaultValue: string }): Response;
function deserializeQueryStringItem<Response = boolean>(item: QueryStringItem, opts: BooleanOptions & { defaultValue?: never }): Response | null;
function deserializeQueryStringItem<Response = boolean>(item: QueryStringItem, opts: BooleanOptions & { defaultValue: boolean }): Response;
function deserializeQueryStringItem<Response = string[]>(item: QueryStringItem, opts: ArrayOptions & { defaultValue: string[] }): Response | [];
function deserializeQueryStringItem<Response = boolean[]>(item: QueryStringItem, opts: ArrayOptions & { defaultValue: boolean[] }): Response | [];
function deserializeQueryStringItem<Response = string[]>(item: QueryStringItem, opts: ArrayOptions & { defaultValue?: never }): Response | [];
function deserializeQueryStringItem<Response = boolean[]>(item: QueryStringItem, opts: ArrayOptions & { defaultValue?: never }): Response | [];
function deserializeQueryStringItem<E>(item: QueryStringItem, opts: EnumOptions<E> & { defaultValue?: never }): E[keyof E] | null;
function deserializeQueryStringItem<E>(item: QueryStringItem, opts: EnumOptions<E> & { defaultValue: E[keyof E] }): E[keyof E];
function deserializeQueryStringItem<Response = unknown>(item: QueryStringItem, opts?: Options): unknown {
  const type = opts?.type || 'string';
  // @todo - Type checking of opts here isn't super important. But it would be nice to not have to do `as any`

  if (type === 'enum') {
    return nullOverUndefined(handleEnum(item, opts as AnyOptions));
  }

  if (type === 'boolean') {
    return nullOverUndefined(handleBoolean(item, opts as AnyOptions));
  }

  if (type === 'array') {
    return handleArray(item, opts as AnyOptions);
  }

  if (type !== 'string') {
    logError(new Error('Invalid type'), { item, type });
  }

  return nullOverUndefined(handleString<Response>(item, opts as AnyOptions));
}

export default deserializeQueryStringItem;
