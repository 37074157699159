import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/ai/creators/:creatorId/customizations/:type']['GET'];
type Input = THIS_API['params'];
type Output = THIS_API['response'];

const getAiCustomization = async ({type, creatorId}: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/ai/creators/${creatorId}/customizations/${type}` );
  return response.data;
};

export default getAiCustomization;