import buildPath from '@tsClient/utilities/buildPath';
import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/creators/{creatorId}/user-workflows';
type THIS_API = ApiDefinition['/creators/{creatorId}/user-workflows']['PUT'];
type Input = THIS_API['params'] & THIS_API['query'] & THIS_API['body'];
type Output = THIS_API['response'];

const getUserWorkflows = async ({
  archived,
  assignedTeamMemberIds,
  clientTaskFilters,
  paused,
  personId,
  creatorId,
  initiatedByPersonId,
  unassigned,
  page,
  perPage,
  search,
  completed,
  dataCollection,
  filedAndPending,
  preparingCase,
  sortDirection,
  sortField,
}: Input): Promise<Output> => {
  const body = {
    archived,
    assignedTeamMemberIds,
    clientTaskFilters,
    completed,
    dataCollection,
    filedAndPending,
    initiatedByPersonId,
    paused,
    personId,
    preparingCase,
    search,
    sortDirection,
    sortField,
    unassigned,
  };
  const path = buildPath<THIS_API>(PATH_TEMPLATE, { creatorId });
  const data = await noodleApiClient.put(path,
    body,
    {
      params: {
        page,
        perPage,
      },
    },
  );
  return data.data;
};

export default getUserWorkflows;
