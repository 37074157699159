/* eslint-disable @typescript-eslint/no-unused-vars */

import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/payment-plans']['PUT'];

type Input = ThisApi['query'] & ThisApi['body'];
type Output = ThisApi['response'];

const searchPaymentPlans = async ({ page, perPage, ...body }: Input): Promise<Output> => {
  const response = await noodleApiClient.put('/payment-plans', body, {
    params: { page, perPage },
  });
  return response.data;
};

export default searchPaymentPlans;