import buildPath from '@/tsClient/utilities/buildPath';
import integrationsClient, { ApiDefinition } from '@/tsClient/servers/integrationsService';

type THIS_API = ApiDefinition['/{provider}/creators/{creatorId}/test-login']['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const testLogin = async ({ creatorId, email, password, provider, twoFaKey }: Input): Promise<Output> => {
  const path = buildPath<THIS_API>('/{provider}/creators/{creatorId}/test-login', { creatorId, provider });
  const response = await integrationsClient.post(path, {
    email,
    password,
    twoFaKey,
  });
  return response.data;
};

export default testLogin;
