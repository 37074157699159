import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/workflows/user-workflows/{userWorkflowId}/integrations/{integrationSlug}/synchronize';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['POST'];
type Input = THIS_API['params'] & THIS_API['body'];
type Output = THIS_API['response'];

const synchronizeUserWorkflowIntegration = async ({ integrationSlug, password, userWorkflowId }: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/workflows/user-workflows/${userWorkflowId}/integrations/${integrationSlug}/synchronize`, {
    password,
  });
  return response.data;
};

export default synchronizeUserWorkflowIntegration;
