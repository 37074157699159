import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/confido-stored-payment-method-payment']['POST'];
type Input = THIS_API['body'] & THIS_API['params'];
type Output = THIS_API['response'];

const createConfidoPaymentWithStoredPaymentMethod = async ({
  amount,
  creatorId,
  paymentMethodId,
  invoiceId,
  passProcessingFeesToCustomer,
  personId,
  priceIds,
  processingFees,
  sessionDuration,
  sessionTime,
  teamMemberPersonId,
}: Input): Promise<Output> => {
  const response = await noodleApiClient.post(`/creators/${creatorId}/confido-stored-payment-method-payment`, {
    amount,
    invoiceId,
    passProcessingFeesToCustomer,
    paymentMethodId,
    personId,
    priceIds,
    processingFees,
    sessionDuration,
    sessionTime,
    teamMemberPersonId,
  });
  return response.data;
};

export default createConfidoPaymentWithStoredPaymentMethod;
