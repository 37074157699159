import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/tiers']['POST'];
type Input = ThisApi['body'];
type Output = ThisApi['response'];

const createTier = async (data: Input): Promise<Output> => {
  const response = await noodleApiClient.post('/tiers', data);
  return response.data;
};

export default createTier;
